import { Dispatch, SetStateAction } from 'react';
import {
  SwitcherInput,
  SwitcherLabel,
  SwithcerIndicator,
  SwitcherText,
} from './styles';

type ISwitcherProps = {
  checked?: boolean;
  setChecked: Dispatch<SetStateAction<boolean | undefined>>;
} & Omit<
  React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >,
  'ref'
>;

export const Switcher: React.FC<ISwitcherProps> = ({
  checked,
  children,
  setChecked,
  ...rest
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event =>
    setChecked(event.target.checked);

  return (
    <SwitcherLabel {...rest}>
      <SwitcherInput
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <SwithcerIndicator />
      <SwitcherText data-cy="switcher.text">{children}</SwitcherText>
    </SwitcherLabel>
  );
};

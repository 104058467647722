import React from 'react';
import { FiCheck, FiChevronLeft, FiX } from 'react-icons/fi';
import { H2, H6, P } from '../../../Atoms/Texts';
import Button from '../../../Button';
import Icon from '../../../Icon';
import { MobileNavigationButtons } from '../../ClassPageComponents/MobileNavigationButtons';
import { usePaginated } from '../../hooks/Paginated';
import Section from '../../Section';
import {
  FooterButton,
  FooterRow,
  NavigateButton,
  PaginatedButtons,
} from '../QuestionContainer/styles';

import { Container, ResumeBars, ButtonContainer } from './styles';

export type IQuestion = {
  id: string;
  title: string;
  selected: string;
  correct: boolean;
  correctAnswer?: string;
};
export type IStats = {
  total: number;
  correct: number;
  wrong: number;
};

export type IResume = {
  questions: IQuestion[];
  stats: IStats;
};

export type IResumeProps = IResume & {
  handleOnClick: () => void;
};

const Resume: React.FC<IResumeProps> = ({
  questions,
  stats,
  handleOnClick,
}) => {
  const { prevPage } = usePaginated();
  return (
    <Container {...stats}>
      <H2 size={20} weight="600">
        Parabéns!
      </H2>
      <P size={16}>
        Você acertou {stats.correct} de {stats.total} questões
      </P>
      <ResumeBars {...stats}>
        <div>
          <div className="correctBar" />
          <small>{stats.correct}</small>
        </div>
        <div>
          <div className="wrongBar" />
          <small>{stats.wrong}</small>
        </div>
        <div className="background">
          {Array(stats.total + 1)
            .fill(null)
            .map((_, i) => i)
            .map(index => (
              <svg
                key={index}
                width="2"
                height="600"
                viewBox="0 0 2 600"
                fill="none"
                strokeWidth={1}
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M 0 0 V 600" />
              </svg>
            ))}
        </div>
      </ResumeBars>
      <Section text={<H6 weight="600">Resumo</H6>} startsExpanded>
        <div className="resumeContent">
          {questions.map((item, index) => (
            <div key={`${item.id} ${item.correct}`}>
              <div className={`${item.correct ? 'title' : 'title red'}`}>
                <Icon
                  IconComponent={item.correct ? FiCheck : FiX}
                  color={item.correct ? 'green' : 'red'}
                  isntGradient
                  size={30}
                />
                <P weight="500">Questão {index + 1}</P>
              </div>
              <P weight="500" className="question">
                <b>Q:</b> {item.title}
              </P>
              <P weight="500" className={item.correct ? 'answer' : 'wrong'}>
                <b>R:</b> {item.selected}
              </P>
              {item.correctAnswer && !item.correct && (
                <P weight="500" className="answer">
                  <b>R:</b> {item.correctAnswer}
                </P>
              )}
            </div>
          ))}
        </div>
      </Section>
      <hr />
      <ButtonContainer>
        <PaginatedButtons type="button" onClick={prevPage}>
          <FiChevronLeft size={20} />
        </PaginatedButtons>
        <Button
          isGradient
          size="small"
          chevron="next"
          colorType="blue"
          onClick={handleOnClick}
        >
          Concluir
        </Button>
      </ButtonContainer>
      <MobileNavigationButtons>
        <FooterButton>
          <FooterRow>
            <NavigateButton type="button" onClick={prevPage}>
              <FiChevronLeft size={20} />
            </NavigateButton>
            <Button
              isGradient
              size="small"
              chevron="next"
              colorType="blue"
              onClick={handleOnClick}
            >
              Concluir
            </Button>
          </FooterRow>
        </FooterButton>
      </MobileNavigationButtons>
    </Container>
  );
};

export default Resume;

import React, { useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import HeaderProfile from './HeaderProfile';
import whiteTextLogo from '../../assets/logo-text.svg';

import { Wrapper, Container, ShowMenuButton } from './styles';

const Header: React.FC = () => {
  const [isMenuDisabled, setIsMenuDisabled] = useState(true);

  const toggleDisableMenu = () => {
    setIsMenuDisabled(prev => !prev);
  };

  return (
    <Wrapper id="header">
      <Container>
        <div className="titleContainer">
          <Link to="/course/do-bug-ao-bounty">
            <img src={whiteTextLogo} alt="Pato Academy" />
          </Link>
        </div>
        <ShowMenuButton type="button" onClick={toggleDisableMenu}>
          <Icon
            IconComponent={isMenuDisabled ? FiMenu : FiX}
            color="grey"
            size={25}
          />
        </ShowMenuButton>
        <HeaderProfile className={isMenuDisabled ? 'disable' : ''} />
      </Container>
    </Wrapper>
  );
};

export default Header;

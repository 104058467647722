import { StyledHeader, StyledTrigger } from '../styles';

type AccordionTriggerProps = {
  hidden?: boolean;
  children: React.ReactNode;
};

const AccordionTrigger: React.FC<AccordionTriggerProps> = ({
  children,
  ...rest
}) => {
  return (
    <StyledHeader {...rest}>
      <StyledTrigger> {children} </StyledTrigger>
    </StyledHeader>
  );
};

export default AccordionTrigger;

import React, { useMemo } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import { useLatestTopicsFromClass } from '@/services/hooks/forum/topics/useLatestTopicsFromClass';
import { useLatestTopics } from '@/services/hooks/forum/topics/useLatestTopics';

import { LastClassTopicsList } from './LastClassTopicsList';

import { Container, Header, Footer } from './styles';

type ILastClassTopicsProps = {
  lastClass?: IFinalClass;
};

export const LastClassTopics: React.FC<ILastClassTopicsProps> = ({
  lastClass,
}) => {
  const { data } = (lastClass?.title) ? useLatestTopicsFromClass(lastClass.id) : useLatestTopics();

  const topTopics = useMemo(() => {
    if (data) {
      return data.slice(0, 4);
    }
    return [];
  }, [data]);

  const {
    newTheme: { white },
  } = useTheme();

  return (
    <Container>
      <Header>
        <h2>Publicações na Pato Community</h2>
        <span>{lastClass?.title}</span>
      </Header>

      <LastClassTopicsList topics={topTopics} />

      {topTopics.length !== 0 && (
        <Footer>
          <a
            href="https://pato.community/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiPlus size={18} stroke={white['30']} />
            <span>Ver mais publicações</span>
          </a>
        </Footer>
      )}
    </Container>
  );
};

import ButtonBackHeader from '@/components/Dashboard/ButtonBackHeader';
import HeaderInfo from '@/components/Dashboard/HeaderInfo';
import { Container } from './styles';

const DashboardFavorites: React.FC = () => {
  return (
    <>
      <ButtonBackHeader />
      <Container>
        <HeaderInfo
          namePage="AULAS FAVORITAS"
          student="Jone Doe"
          message="ao seu Espaço Aulas Favoritas! Aqui estão organizadas todas as aulas que você marcou como favoritas. Você pode clicar em uma aula para ser movido para ela."
        />
      </Container>
    </>
  );
};

export default DashboardFavorites;

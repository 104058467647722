import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, StyledArrow, Title, ButtonGoBack } from './styles';

type IButtonBackHeader = {
  title?: string;
};

const ButtonBackHeader: React.FC<IButtonBackHeader> = ({
  title = 'Voltar',
}) => {
  const { goBack } = useHistory();

  return (
    <Container>
      <ButtonGoBack type="button" onClick={goBack}>
        <StyledArrow size={21} />
        <Title>{title}</Title>
      </ButtonGoBack>
    </Container>
  );
};

export default ButtonBackHeader;

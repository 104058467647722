import React from 'react';
import { FiChevronUp } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { P } from '../../../Atoms/Texts';
import { ToTopButtonContainer } from './styles';

const ToTopButton: React.FC = () => {
  const {
    newColors: { white },
  } = useTheme();

  const handleClick = () => window.scrollTo(0, 0);

  return (
    <ToTopButtonContainer>
      <P size={14} weight="400">
        De volta ao topo
      </P>
      <button type="button" onClick={handleClick}>
        <FiChevronUp size={20} stroke={white['700']} />
      </button>
    </ToTopButtonContainer>
  );
};

export default ToTopButton;

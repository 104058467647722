import { useQuery } from '@tanstack/react-query';

type ICourseLastActivityResponse = {
  isLoading?: boolean;
  activity?: IFinalClass;
  nextAcivity?: IFinalClass;
  progress?: IAcivitiesProgression;
};

type IProps = {
  modules?: IGroupedClasses[];
  progression?: IAcivitiesProgression[];
};

export const useCourseLastActivity = ({
  modules,
  progression,
}: IProps): ICourseLastActivityResponse => {
  const { data, isLoading } = useQuery(
    [`course-last-activity`, `${modules?.length}-${progression?.length}`],
    () => {
      const classes = modules?.map(module => module.classes).flat() ?? [];

      const classProgressions = progression?.filter(classProgress => {
        const classRelated = classes?.find(
          classItem => classItem.id === classProgress.class.id,
        );
        return classRelated?.type === 'CLASS';
      });

      const classProgress = classProgressions?.[classProgressions.length - 1];
      if (!classProgress) return undefined;

      const activityIndex = classes?.findIndex(
        classItem => classItem.id === classProgress.class.id,
      );
      const activity = classes[activityIndex];
      const nextAcivity =
        activityIndex + 1 < classes.length
          ? classes[activityIndex + 1]
          : undefined;

      if (activityIndex < 0)
        return {
          activity: classes[0],
          progress: {} as IAcivitiesProgression,
          nextAcivity,
        };

      return { activity, progress: classProgress, nextAcivity };
    },
    { staleTime: Infinity, refetchOnMount: 'always' },
  );

  return { ...data, isLoading } || {};
};

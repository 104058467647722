import { comparePureModules } from '../../util/module.manipulator';
import api from './main.api';

export async function getModulesFromCourse(
  courseId: string,
): Promise<IModule[]> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { items },
    },
  } = (await api.get(`/courses/${courseId}/modules`, {
    headers: { authorization },
  })) as { data: { data: { items: IModule[] } } };
  return items.sort(comparePureModules);
}

import styled from 'styled-components';

import * as Accordion from "@radix-ui/react-accordion";

export const Container = styled(Accordion.Root)`
  margin: 0;
  width: 100%;
  position: relative;
  z-index: 1;

  background: ${props => props.theme.newTheme.black['20']};

  .loading ul li {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 150px;
    background: ${props => props.theme.colors.black};
    p, small {
      margin: 20px 26px 0;
      flex: 1;
      color: ${props => props.theme.colors.lightGrey};
      background: ${props => props.theme.colors.lightGrey};
    }
    p {
      max-height: 25px;
    }
    small {
      max-height: 42px;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

import { FormHandles } from '@unform/core';
import axios, { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import * as Yup from 'yup';
import { useAlert } from '../../hooks/Alert';
import {
  getDiscordUserName,
  sendDiscordUserName,
} from '../../services/api/discord.api';
import { getValidationErrors } from '../../util/getValidationErrors';
import { discordValidation } from '../../util/Yup/discord';

import Input from '../RocketInput';
import { Container } from './styles';

type IDiscordButton = {
  userName: string;
};

export const DiscordButton: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [registeredeUserName, setRegisteredUserName] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    getDiscordUserName().then(({ userName }) =>
      setRegisteredUserName(userName),
    );
  }, []);

  const { showMessage } = useAlert();

  const handleSubmit = async (data: IDiscordButton) => {
    setIsLoading(true);
    formRef.current?.setErrors({});

    try {
      await discordValidation.validate(data, { abortEarly: false });

      await sendDiscordUserName(data);
      showMessage({
        type: 'SUCCESS',
        message: 'Usuário cadastrado!',
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        return;
      }
      if (axios.isAxiosError(error)) {
        error as AxiosError;
        if (error.response?.status === 409) {
          showMessage({
            type: 'ERROR',
            message:
              'Tenha certeza que já está em nosso servidor e tente novamente!',
          });
          return;
        }
      }
      showMessage({
        type: 'ERROR',
        message: 'Ocorreu um erro, tente novamente',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container onSubmit={handleSubmit} ref={formRef}>
      <Input
        name="userName"
        title="Tag do usuário"
        disabled={isLoading}
        placeholder="Gabriel Pato#1234"
        value={registeredeUserName}
      />
      {!registeredeUserName && (
        <button type="submit" disabled={isLoading}>
          <FiChevronRight size={20} />
        </button>
      )}
    </Container>
  );
};

import { useMemo } from 'react';
import { IUseProgressByActivity } from '../../hooks/useProgressByActivity';
import { ClassItem } from './ClassItem';
import {
  ModuleItemContainer,
  ModuleItemHeader,
  ModuleItemTrigger,
  ModuleItemTitle,
  ModuleItemContent,
  ModuleItemClassList,
  ModuleItemClassCount,
  ModuleItemChevronDown,
  ModuleItemChevronUp,
  ModuleItemHeaderTextContainer,
} from './styles';

type Props = {
  module?: IModule | null;
  groupedClass: IGroupedClasses;
  courseResume?: IMyCourseResume;
  progression?: IUseProgressByActivity;
};

export const ModuleItem: React.FC<Props> = ({
  module,
  groupedClass,
  progression,
  courseResume,
}) => {
  const completed = useMemo(() => {
    const totalClasses = groupedClass.classes.length;
    const completedClasses = groupedClass.classes
      .map(classItem => progression?.get(classItem.id))
      .filter(progressItem => progressItem?.state === 'COMPLETED').length;
    return `${completedClasses} de ${totalClasses} aulas concluídas`;
  }, [groupedClass, progression]);

  if (!module) return null;

  return (
    <ModuleItemContainer value={module.id}>
      <ModuleItemHeader>
        <ModuleItemTrigger>
          <ModuleItemHeaderTextContainer>
            <ModuleItemTitle>{module.name}</ModuleItemTitle>
            <ModuleItemClassCount>{completed}</ModuleItemClassCount>
          </ModuleItemHeaderTextContainer>
          <ModuleItemChevronDown />
          <ModuleItemChevronUp />
        </ModuleItemTrigger>
      </ModuleItemHeader>
      <ModuleItemContent>
        <ModuleItemClassList type="multiple">
          {groupedClass.classes.map(classItem => (
            <ClassItem
              key={classItem.id}
              classItem={classItem}
              courseResume={courseResume}
              progression={progression?.get(classItem.id)}
            >
              {classItem.title}
            </ClassItem>
          ))}
        </ModuleItemClassList>
      </ModuleItemContent>
    </ModuleItemContainer>
  );
};

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useCarroussel } from '../../../../hooks/useCarroussel';
import { LastActivityItem } from './LastActivityItem';
import {
  LastActivitiesCarrouseelHeader,
  LastActivitiesCarrousselContainer,
  LastActivitiesCarrousselList,
  LastActivitiesCarrousselScrollContainer,
  LastActivitiesCarrousselTitle,
  PaginatedButton,
  PaginatedButtonContainer,
} from './styles';

type Props = {
  lastActivities?: IFinalClass[];
  isLoading: boolean;
};

export const LastActivities: React.FC<Props> = ({ lastActivities = [] }) => {
  const { page, listRef, prevCards, nextCards, isntFirstPage, hasMoreCards } =
    useCarroussel({ length: lastActivities.length, cardWidth: 328 });

  return (
    <LastActivitiesCarrousselContainer>
      <LastActivitiesCarrouseelHeader>
        <LastActivitiesCarrousselTitle>
          Últimas postagens
        </LastActivitiesCarrousselTitle>
        <PaginatedButtonContainer>
          <PaginatedButton onClick={prevCards} disabled={!isntFirstPage}>
            <FiChevronLeft size={20} />
          </PaginatedButton>
          <PaginatedButton onClick={nextCards} disabled={!hasMoreCards}>
            <FiChevronRight size={20} />
          </PaginatedButton>
        </PaginatedButtonContainer>
      </LastActivitiesCarrouseelHeader>
      <LastActivitiesCarrousselScrollContainer>
        <LastActivitiesCarrousselList ref={listRef} translateValue={page}>
          {lastActivities.map(activity => (
            <LastActivityItem activity={activity} key={activity.id} />
          ))}
        </LastActivitiesCarrousselList>
      </LastActivitiesCarrousselScrollContainer>
    </LastActivitiesCarrousselContainer>
  );
};

import styled from 'styled-components';
import { ToggleClassListButton } from '../ClassPageComponents/ToggleClassList/styles';

export const Container = styled.div`
  margin: 8rem auto;
  padding: 0rem 1rem;
  width: 100%;
  max-width: 960px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${ToggleClassListButton} {
    margin: 0 0 3.2rem;
  }

  h1 {
    margin-top: 65px;
    font-weight: 600;
    line-height: 130%;
    /* identical to box height, or 26px */

    text-align: justify;
    letter-spacing: 0.01em;

    color: #F2EBFF;
  }

  label h6{
    margin-top: 40px;
    /* Small 14 Regular */

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    line-height: 150%;
    /* or 21px */

    display: flex;
    align-items: center;

    color: #FFFFFF;
  }

  @media screen and (max-width: 1024px) {
    max-width: none;
    margin: 6.4rem auto 2rem;

    iframe {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  max-width: 960px;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 1.6rem;
  }

  form {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  > form,
  > div {
    width: 100%;
  }

  > p {
    margin: 15px 0 35px;

    /* P 16 Regular */
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    line-height: 150%;
    /* or 24px */

    text-align: justify;

    color: rgba(255, 255, 255, 0.8);
  }
`;

export const ButtonsContainerDescription = styled.div`
  gap: 1.6rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2.4rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  gap: 1.6rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 6.7rem;

  button {
    height: 4.8rem;
    padding: 0;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const PaginatedButtons = styled.button`
  width: 4.8rem;
  height: 4.8rem;

  display: grid;
  place-items: center;
  border-radius: 50%;

  background: ${({ theme: { newColors } }) => newColors.black['500']};
  border: 1px solid ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.1 })};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25), inset 0px 5px 5px -20px rgba(255, 255, 255, 0.02);
  color: ${({ theme: { newColors } }) => newColors.white['900']};
  &:disabled {
    display: none;
  }

  &:active {
    transform: scale(.97);
  }

  @media screen and (max-width: 768px) {
    width: none;
    border-radius: 0.4rem;
  }
`;

import React, { useState } from 'react';
import Button from '../../components/Button';
import { labAuthentication } from '../../services/api';
import { P, Small } from '../../components/Atoms/Texts';
import { useAlert } from '../../hooks/Alert';
import { LabContainer, ButtonsContainer } from './styles';

const Lab: React.FC = () => {
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const { showMessage } = useAlert();

  const onConfirm = async () => {
    setIsLoadingToken(true);
    try {
      const userLabToken = await labAuthentication();
      window.location.href = `https://patoacademy.run/start?session=${userLabToken}`;
    } catch {
      showMessage({
        message: 'Aconteceu um erro! Tente novamente',
        type: 'ERROR',
      });
    } finally {
      setIsLoadingToken(false);
    }
  };

  return (
    <LabContainer>
      <P>
        Você tentou acessar um link do ambiente de exercícios da Pato
        Academy,&nbsp;
        <mark>mas não localizamos sessões de prática ativas</mark>
        &nbsp;em seu navegador.
      </P>
      <Small size={16}>Deseja iniciar uma nova sessão de exercício?</Small>
      <ButtonsContainer>
        <Button
          size="medium"
          type="button"
          colorType="blue"
          onClick={onConfirm}
          disabled={isLoadingToken}
          isLoading={isLoadingToken}
          data-cy="lab.session-start.confirm-button"
        >
          Sim
        </Button>
        <Button
          isntFilled
          isntColored
          type="button"
          size="medium"
          disabled={isLoadingToken}
          isLoading={isLoadingToken}
          data-cy="lab.session-start.cancel-button"
        >
          Não
        </Button>
      </ButtonsContainer>
      <Small size={14}>
        Ao criar uma nova sessão as anteriores serão invalidadeas
      </Small>
    </LabContainer>
  );
};

export default Lab;

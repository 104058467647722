import styled, { keyframes } from 'styled-components';

type IAlertType = 'SUCCESS' | 'ERROR' | 'WARNING';

interface IContainerProps {
  type: IAlertType;
  isVisible: boolean;
}

type IBaseAnimationDefaultProps = {
  duration?: string,
  timingFunction?: string,
  delay?: string,
  iterationCount?: string,
  direction?: string,
  fillMode?: string,
  playState?: string,
  display?: string
}

type IBaseAnimationProps = IBaseAnimationDefaultProps & IContainerProps;

const BaseAnimation = styled.div<IBaseAnimationProps>`
  animation-duration: ${props => props.duration || '1s'};
  animation-timing-function: ${props => props.timingFunction || 'ease'};
  animation-delay: ${props => props.delay || '0s'};
  animation-iteration-count: ${props => props.iterationCount || '1'};
  animation-direction: ${props => props.direction || 'normal'};
  animation-fill-mode: ${props => props.fillMode || 'both'};
  animation-play-state:  ${props => props.playState || 'running'};
  display: ${props => props.display || 'block'};
`;


const FallInAnimation = keyframes`
  from {
    top: -64px;
    opacity: 0;
  }
  to {
    top: 86px;
    opacity: 1;
  }
`;

const FloatOutAnimation = keyframes`
  from {
    top: 86px;
    opacity: 1;
  }
  to {
    top: -64px;
    opacity: 0;
  }
`;

export const Container = styled(BaseAnimation)`
  position: fixed;
  left: 50%;
  top: 86px;
  padding: 20px;
  display: flex;
  align-items: center;
  transform: translateX(-50%);
  margin: 0 auto 0;
  z-index: 100;
  max-width: 66.6rem;

  animation-duration: '4s';
  animation-timing-function: ease-in-out;
  animation-delay: '0s';
  animation-iteration-count: '1';

  animation-name: ${props => props.isVisible ? FallInAnimation : FloatOutAnimation};

  background: ${props => {
    switch (props.type) {
      case 'SUCCESS':
        return 'rgba(36, 255, 0, 0.15);';
      case 'ERROR':
        return 'rgba(255, 0, 0, 0.15);';
      case 'WARNING':
        return 'rgba(255, 199, 0, 0.15);';
      default:
        return 'rgba(36, 255, 0, 0.15);';
    }
  }};;
  border: 1px solid;
  border-color: ${props => {
    switch (props.type) {
      case 'SUCCESS':
        return '#8AFF6D';
      case 'ERROR':
        return '#FF6D7F';
      case 'WARNING':
        return '#FFC24D';
      default:
        return '#8AFF6D';
    }
  }};
  box-sizing: border-box;
  /* Background blur */

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  @supports (backdrop-filter: blur(20px)){
    backdrop-filter: blur(20px);
  }

  /* Note: backdrop-filter has minimal browser support */

  border-radius: 5px;

  p {
    /* P 16 Regular */

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    color: ${props => {
    switch (props.type) {
      case 'SUCCESS':
        return '#8AFF6D';
      case 'ERROR':
        return '#FF6D7F';
      case 'WARNING':
        return '#FFC24D';
      default:
        return '#8AFF6D';
    }
  }};
  }

  button {
    display: flex;
    background: none;
    outline: none;
    border-width: 0;
    margin-left: 20px;
    svg {
      stroke: ${props => {
    switch (props.type) {
      case 'SUCCESS':
        return '#8AFF6D';
      case 'ERROR':
        return '#FF6D7F';
      case 'WARNING':
        return '#FFC24D';
      default:
        return '#8AFF6D';
    }
  }};
    }
  }

  @media screen and (max-width: 768px) {
    width: auto;
    max-width: calc(100vw - 2rem);
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

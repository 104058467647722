import styled from 'styled-components';

type IContainerProps = {
  rate: number;
};

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 32px;
  height: 32px;

  > svg {
    width: 85%;

    position: absolute;
    fill: transparent;

    transform: rotate(-90deg);

    circle {
      stroke-linecap: round;
      stroke-dasharray: 292;
      stroke-dashoffset: ${props => (1 - props.rate) * 292};
    }

    .full {
      stroke-linecap: initial;
      stroke-dasharray: initial;
      stroke-dashoffset: initial;
      stroke: ${props => props.theme.colors.lightGrey}
    }
  }
`;

import { forwardRef } from 'react';
import { FiAlignLeft, FiBookOpen, FiStar, FiUsers } from 'react-icons/fi';

import { DescriptionContent } from './DescriptionContent';
import { ExtraContent } from './ExtraContent';
import { Feedback } from './Feedback';
import { Community } from './Community';

import { TabContainer, TabHeader, TabTrigger, TabContent } from './styles';

export type IFooterOptions = 'description' | 'extra' | 'feedback' | 'community';

type IClassFooterProps = {
  classItem: IClassType;
  selected?: IFooterOptions;
  setSelected?: (values: IFooterOptions) => void;
};

export const ClassFooterComponent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  IClassFooterProps
> = ({ selected, classItem, setSelected }, ref) => (
  <TabContainer
    ref={ref}
    value={selected}
    defaultValue="description"
    onValueChange={value => setSelected?.(value as IFooterOptions)}
  >
    <TabHeader aria-label="extra options">
      <TabTrigger
        value="description"
        data-cy="activity.footer.tabs.description"
      >
        <FiAlignLeft size={22} strokeWidth={1} />
        <span>Descrição</span>
      </TabTrigger>
      <TabTrigger value="community" data-cy="activity.footer.tabs.community">
        <FiUsers size={22} strokeWidth={1} />
        <span>Dúvidas da Comunidade</span>
      </TabTrigger>
      {classItem.extra.length > 0 && (
        <TabTrigger value="extra" data-cy="activity.footer.tabs.extra">
          <FiBookOpen size={22} strokeWidth={1} />
          <span>Materiais relacionados</span>
        </TabTrigger>
      )}
      <TabTrigger value="feedback" data-cy="activity.footer.tabs.feedback">
        <FiStar size={22} strokeWidth={1} />
        <span>Avalie essa aula</span>
      </TabTrigger>


    </TabHeader>

    <TabContent value="description">
      <DescriptionContent description={classItem.description} />
    </TabContent>
    {classItem.extra.length > 0 && (
      <TabContent value="extra">
        <ExtraContent classItem={classItem} />
      </TabContent>
    )}
    <TabContent value="feedback">
      <Feedback classId={classItem.id} />
    </TabContent>
    <TabContent value="community">
      <Community classItem={classItem} />
    </TabContent>
  </TabContainer>
);

export const ClassFooter = forwardRef(ClassFooterComponent);

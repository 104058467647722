import { differenceInDays, differenceInMilliseconds } from 'date-fns';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import convertTimeToMinutes from '../../../../../../util/convertTimeToMinutes';
import { pufiryHtmlText } from '../../../../../../util/purifyHtmlText';
import { H4 } from '../../../../../Atoms/Texts';
import {
  QuizTypeIcon,
  FlagTypeIcon,
  ClassTypeIcon,
  ClassItemTitle,
  ClassItemHeader,
  ClassItemTrigger,
  ClassItemContent,
  PracticeTypeIcon,
  ClassItemDuration,
  ClassItemChevronUp,
  ClassItemContainer,
  ClassItemMoreButton,
  ClassItemProgressBar,
  ClassItemDescription,
  ClassItemChevronDown,
  DisabledActivityIcon,
  ClassItemCompletedIcon,
  ClassItemProgressContainer,
} from './styles';

type IActivityIcon = {
  [key in IClassStyles]: IconType;
};

const ActivityIcon: IActivityIcon = {
  CLASS: ClassTypeIcon,
  QUIZ: QuizTypeIcon,
  PRACTICE: PracticeTypeIcon,
  FLAG: FlagTypeIcon,
};

type Props = {
  classItem: IFinalClass;
  courseResume?: IMyCourseResume;
  progression?: IAcivitiesProgression | null;
};

export const ClassItem: React.FC<Props> = ({
  classItem,
  courseResume,
  progression,
}) => {
  const hasntAcces = useMemo(() => {
    if (classItem.requirement.type === 'NONE') return false;

    if (classItem.requirement.type === 'START_DATE') {
      const diffDate = differenceInMilliseconds(
        new Date(classItem.requirement.startDate),
        new Date(),
      );
      return diffDate >= 0;
    }

    if (classItem.requirement.type === 'DAYS_SINCE_PURCHASE' && courseResume) {
      const diffDate = differenceInDays(
        new Date(),
        new Date(courseResume.createdAt),
      );
      return diffDate <= classItem.requirement.days;
    }
    return false;
  }, [classItem, courseResume]);

  const rate = useMemo(() => {
    if (classItem.type === 'CLASS') {
      const top = progression?.currentDuration || 0;
      const bottom = classItem.class?.duration || 1;
      const progress = top / bottom;
      return progress <= 1 ? progress : 1;
    }
    if (classItem.type === 'FLAG') {
      return 0;
    }
    if (classItem.type === 'PRACTICE') {
      return Number(progression?.state === 'COMPLETED');
    }
    if (classItem.type === 'QUIZ') {
      const top = progression?.questions?.length || 0;
      const bottom = classItem.questions?.length || 1;
      const progress = top / bottom;

      return progress > 1 ? 1 : progress;
    }
    return 0;
  }, [progression, classItem]);

  const IconActivity = ActivityIcon[classItem.type];

  return (
    <ClassItemContainer value={classItem.id}>
      <ClassItemHeader as="div">
        <ClassItemTrigger>
          <ClassItemTitle
            to={`/course/${classItem.course.id}/class/${classItem.id}`}
          >
            {hasntAcces ? <DisabledActivityIcon /> : <IconActivity />}
            <H4 size={16}>{classItem.title}</H4>
          </ClassItemTitle>
          <ClassItemMoreButton>
            {classItem.type === 'CLASS' && classItem?.class?.duration > 0 && (
              <ClassItemDuration>
                {convertTimeToMinutes(classItem.class.duration)}
              </ClassItemDuration>
            )}
            <ClassItemChevronDown />
            <ClassItemChevronUp />
          </ClassItemMoreButton>
        </ClassItemTrigger>
        {rate > 0 && progression?.state === 'COMPLETED' && rate === 1 && (
          <ClassItemProgressContainer>
            <ClassItemProgressBar
              max={1}
              value={rate}
              data-type={classItem.type}
            />
            <ClassItemCompletedIcon />
          </ClassItemProgressContainer>
        )}
      </ClassItemHeader>
      <ClassItemContent>
        <ClassItemDescription
          dangerouslySetInnerHTML={{
            __html: pufiryHtmlText(classItem.description),
          }}
        />
      </ClassItemContent>
    </ClassItemContainer>
  );
};

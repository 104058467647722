import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { forumApi } from '@/services/api/forum.api';
import { queryClient } from '@/App';

export async function getTopTopicsFromClassId(
  classId: string,
): Promise<IForumTopic[]> {
  const api = await forumApi();

  try {
    const response = await api.get(`/posts/class/${classId}/top`);

    // if (response.data.length === 0) {
    //   const fallbackResponse = await api.get(`/posts/class/${classId}/latest`);

    //   console.log(fallbackResponse.data);
    // }

    return response.data;
  } catch (error: unknown) {
    const { response } = error as AxiosError;

    if (response?.status === 404 && response.data.length === 0) {
      return response.data;
    }
  }

  return [];
}

export function invalidateTopTopicsFromClassQuery(classId: string): void {
  queryClient.invalidateQueries(['top-topics-from-class', classId]);
}

export function useTopTopicsFromClass(
  classId: string,
): UseQueryResult<IForumTopic[]> {
  const { data, ...rest } = useQuery(
    ['top-topics-from-class', classId],
    () => getTopTopicsFromClassId(classId),
    { staleTime: Infinity, refetchOnMount: 'always', retry: false },
  );

  const parsedData = data?.map(topic => ({
    ...topic,
    posts_count: Number(topic.posts_count) - 1, // -1 because the first post is the topic itself
    author: {
      ...topic.author,
      avatar_template: `https://pato.community${topic.author.avatar_template.replace(
        '{size}',
        '60',
      )}`,
    },
  }));

  return {
    data: parsedData,
    ...rest,
  } as UseQueryResult<IForumTopic[]>;
}

import { useState } from 'react';
import { useHistory } from 'react-router';
import { delay } from '../../util/delay';
import { useCookies } from '../../util/hooks/useCookies';
import { P } from '../Atoms/Texts';
import Button from '../Button';
import { Container } from './styles';

export const CookiesAlert: React.FC = () => {
  const { location } = useHistory();
  const [renderState, setRenderState] = useState(true);
  const { allowCookies, hasAlreadyAllowed } = useCookies();
  const isAllreadyAllowed = hasAlreadyAllowed();

  const isSignup = location.pathname.includes('signup');
  const isSignin = location.pathname.includes('signin');

  if (isAllreadyAllowed || isSignup || isSignin) return null;

  const handleClick = async () => {
    setRenderState(false);
    await delay(700);
    allowCookies();
  };

  return (
    <Container data-renderstate={renderState}>
      <P>Usamos cookies para garantir a você uma melhor experiência</P>
      <Button
        onClick={handleClick}
        isGradient
        isntFilled
        colorType="blue"
        size="small"
      >
        Ok
      </Button>
    </Container>
  );
};

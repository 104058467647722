import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios, { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { FiChevronLeft } from 'react-icons/fi';
import { useWindowSize } from 'react-use';
import { useAlert } from '../../../hooks/Alert';
import { useClass } from '../../../hooks/Class';
import { validateFlagAnswear } from '../../../services/api';
import { CustomError } from '../../../util/Errors/CustomError';
import { pufiryHtmlText } from '../../../util/purifyHtmlText';
import { H1, P } from '../../Atoms/Texts';
import Button from '../../Button';
import Input from '../../RocketInput';
import { ToggleClassList } from '../ClassPageComponents/ToggleClassList';
import DescriptionVideo from '../DescriptionVideo';
import Extra from '../Extra';
import { PaginatedProvider, usePaginated } from '../hooks/Paginated';
import { PaginatedContainer } from '../hooks/Paginated/PaginatedContainer';
import { PaginatedItem } from '../hooks/Paginated/PaginatedItem';
import { Loading } from '../Loading';
import { FlagCustomNextButton } from './CustomNextButton';
import { FlagButtons } from './FlagButtons';
import FlagLink from './FlagLink';

import {
  Container,
  Content,
  ButtonsContainer,
  PaginatedButtons,
  ButtonsContainerDescription,
} from './styles';

type IFormData = {
  flag: string;
};
interface IMainFlagProps {
  classItem: IFlagType;
}

const MainFlag: React.FC<IMainFlagProps> = ({ classItem }) => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const { findNextActivity, updateProgress, activitiesProgression, courseId } =
    useClass();
  const { showMessage } = useAlert();
  const { width, height } = useWindowSize();
  const { nextPage, prevPage, pageIdList } = usePaginated();
  const handleSubmit = async (data: IFormData) => {
    try {
      setIsLoading(true);
      await validateFlagAnswear(
        {
          classId: classItem.id,
          courseId,
        },
        {
          answers: [data.flag],
          state: 'COMPLETED',
        },
      );
      updateProgress();
      showMessage({
        message: 'Palavra Certa! Parabéns 🎉!',
        type: 'SUCCESS',
      });
      setIsRight(true);
      // findNextActivity(classItem.id);
    } catch (error) {
      if (error instanceof CustomError) {
        if (error.code === 'wrong') {
          showMessage({
            message: 'Palavra errada! Procure mais',
            type: 'ERROR',
          });
          return;
        }
      }
      if (axios.isAxiosError(error)) {
        error as AxiosError;
        showMessage({
          message: 'Ocorreu um erro! Tente novamente',
          type: 'ERROR',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextClass = () => findNextActivity(classItem.id);

  if (!classItem.flag) return Loading;

  const findedClassProgression = activitiesProgression.find(
    item => item.class.id === classItem.id,
  );

  const isCompleted = findedClassProgression?.state === 'COMPLETED';

  const placeholderText = findedClassProgression?.answers?.[0] || '';

  const handleMobileSubmit = () => formRef.current?.submitForm();

  const hasVideoPage = pageIdList.length > 1;

  return (
    <Container>
      <ToggleClassList />
      <PaginatedContainer>
        {classItem.flag.videoUrl && (
          <PaginatedItem id="description">
            <DescriptionVideo
              description={classItem.description}
              title={classItem.title}
              url={classItem.flag.videoUrl}
            />
            <ButtonsContainerDescription>
              <FlagCustomNextButton onClick={nextPage} />
            </ButtonsContainerDescription>
          </PaginatedItem>
        )}
        <PaginatedItem id="content">
          <Content>
            <H1 size={16} weight="500">
              Exercício prático
            </H1>
            <P
              dangerouslySetInnerHTML={{
                __html: pufiryHtmlText(classItem.flag.description),
              }}
            />
            <FlagLink
              flag={{
                description: isCompleted
                  ? 'Rever ambiente sandbox'
                  : 'Acesse o ambiente sandbox',
                url: classItem.flag.url,
              }}
            />
            {classItem.extra.length > 0 && (
              <ul>
                {classItem.extra.map(extraData => (
                  <Extra extra={extraData} key={extraData.url} />
                ))}
              </ul>
            )}
            <Form onSubmit={handleSubmit} ref={formRef}>
              <Input
                width="100%"
                name="flag"
                title="Insira a resposta secreta encontrada ao resolver o desafio"
                disabled={isCompleted}
                placeholder={isCompleted ? placeholderText : 'S3gr3d0'}
                description={
                  isCompleted
                    ? 'Atividade finalizada!'
                    : 'Preencha este campo com a palavra secreta que você localizou ao realizar o exercício no ambiente sandbox'
                }
              />
              <ButtonsContainer>
                {hasVideoPage && (
                  <PaginatedButtons type="button" onClick={prevPage}>
                    <FiChevronLeft size={20} />
                  </PaginatedButtons>
                )}
                <Button
                  isGradient
                  size="large"
                  hasSmallText
                  type={isCompleted ? 'button' : 'submit'}
                  onClick={isCompleted ? handleNextClass : undefined}
                  colorType="blue"
                  isLoading={isLoading}
                >
                  {isCompleted ? 'Próxima atividade' : 'Concluir'}
                </Button>
              </ButtonsContainer>
              <FlagButtons
                isLoading={isLoading}
                isCompleted={isCompleted}
                handleNextClass={handleNextClass}
                handleSubmit={handleMobileSubmit}
              />
            </Form>
            {isRight && <Confetti width={width} height={height} />}
          </Content>
        </PaginatedItem>
      </PaginatedContainer>
    </Container>
  );
};

export default ({ classItem }: IMainFlagProps): JSX.Element => (
  <PaginatedProvider>
    <MainFlag classItem={classItem} />
  </PaginatedProvider>
);

import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100vw;

  &::before {
    position: absolute;
    content: '';
    max-width: 1440px;
    width: 100%;
    height: 320px;
    left: 50%;
    top: -25%;
    z-index: -1;
    transform: translateX(-50%);
    pointer-events: none;

    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 84, 238, 0.1) 0%, rgba(255, 84, 238, 0) 100%);
  }
`;

export const FooterContainer = styled.footer`
  overflow: hidden;
  width: 100%;
  border-top: 1px solid ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.1 })};

  > div {
    position: relative;

    /* min-height: 640px; */
    max-width: 1460px;

    margin: 0 auto;
    padding: 0 6.4rem;

    display: flex;
    align-items: stretch;
    flex-direction: column;
    background-color: ${({ theme: { colors } }) => colors.black};

    &::before {
      position: absolute;
      content: '';
      width: 1174px;
      height: 1174px;
      left: -543px;
      top: -449px;
      pointer-events: none;

      background: radial-gradient(50% 50% at 50% 50%, rgba(0, 186, 244, 0.08) 0%, rgba(0, 186, 244, 0) 100%);
    }
    &::after {
      content: '';
      position: absolute;
      width: 1103px;
      height: 1103px;
      left: 655px;
      top: -65px;
      pointer-events: none;

      background: radial-gradient(50% 50% at 50% 50%, rgba(255, 84, 238, 0.08) 0%, rgba(255, 84, 238, 0) 100%);
    }
  }

  hr {
    height: 1px;
    background: ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.1 })};
  }
`;

export const ContentHeader = styled.header`
  padding: 8rem 0;
`;

export const ContentMain = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  z-index: 0;
  margin: 3.2rem 0;

  @media (max-width: 732px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

export const ContentFooter = styled.footer`
  min-height: 8rem;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    line-height: 1.4em;
    transition: all .3s;
    color: ${({ theme: { newColors } }) => newColors.white['500']}
  }

  div {
    margin: 1.5rem 0;
  }

  div:nth-child(2) {
    min-width: 45rem;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;

    button,
    a {
      position: relative;
      transition: all .3s;
      font-size: 1rem;
      text-align: start;

      :hover p {
        filter: brightness(.8);
      }

      :active {
        top: 1px;
      }
    }
  }

  div:nth-child(3) {
    margin: 0;
    padding: 0;
    height: 0;
    min-width: 15rem;
  }

  @media (max-width: 732px) {
    div:nth-child(2) {
      min-width: 0;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const CopyRightContent = styled.div`
  max-width: 35rem;
`;

/* eslint-disable react/no-danger */
import { DescriptionContentContainer } from './styles';
import { pufiryHtmlText } from '../../../../../util/purifyHtmlText';

type IDescriptionContent = {
  description: string;
};

export const DescriptionContent: React.FC<IDescriptionContent> = ({
  description,
}) => (
  <DescriptionContentContainer>
    <p
      data-cy="activity.footer.class-description"
      dangerouslySetInnerHTML={{
        __html: pufiryHtmlText(description),
      }}
    />
  </DescriptionContentContainer>
);

import api from './main.api';

export async function getAllCourses(): Promise<ICourse[]> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { items },
    },
  } = (await api.get('/courses', { headers: { authorization } })) as {
    data: { data: { items: ICourse[] } };
  };
  return items.sort((first, second) => {
    return first.createdAt > second.createdAt ? 1 : -1;
  });
}

export async function getMyCourses(): Promise<IMyCourseResume[]> {
  const authorization = localStorage.getItem('patoToken');

  try {
    const {
      data: {
        data: { items },
      },
    } = (await api.get('/courses', {
      headers: { authorization },
    })) as {
      data: { data: { items: IMyCourseResume[] } };
    };
    return items;
  } catch (error) {
    return [];
  }
}

export async function getCourseData(
  courseId: string,
): Promise<IDetailedCourse> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: { data: course },
  } = await api.get<{ data: IDetailedCourse }>(`/courses/${courseId}`, {
    headers: { authorization },
  });
  return course;
}

export async function validateCourse(
  courseId: string,
): Promise<IMyCourseResume> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: { data },
  } = (await api.get(`/student/courses/${courseId}`, {
    headers: { authorization },
  })) as {
    data: { data: IMyCourseResume };
  };
  return data;
}

export async function getCourseProgression(
  courseId: string,
): Promise<IAcivitiesProgression[]> {
  console.log("Progression: Reading " + courseId)
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { items },
    },
  } = (await api.get(`/student/courses/${courseId}/classes`, {
    headers: { authorization },
  })) as {
    data: { data: { items: IAcivitiesProgression[] } };
  };
  return items.sort((first, second) => {
    return first.createdAt > second.createdAt ? 1 : -1;
  });
}

export async function rateCourse(
  courseId: string,
  data: IRateData,
): Promise<string> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { id },
    },
  } = (await api.post(`/courses/${courseId}/rate`, data, {
    headers: { authorization },
  })) as { data: { data: { id: string } } };
  return id;
}

export async function getRatedCourse(courseId: string): Promise<IRatedCourse> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: { data },
  } = (await api.get(`/courses/${courseId}/rate`, {
    headers: { authorization },
  })) as { data: { data: IRatedCourse } };
  return data;
}

type ISubscribeResponse = {
  id: string;
};

type IApiSubscribeSuccessResponse = {
  data: ISubscribeResponse;
};

export async function subscribeToWatingList(
  courseId: string,
): Promise<ISubscribeResponse> {
  try {
    const authorization = localStorage.getItem('patoToken');

    const { data } = await api.post<IApiSubscribeSuccessResponse>(
      `/courses/${courseId}/wait`,
      {},
      {
        headers: { authorization },
      },
    );
    return data.data;
  } catch ({ response: res }) {
    const response = res as unknown as { status?: number };
    if (response?.status === 409)
      throw new Error('Você já está na lista de espera');
    throw new Error('Unexpected Error');
  }
}

type IUpdateCardSubscriptionRequestData = {
  courseId: string;
  cardId: string;
};

export async function updateCardSubscription(
  data: IUpdateCardSubscriptionRequestData,
): Promise<void> {
  const authorization = localStorage.getItem('patoToken');

  await api.put(`/student/courses/${data.courseId}/cards`, data, {
    headers: { authorization },
  });
}

type IUpdateRenewalSubscription = {
  courseId: string;
  active: boolean;
};

export async function updateRenewalSubscription(
  data: IUpdateRenewalSubscription,
): Promise<void> {
  const authorization = localStorage.getItem('patoToken');

  await api.post(`/student/courses/${data.courseId}/renewal`, data, {
    headers: { authorization },
  });
}

type IUpdateSubscriptionCard = {
  courseId: string;
  cardId: string;
};

export async function updateSubscriptionCard(
  data: IUpdateSubscriptionCard,
): Promise<void> {
  const authorization = localStorage.getItem('patoToken');

  await api.post(`/student/courses/${data.courseId}/cards`, data, {
    headers: { authorization },
  });
}

export async function getCoursesPayments(): Promise<IPaymentCourseData[]> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { items },
    },
  } = await api.get<{ data: { items: IPaymentCourseData[] } }>(
    `/student/payments`,
    {
      headers: { authorization },
    },
  );
  return items;
}

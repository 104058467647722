import { getDetailedClass } from '@/services/api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { queryClient } from '@/App';

export function invalidateClassDataByIdQuery(classId: string): void {
  queryClient.invalidateQueries(['class-data', classId]);
}

export function useClassDataByIdQuery(
  classId: string,
): UseQueryResult<IFinalClass> {
  return useQuery(['class-data', classId], () => getDetailedClass(classId), {
    staleTime: Infinity,
  });
}

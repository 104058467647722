import { useEffect } from 'react';

export const LabRedirect = (): React.ReactElement => {
  useEffect(() => {
    const url = localStorage.getItem('last-practice');
    if (url) {
      // localStorage.removeItem('last-practice');
      window.open(url, '_self');
    }
  }, []);
  return <></>;
};

import { useClass } from '../../../../hooks/Class';
import { HideContent } from './HideContent';
import { ShowContent } from './ShowContent';
import { ToggleClassListButton } from './styles';

export const ToggleClassList: React.FC = () => {
  const { drawerVisible, setDrawerVisible } = useClass();

  const toggleVisible = () =>
    setDrawerVisible(prev => (prev === 'classList' ? undefined : 'classList'));

  return (
    <ToggleClassListButton
      onClick={toggleVisible}
      data-cy="activity.toggle.class-list"
    >
      {drawerVisible === 'classList' ? <HideContent /> : <ShowContent />}
    </ToggleClassListButton>
  );
};

import { Link } from "react-router-dom";
import styled from "styled-components";
import { P } from "../../../../Atoms/Texts";

export const SpaceItemLink = styled(Link)`
  padding: 2.6rem;
  height: 7.2rem;
  min-width: 20rem;
  border-radius: .4rem;
  background: ${props => props.theme.newTheme.black['30']};
  border: 1px solid transparent;

  gap: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    background: ${props => props.theme.newTheme.black['50']};

    border: 1px solid ${props => props.theme.newTheme.border['20']};
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

export const SpaceItemTitle = styled(P)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 150%;

  text-align: justify;
  color: ${props => props.theme.newTheme.white['30']};
`;

export const SpaceItemContainer = styled.li`
  flex: 1;
  border-radius: .4rem;

  svg {
    stroke: ${props => props.theme.newTheme.white['30']};
  }
`;

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FiGrid } from 'react-icons/fi';
import { useCloseMenuRef } from '@/util/hooks/useCloseMenuRef';
import { Small } from '@/components/Atoms/Texts';
import { Container, Dashboard } from './styles';

const Progress: React.FC = () => {
  const { ref } = useCloseMenuRef<HTMLDivElement>();

  const params = useParams();

  const { courseId } = params as { courseId: string };

  return (
    <Container ref={ref}>
      <Link to={`/course/${courseId}`}>
        <Dashboard>
          <FiGrid size={20} />
          <Small size={14}>Dashboard</Small>
        </Dashboard>
      </Link>
    </Container>
  );
};

export default Progress;

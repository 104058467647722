import { Link } from "react-router-dom";
import styled from "styled-components";

export const ExtraItemLink = styled(Link)`
  padding: 2.6rem;

  gap: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h3 {
    color: inherit;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 150%;
  }
  svg {
    fill: inherit;
    stroke: inherit;
  }
`;

export const ExtraItemHeader = styled.a`
  padding: 2.6rem;

  gap: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h3 {
    color: inherit;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 150%;
  }
  svg {
    fill: inherit;
    stroke: inherit;
  }
`;

export const ExtraItemContainer = styled.li`
  gap: 2.6rem;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  border-radius: 0.6rem;
  background: ${props => props.theme.newTheme.black['20']};
  border: 1px solid ${props => props.theme.newTheme.border['20']};

  &[data-type="URL"] ${ExtraItemHeader} {
    color: ${props => props.theme.newTheme.green['20']};
    stroke: ${props => props.theme.newTheme.green['20']};
  }

  &[data-type="PDF"] ${ExtraItemHeader} {
    color: ${props => props.theme.newTheme.red['20']};
    fill: ${props => props.theme.newTheme.red['20']};
  }

  &[data-type="CODE"] ${ExtraItemHeader} {
    color: ${props => props.theme.newTheme.yellow['20']};
    stroke: ${props => props.theme.newTheme.yellow['20']};
  }

  &[data-type="CLASS"] ${ExtraItemLink} {
    color: ${props => props.theme.newTheme.red['20']};
    stroke: ${props => props.theme.newTheme.red['20']};
  }

  &[data-type="ARTICLE"] ${ExtraItemHeader} {
    color: ${props => props.theme.newTheme.blue['20']};
    fill: ${props => props.theme.newTheme.blue['20']};
  }
`;

export const ExtraItemDescription = styled.span``;

import Button from '../../../Button';

type IFlagCustomNextButton = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const FlagCustomNextButton = ({
  onClick,
}: IFlagCustomNextButton): JSX.Element | null => {
  return (
    <Button colorType="blue" type="button" onClick={onClick}>
      Acesso ao exercício
    </Button>
  );
};

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useClass } from '../../../../hooks/Class';
import { ClassPaginationContainer, NavigationButton } from './styles';

type IClassPaginationType = {
  title: string;
  classId: string;
};

export const ClassPagination: React.FC<IClassPaginationType> = ({
  title,
  classId,
}) => {
  const { findNextActivity, findPreviousActivity } = useClass();

  const handlePreviousActivity = () => findPreviousActivity(classId);

  const handleNextActivity = () => findNextActivity(classId);

  return (
    <ClassPaginationContainer>
      <NavigationButton
        type="button"
        onClick={handlePreviousActivity}
        data-cy="activity.class.previous-activity"
      >
        <FiChevronLeft size={20} />
      </NavigationButton>
      <h2>{title}</h2>
      <NavigationButton
        type="button"
        onClick={handleNextActivity}
        data-cy="activity.class.next-activity"
      >
        <FiChevronRight size={20} />
      </NavigationButton>
    </ClassPaginationContainer>
  );
};

import styled, { css, keyframes } from 'styled-components';

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
`;

export const LoadingButtonSvg = styled.svg`
  height: 100%;
  circle {
    transform-origin: center;
    animation: 1s ${rotating} linear infinite;
  }
`;

export type IButtonStyleProps = {
  isLoading?: boolean;
  isGradient?: boolean;
  isntFilled?: boolean;
  isntColored?: boolean;
  chevron?: 'next' | 'prev';
  size?: 'larger' | 'large' | 'medium' | 'small' | 'full';
  colorType: 'green' | 'reddish' | 'yellow' | 'purple' | 'blue';
};

export const Container = styled.button<IButtonStyleProps>`
  width: 19rem;
  height: 5rem;
  padding: 0 20px;

  border-radius: 4.8px;
  border-width: 1px;

  border-style: solid;

  font-weight: 600;
  line-height: 150%;
  font-style: normal;
  font-family: 'Poppins';
  font-size: 1rem;

  transition: all .2s;

  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);

  display: flex;
  align-items: center;
  justify-content: ${props => (props.chevron && !props.isLoading ? 'space-between' : 'center')};

  &:active {
    transform: scale(.97);
  }

  &:hover {
    ${props => {
    if (!props.isLoading)
      switch (props.chevron) {
        case 'next': return css`
          transform: translateX(10px);
          `;
        case 'prev': return css`
          transform: translateX(-10px);
          `;
        default: return '';
      }
    return '';
  }}
    filter: brightness(1.1);
    ${({ colorType: color, isntColored, theme: { newColors, opacifyShadow } }) => isntColored ? css`
    filter: brightness(0.8);
    box-shadow: 0px 10px 20px -5px ${newColors.white[700]}${(0.25 * 256)
      .toString(16)
      .replace(/\.\w+/, '')
      .replace('100', 'ff')
      .padStart(2, '0')};
    `: css`
    box-shadow: 0px 10px 20px -5px ${opacifyShadow({ color, opacity: 0.25 })}};
    `}
  }

  ${({ isntFilled, isGradient, isntColored, theme, theme: { opacifyShadow, opacifyColor }, colorType: color }) => {
    switch (true) {
      case isntColored:
        return css`
          background: none;
          border-color: ${opacifyColor({ color: 'white', opacity: 0.3 })};

          * {
            color: ${theme.colors.black};
          }

          background: #00000070;
      border-color: none;
      color: none;

      * {
        color: ${theme.newColors.white['700']};
      }
        `;
      case !isntFilled:
        return css`
        background: ${isGradient ? theme.buttonGradients[color] : theme.colors[color]};
        box-shadow: 0px 10px 20px -10px ${opacifyShadow({ color, opacity: 0.15 })};

        border-color: ${opacifyColor({ color: 'white', opacity: 0.3 })};
        color: ${theme.colors.black};

        * {
          color: ${theme.colors.black};
        }
        `;
      default:
        return css`
    background: linear-gradient(90deg,
      ${opacifyColor({ color, opacity: 0.16 })} 0%,
      ${opacifyColor({ color, opacity: 0.0 })} 100%
      ), #000000;
    border-color: ${theme.colors[color]};
    color: ${theme.colors[color]};

    * {
      color: ${theme.colors[color]};
    }
  `}
  }}

  width: ${props => {
    switch (props.size) {
      case 'larger':
        return '264px'
      case 'large':
        return '203px';
      case 'medium':
        return '162px';
      case 'small':
        return '132px';
      case 'full':
        return '100%';
      default:
        return '240px';
    }
  }};

  ${LoadingButtonSvg} circle {
    ${({ theme: { colors }, isntFilled, colorType: color }) => !isntFilled ?
    css`
      stroke:${colors.black};
    ` : css`
    stroke:${colors[color]};
    `
  }}
`;

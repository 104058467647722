import styled, { keyframes } from 'styled-components';

const indicator = keyframes`
  from {
    width: 0px;
    height: 0px;
  }
  to {
    width: 1.2rem;
    height: 1.2rem;
  }
`;
interface ICheckboxContainerProp {
  checked: boolean;
}

export const Container = styled.div<ICheckboxContainerProp>`
  & + & {
    margin-top: 1rem;
  }

  > small {
    margin-top: 20px;
  }

  .error {
    margin-left: 32px;
    color: ${props => props.theme.newColors.red['400']};
  }

  input {
    appearance: none;
    width: 2.4rem;
    height: 2.4rem;
    border: 1px SOLID;
    border-color: ${props => (props.checked ? props.theme.colors.pastelGreen : "rgba(255,255,255,0.1)")};
    border-radius: 3px;
    display: grid;
    place-items: center;
    &:checked::after {
      content: ' ';
      border-radius: 1px;
      width: 1.2rem;
      height: 1.2rem;
      background: ${props => props.theme.gradients.pastelGreen({})};
      animation: ${indicator} .2s ease forwards;
    }
  }

  input:focus + label {
    outline: 1px solid ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.5 })};
  }

  > label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    small {
      line-height: 80%;
      line-height: 150%;
      flex: 1;
      color: #FFFFFF;
    }
  }

  &.blue {
    input {
      border-color: ${props => (props.checked ? props.theme.newTheme.blue['20'] : "rgba(255,255,255,0.1)")};
      &:checked::after {
        background: ${props => props.theme.newTheme.blue['20']};
      } 
    }
  }
`;

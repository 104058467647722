import styled from "styled-components";
import { H3 } from "../../../Atoms/Texts";

export const SpacesContainer = styled.section`
  width: 100%;
  gap: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SpacesTitle = styled(H3)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 150%;

  color: ${props => props.theme.newTheme.white['30']};
`;

export const ScacesList = styled.ul`
  width: 100%;

  gap: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 690px){
    align-items: stretch;
    flex-direction: column;
  }
`;
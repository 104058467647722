import { useField } from '@unform/core';
import React, { useEffect, useRef, useState } from 'react';
import { Small } from '../Atoms/Texts';

import { Container } from './styles';

interface ICheckboxFormProps {
  name: string;
  isChecked?: boolean;
  defaultChecked?: boolean;
  onClick?: (value: boolean) => void;
  styleColor?: string;
  id?: string;
  emitId?: (id: string | undefined) => void;
}

const CheckboxForm: React.FC<ICheckboxFormProps> = ({
  name,
  onClick,
  children,
  isChecked,
  defaultChecked,
  styleColor,
  id,
  emitId,
}) => {
  const [checked, setChecked] = useState(!!defaultChecked);
  const { fieldName, error, registerField, clearError } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setChecked(!!isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (defaultChecked) setChecked(defaultChecked);
  }, [defaultChecked]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  const toggleChecked = () => {
    const newValue = !checked;
    if (onClick) onClick(newValue);
    if (emitId) emitId(id);
    setChecked(newValue);
    clearError();
  };

  return (
    <Container checked={checked} className={styleColor} id={id}>
      {error && (
        <Small weight="400" className="error">
          {error}
        </Small>
      )}
      <label htmlFor={name}>
        <input
          ref={inputRef}
          type="checkbox"
          id={name}
          name={name}
          checked={checked}
          onChange={toggleChecked}
        />
        <Small weight="300">{children}</Small>
      </label>
    </Container>
  );
};

export default CheckboxForm;

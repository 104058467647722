import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

type ICloseMenuReturn<T> = {
  ref: RefObject<T>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  toggleMenu: IVoidFunction;
  closeMenu: IVoidFunction;
  openMenu: IVoidFunction;
};

export const useCloseMenuRef = <T extends HTMLElement>(
  defaultIsOpen = false,
): ICloseMenuReturn<T> => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        event.target &&
        !ref.current.contains(event.target as Node)
      ) {
        setTimeout(() => setIsOpen(false), 100);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const closeMenu = () => setIsOpen(false);
  const openMenu = () => setIsOpen(true);
  const toggleMenu = () => setIsOpen(prev => !prev);

  return {
    ref,
    isOpen,
    setIsOpen,
    toggleMenu,
    closeMenu,
    openMenu,
  };
};

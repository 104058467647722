import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 1.6rem;
  margin: 10rem auto 0;
  @media (min-width: 1024px) {
    padding: 0 4.8rem;
    max-width: 1440px;
  }
`;

export const ContainerResult = styled.div`
  margin-top: 2.4rem;
  min-height: 50vh;

  > h2 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    color: ${props => props.theme.newTheme.white['40']};
    margin-bottom: 1.6rem;
  }
`
export const GroupCards = styled.div`
  display: grid;
  gap: 0.8rem;
  padding-bottom: 4.0rem;

  @media (min-width: 1024px) { 
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
`
export class CustomError extends Error {
  message: string;

  code: string;

  constructor({ message, code }: { message: string; code: string }) {
    super(message);
    this.message = message;
    this.code = code;
  }
}

import { FiBook, FiBookOpen } from 'react-icons/fi';
import { SpaceItem, ISpaceItem } from './SpaceItem';
import { SpacesContainer, SpacesTitle, ScacesList } from './styles';

type ISpacesProps = {
  courseId: string;
};

export const Spaces: React.FC<ISpacesProps> = ({ courseId }) => {
  const spaces: ISpaceItem[] = [
    {
      color: 'yellow',
      link: `/course/${courseId}/grid`,
      title: 'Conteúdo do curso',
      Icon: FiBook,
    },
    {
      color: 'blue',
      link: `/course/${courseId}/library`,
      title: 'Materiais',
      Icon: FiBookOpen,
    },
    // {
    //   color: 'red',
    //   link: `/course/${courseId}/favorites`,
    //   title: 'Aulas favoritas',
    //   Icon: FiHeart,
    // },

  ];

  return (
    <SpacesContainer>
      <SpacesTitle>Acesse seus espaços</SpacesTitle>
      <ScacesList>
        {spaces.map(spaceItem => (
          <SpaceItem key={spaceItem.title} space={spaceItem} />
        ))}
      </ScacesList>
    </SpacesContainer>
  );
};

import { ExtraItem } from './ExtraItem';
import { ExtraContentContainer } from './styles';

type IExtraContentProps = {
  classItem: IClassType;
};

export const ExtraContent: React.FC<IExtraContentProps> = ({ classItem }) => {
  return (
    <ExtraContentContainer data-cy="activity.extra.list">
      {classItem.extra.map(extra => (
        <ExtraItem extra={extra} key={extra.url} />
      ))}
    </ExtraContentContainer>
  );
};

import styled from 'styled-components';

export const Card = styled.a`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.newTheme.black['30']};
  border-radius: 0.6rem;
  width: 100%;
  padding: 0.8rem;
  transition: all 200ms ease;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);

  &:hover {
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.3), 0px 2px 4px -1px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.theme.newTheme.black['40']};
  }

  @media (min-width: 1024px) {
    padding: 1.6rem;
  }
`

export const CardLesson = styled.span`
  display: block;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${props => props.theme.newTheme.white['40']};
`

export const CardTitle = styled.h4`
  font-size: 1.4rem;
  margin: 1rem 0;
  line-height: 150%;
  color: ${props => props.theme.newTheme.white['40']};
  font-weight: 500;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 1.6rem;
  }

  &.pdf, &.article {
    color: ${props => props.theme.newTheme.blue['20']};
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z' stroke='%2362BFEF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }

  &.code {
    color: ${props => props.theme.newTheme.yellow['20']};
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 17L10 11L4 5M12 19H20' stroke='%23FFE47A' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }

  &.url {
    color: ${props => props.theme.newTheme.green['20']};
    &::before {
	    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99996 13C10.4294 13.5742 10.9773 14.0492 11.6065 14.393C12.2357 14.7367 12.9315 14.9411 13.6466 14.9924C14.3617 15.0436 15.0795 14.9404 15.7513 14.6898C16.4231 14.4392 17.0331 14.0471 17.54 13.54L20.54 10.54C21.4507 9.59702 21.9547 8.334 21.9433 7.02302C21.9319 5.71204 21.4061 4.45797 20.479 3.53093C19.552 2.60389 18.2979 2.07805 16.987 2.06666C15.676 2.05526 14.413 2.55924 13.47 3.47003L11.75 5.18003M14 11C13.5705 10.4259 13.0226 9.95084 12.3934 9.60709C11.7642 9.26333 11.0684 9.05891 10.3533 9.00769C9.63816 8.95648 8.92037 9.05966 8.24861 9.31025C7.57685 9.56083 6.96684 9.95296 6.45996 10.46L3.45996 13.46C2.54917 14.403 2.04519 15.666 2.05659 16.977C2.06798 18.288 2.59382 19.5421 3.52086 20.4691C4.4479 21.3962 5.70197 21.922 7.01295 21.9334C8.32393 21.9448 9.58694 21.4408 10.53 20.53L12.24 18.82' stroke='%2348FFBD' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }

  &.class {
    color: ${props => props.theme.newTheme.red['20']};
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 2V22M17 2V22M2 12H22M2 7H7M2 17H7M17 17H22M17 7H22M4.18 2H19.82C21.024 2 22 2.97602 22 4.18V19.82C22 21.024 21.024 22 19.82 22H4.18C2.97602 22 2 21.024 2 19.82V4.18C2 2.97602 2.97602 2 4.18 2Z' stroke='%23FF7070' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }
`
export const CardDescription = styled.p`
  font-size: 1.4rem;
  line-height: 150%;
  color: ${props => props.theme.newTheme.white['40']};
  font-weight: 400;
`

import styled from "styled-components";

export const FlagButtonsContainer = styled.div`
  display: flex;
  width: 100vw;
  gap: 1.6rem;
  padding: 2.4rem 1.6rem 1.6rem;
  background: ${props => props.theme.newTheme.black['10']};

  button {
    flex: 1;
  }
`;

export const NavigationButton = styled.button`
  display: grid;
  place-items: center;
  border-radius: .4rem;

  background: ${({ theme: { newColors } }) => newColors.black['500']};
  border: 1px solid ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.1 })};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25), inset 0px 5px 5px -20px rgba(255, 255, 255, 0.02);
  color: ${({ theme: { newColors } }) => newColors.white['900']};
`;

import { useLocalStorage } from 'react-use';
import { useCallback, useEffect, useState } from 'react';
import { FiBookOpen, FiStar } from 'react-icons/fi';
import { useClass } from '../../../../hooks/Class';
import { Switcher } from '../../../Switcher';
import Button from '../../../Button';
import {
  NextActivityContainer,
  NextActivityTimer,
  NextActivityTitle,
  NextActivityFooter,
  RateButton,
  ExtrasButton,
  DisabledButton,
} from './styles';
import { useCountDown } from '../../../../util/hooks/useCountdown';
import { P } from '../../../Atoms/Texts';

type INextActivityProps = {
  classItem: IClassType;
  nextActivityTitle: string;
  onClickRateActivity?: () => void;
  onClickExtras?: () => void;
};

export const NextActivity: React.FC<INextActivityProps> = ({
  classItem,
  onClickExtras,
  nextActivityTitle,
  onClickRateActivity,
}) => {
  const [secondsLeft] = useCountDown(10);
  const [hasAutoTransition, setIsAutoTransition] = useLocalStorage<boolean>(
    '@pato.academy:auto-transition',
    false,
  );
  const [allowAutoTransition, setAllowAutoTransition] = useState(true);
  const { completeActivity, activitiesProgression } = useClass();

  const handleClick = useCallback(
    () =>
      completeActivity({
        state: 'COMPLETED',
        currentDuration: classItem.class.duration,
      }),
    [completeActivity, classItem],
  );

  useEffect(() => {
    if (secondsLeft === 0 && hasAutoTransition && allowAutoTransition)
      handleClick();
  }, [secondsLeft, hasAutoTransition, allowAutoTransition, handleClick]);

  const handleClickRateActivity = () => {
    setAllowAutoTransition(false);
    onClickRateActivity?.();
  };

  const handleClickExtras = () => {
    setAllowAutoTransition(false);
    onClickExtras?.();
  };

  const handleSwitch = () => setAllowAutoTransition(false);

  const hasCompletedActivity =
    activitiesProgression.find(item => item.class.id === classItem.id)
      ?.state === 'COMPLETED';

  return (
    <NextActivityContainer data-cy="activity.transition.container">
      <div>
        <NextActivityTimer>
          próxima aula
          {allowAutoTransition && (
            <>{hasAutoTransition && <> em {secondsLeft}</>}</>
          )}
        </NextActivityTimer>
        <NextActivityTitle data-cy="activity.transition.next-activity-title">
          {nextActivityTitle}
        </NextActivityTitle>
      </div>
      <main>
        <RateButton onClick={handleClickRateActivity}>
          <FiStar size={20} />
          <P>Avalie essa aula</P>
        </RateButton>
        {classItem.extra.length > 0 && (
          <ExtrasButton onClick={handleClickExtras}>
            <FiBookOpen size={20} />
            <P>Explore os materiais da aula</P>
          </ExtrasButton>
        )}
      </main>

      <NextActivityFooter>
        {hasCompletedActivity ? (
          <DisabledButton type="button" disabled>
            Concluída
          </DisabledButton>
        ) : (
          <Button
            onClick={handleClick}
            colorType="blue"
            data-cy="activity.transition.conclude-button"
          >
            Concluir
          </Button>
        )}
        <Switcher
          onChange={handleSwitch}
          checked={hasAutoTransition}
          setChecked={setIsAutoTransition}
          data-cy="activity.transition.auto-transition-switcher"
        >
          Transição automática
        </Switcher>
      </NextActivityFooter>
    </NextActivityContainer>
  );
};

import React, { useEffect, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import {
  ButtonDrop,
  HeaderContent,
  PopoverContent,
  PopoverRoot,
} from './style';
import CheckboxForm from '../CheckboxForm';

type IDropdownWithCheckbox = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  data: IFilter[];
  sendFiltersSelected: (data: IFilter[]) => void;
};

export type IFilter = {
  name: string | undefined;
  value: string | undefined;
  checked: boolean;
};

const DropdownWithCheckbox: React.FC<IDropdownWithCheckbox> = ({
  title,
  data,
  sendFiltersSelected,
  ...rest
}) => {
  const [filters, setFilters] = useState<IFilter[]>([]);

  useEffect(() => {
    if (filters.length <= 0) {
      setFilters(data);
    }
  }, [data, filters]);

  const clearFiltersSelected = () => {
    const clearFilters = filters.map(item => {
      return {
        ...item,
        checked: false,
      };
    });
    sendFiltersSelected([]);
    setFilters([...clearFilters]);
  };

  const selectedOption = (id?: string) => {
    const selectFilter = filters.map(item => {
      if (item.value === id) {
        return {
          ...item,
          checked: !item.checked,
        };
      }
      return {
        ...item,
      };
    });
    setFilters([...selectFilter]);
    sendFiltersSelected([...selectFilter.filter(filter => filter.checked)]);
  };

  return (
    <PopoverRoot>
      <Popover.Trigger asChild {...rest}>
        <ButtonDrop> {title} </ButtonDrop>
      </Popover.Trigger>
      <Popover.Portal>
        <PopoverContent sideOffset={8} align="end">
          <HeaderContent>
            {title}
            <button type="button" onClick={clearFiltersSelected}>
              Limpar
            </button>
          </HeaderContent>
          <div>
            {filters.map(item => (
              <CheckboxForm
                id={item.value}
                name={`${item?.name} ${item?.value}` ?? ''}
                key={item.value}
                styleColor="blue"
                emitId={selectedOption}
                isChecked={item.checked}
              >
                {item.name}
              </CheckboxForm>
            ))}
          </div>
        </PopoverContent>
      </Popover.Portal>
    </PopoverRoot>
  );
};

export default DropdownWithCheckbox;

import styled, { keyframes } from "styled-components";
import { P } from "../Atoms/Texts";

const fade = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -30%)
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%)
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 1000;


  &[data-has-blur="true"]{
    background: ${props => props.theme.newTheme.black['50']}77;

    animation-duration: .2s;
    animation-name: ${fade};
    animation-direction: normal;
    animation-timing-function: ease-in;
  }
`;

const appear = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -30%)
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%)
  }
`;

export const Content = styled.div`
  padding: 3.2rem 2.4rem;
  width: 60rem;
  z-index: 2000 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  position: absolute;

  animation-duration: .2s;
  animation-name: ${appear};
  animation-direction: normal;
  animation-timing-function: ease-in;

  background: #0A090B;

  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 8px;

  > ${P} {
    line-height: 150%;
    margin: 4.8rem 0;
    color: ${({ theme: { newColors } }) => newColors.white['700']};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 3.7rem;
  right: 3rem;
`;

export const ButtonsContainer = styled.div`
  gap: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

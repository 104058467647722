/* eslint-disable prettier/prettier */
import { Form as Unform } from '@unform/web';

import styled from 'styled-components';
import backgroundImage from '../../assets/signup/background.jpg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;

  > section {
    display: flex;
    align-items: stretch;

    width: 990px;
    height: 645px;

    border-radius: 15px;
    background: ${props => props.theme.opacifyColor({ color: 'black', opacity: 0.8 })};
    border: 1px solid ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.1 })};

    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);

    fieldset + fieldset {
      margin-top: 2rem;
    }

    .image{
      border-radius: 15px 0 0 15px;
      min-width: 469px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(${backgroundImage});
    }
    .content{
      backdrop-filter: blur(0px);
      width: 100%;
    }
  }

  @media (max-width: 1024px){
    padding: 0 10px;
    > section {
      font-size: .8em;
      align-items: stretch;
      width: 100%;
      height: 550px;
      margin: 0;
      .image {
        min-width: 200px;
        width: 100%;
        max-width: 40%;
      }
    }
  }

  @media (max-width: 680px){
    padding: 0;
    > section {
      font-size: .8em;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 10px;
      border-radius: 0;
      .image {
        display: none;
      }
    }
  }

  @media (max-width: 425px), (max-height: 660px){
    padding: 0;
    > section {
      font-size: .8em;
      padding: 8px;
      align-items: center;
      width: 100%;
      height: 100vh;
      border-radius: 0;
      margin: 0;
    }
    .image {
      display: none;
    }
  }
  `;

export const Form = styled(Unform)`
  margin: 80px 80px 140px;

  h3 {
    line-height: 120%;
    color: #ffffff;
    margin-bottom: 70px;
  }

  > p {
    margin-top: 15px;
    line-height: 150%;
    /* identical to box height, or 21px */

    text-decoration-line: underline;

    color: rgba(255, 255, 255, 0.8);
  }

  button {
    margin-top: 15px;
  }


  @media (max-width: 1024px){
    margin: 60px 60px 120px;
    > section {
      width: 100%;

      fieldset {
        width: 100%;
      }
    }
    button {
      max-width: 25em;
    }
  }

  @media (max-height: 660px) and (orientation: landscape){
    margin: 0;
    padding: 20px;

    h3 {
      margin-bottom: 30px;
    }

    section {
      display: flex;
      align-items: center;
      gap: 10px;
      > fieldset {
        width: 100% !important;
      }
      fieldset + fieldset {
        margin-top: 0;
      }
    }

    button {
      margin: 60px auto 0;
      font-size: 1.2em;
      max-height: 5.5em;
    }
  }

  @media (max-height: 500px) and (orientation: landscape){
    section {
      display: flex;
      align-items: center;
      gap: 10px;

      > fieldset {
        width: 100% !important;
      }
      > fieldset + fieldset {
        margin-top: 0 !important;
      }
    }

    button {
      margin-top: 15px;
      max-width: 15em;
    }
  }


  @media (max-width:680px){
    margin: 40px 40px;
    > section {
      max-width: 400px;
      fieldset {
        width: 100%;
      }

      > fieldset + fieldset {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 600px){
    button {
      margin: 15px auto 0;
      width: 60%;
    }
  }

  @media (max-width: 425px){
    margin: 10px;
    button {
      margin: 15px auto 0;
      width: 60%;
    }
  }
`;

import React from 'react';
import { pufiryHtmlText } from '../../../util/purifyHtmlText';
import { H1, P } from '../../Atoms/Texts';

import { Container } from './styles';

type IDescriptionVideoProps = {
  title: string;
  description: string;
  url: string;
};

const DescriptionVideo: React.FC<IDescriptionVideoProps> = ({
  title,
  description,
  url,
}) => (
  <Container>
    <H1 size={20} weight="600">
      {title}
    </H1>
    <P
      dangerouslySetInnerHTML={{
        __html: pufiryHtmlText(description),
      }}
    />
    <iframe
      width="100%"
      height="600"
      src={url}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      referrerPolicy="strict-origin"
    />
  </Container>
);

export default DescriptionVideo;

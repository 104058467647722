import { useEffect } from 'react';
import { usePaginated } from '..';
import { PaginatedItemStyled } from './styles';

type IPaginatedItemPrps = {
  id: string;
};

export const PaginatedItem: React.FC<IPaginatedItemPrps> = ({
  id,
  children,
}) => {
  const { setPageIdList, page, pageIdList } = usePaginated();
  useEffect(() => {
    setPageIdList(prev => {
      if (prev.find(item => item === id)) return prev;
      return [...prev, id];
    });
  }, [setPageIdList, id]);
  return (
    <PaginatedItemStyled data-disable={pageIdList[page] !== id}>
      {children}
    </PaginatedItemStyled>
  );
};

import { H5 } from '@/components/Atoms/Texts';
import LoadingAnimation from '@/components/ClassData/MainQuizz/QuestionContainer/LoadingAnimation';
import { getForumTokens } from '@/services/api/sso.api';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SsoContainer } from './styles';




const forumSsoUrl = `${process.env.REACT_APP_FORUM_BASE_URL}/session/sso_login`;

export const SSOPage: React.FC = () => {
  const { search } = useLocation();
  const { push } = useHistory();



  useEffect(() => {
  
    async function validateParams() {
      const urlParams = new URLSearchParams(search);
      const originSig = urlParams.get('sig');
      const originSso = urlParams.get('sso');
      if (!originSso || !originSig) {
        location.href="https://pato.community"
        return;
      }

      try {
      const { sig, sso, success } = await getForumTokens({
        sig: originSig,
        sso: originSso,
      });
      if (!success || !sig || !sso) {
      console.log("Redirecting to profile creation...")
       location.href="https://pato.academy/account/profile"
        return;
      }
      const forumParams = new URLSearchParams();
      forumParams.append('sig', sig);
      forumParams.append('sso', sso);
      window.location.replace(`${forumSsoUrl}?${forumParams.toString()}`);
     } catch(error: any) {
      console.log("[catch] Redirecting to profile creation...")
      location.href="https://pato.academy/account/profile"     
     }
    
    }
    validateParams();
  }, [search, push]);

  return (
    <SsoContainer>
      <H5>Acessando seu perfil da Pato.community ...</H5>
      <LoadingAnimation />
    </SsoContainer>
  );
};

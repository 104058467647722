import React from 'react';
import { FiChevronUp } from 'react-icons/fi';
import Icon from '../../Icon';

import { Container } from './styles';

interface ISectionProps {
  text?: JSX.Element;
  startsExpanded?: boolean;
}

const Section: React.FC<ISectionProps> = ({
  children,
  text,
  startsExpanded = false,
}) => (
  <Container open={startsExpanded}>
    <summary>
      {text}
      <Icon IconComponent={FiChevronUp} size={25} className="icon" />
    </summary>
    <div>{children}</div>
  </Container>
);

export default Section;

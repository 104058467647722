import React from 'react';
import { FiLogOut } from 'react-icons/fi';

import { useAuth } from '../../../hooks/Auth';
import { Small } from '../../Atoms/Texts';
import DropdownMenu from '../../DropdownMenu';
import Icon from '../../Icon';
// import NotificationMenu from '../../NotificationMenu';
import MyCourses from './MyCourses';
import AlertMenu from './AlertMenu';
// import RegistrationStatus from './RegistrationStatus';
import { Container, SignOutButton } from './styles';

type IHeaderProfileProps = {
  className: string;
};

const HeaderProfile: React.FC<IHeaderProfileProps> = ({ className }) => {
  const {
    // student: { isIdentityValidated },
    // user,
    signOut,
  } = useAuth();

  return (
    <Container className={className}>
      <SignOutButton type="button" onClick={signOut}>
        <Icon IconComponent={FiLogOut} color="red" size={24} />
        <Small>Sair</Small>
      </SignOutButton>
      {/* {!isIdentityValidated && user && user['custom:country'] === 'BR' && (
        <RegistrationStatus />
      )} */}
      {/* <NotificationMenu /> */}
      <AlertMenu />
      <DropdownMenu hasImage />
    </Container>
  );
};

export default HeaderProfile;

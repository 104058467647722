import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useHistory } from 'react-router';

import {
  getAllCourses,
  getModulesWithClassesFromStudentCourse,
  getCourseProgression,
  getMyCourses,
  getCourseData,
} from '../../services/api';
import { useAuth } from '../Auth';

type ICoursesContext = {
  courses?: IDetailedCourse[];
  myCourses?: ICourse[];
  myCoursesDetailed?: IMyCourse[];
  allClassess?: IFinalClass[];
  classesWatchedLimit: number;
  myCoursesResume?: IMyCourseResume[];
  updateMyCoursesData: () => Promise<void>;
};

const CourseContext = createContext<ICoursesContext>({} as ICoursesContext);

const CoursesContext: React.FC = ({ children }) => {
  const classesWatchedLimit = 50;
  const [, setIsLoading] = useState(false);
  const [courses, setCourses] = useState<IDetailedCourse[]>();
  const [myCourses, setMyCourses] = useState<ICourse[]>();
  const [myCoursesResume, setMyCoursesResume] = useState<IMyCourseResume[]>();
  const [allClassess, setAllClassess] = useState<IFinalClass[]>();
  const [myCoursesDetailed, setMyCoursesDetailed] = useState<IMyCourse[]>();

  const { push } = useHistory();
  const { user } = useAuth();

  const fetchCourseData = async (course: ICourse): Promise<IMyCourse> => {
    const courseProgression = await getCourseProgression(course.id);

    const timeWatched = courseProgression.reduce((prev, curr) => {
      if (curr.state === 'COMPLETED')
        setAllClassess(preAllClasses =>
          preAllClasses ? [...preAllClasses, curr.class] : [curr.class],
        );

      if (curr.currentDuration) return prev + curr.currentDuration;

      return prev;
    }, 0);

    const courseTotal = await getModulesWithClassesFromStudentCourse(course.id);

    const timeTotal = courseTotal.reduce(
      (prev, curr) =>
        prev +
        curr.classes.reduce((prev2, classItem) => {
          if (classItem.class) {
            return prev2 + Number(classItem.class.duration || 0);
          }
          return prev2;
        }, 0),
      0,
    );

    return {
      time: timeTotal,
      watched: timeWatched,
      progress: courseProgression,
      ...course,
      modules: courseTotal,
    };
  };

  const updateMyCoursesData = useCallback(async () => {
    const myCoursesData = await getMyCourses();
    setMyCoursesResume(myCoursesData);
  }, []);

  useEffect(() => {
    async function dowloadEachCourse(allCourses: ICourse[]) {
      const detailedAllCourses = await Promise.all(
        allCourses.map(course => getCourseData(course.id)),
      );
      setCourses(detailedAllCourses);
    }
    async function filterCourses() {
      const allCourses = await getAllCourses();
      dowloadEachCourse(allCourses);
      //const myCoursesData = await getMyCourses();
      //setMyCoursesResume(myCoursesData);
      return allCourses;
      //return allCourses.filter(course =>
      //  myCoursesData.find(myCourse => myCourse.course.id === course.id),
      //);
    }

    async function handleCourseList(myCourseList: ICourse[]) {
      setMyCourses(myCourseList);
      if (myCourseList.length === 0) {
        // push('/');
        return;
      }

      const fetchCoursesPromises: Promise<IMyCourse>[] = myCourseList.map(
        course => fetchCourseData(course),
      );
      const fetchedCourses = await Promise.all(fetchCoursesPromises);
      setMyCoursesDetailed(fetchedCourses);
    }

    setIsLoading(true);
    filterCourses()
      .then(handleCourseList)
      .catch(() => {
        setMyCourses(undefined);
        setMyCoursesDetailed(undefined);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [push, user]);



  return (
    <CourseContext.Provider
      value={{
        courses,
        myCourses,
        allClassess,
        myCoursesResume,
        myCoursesDetailed,
        classesWatchedLimit,
        updateMyCoursesData,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export default CoursesContext;

export const useCourse = (): ICoursesContext => {
  const context = useContext(CourseContext);
  if (!context) {
    throw new Error('Você deve usar o hook dentro do ClassProvider');
  }
  return context;
};

import styled from 'styled-components';

type IContainerProps = {
  total: number;
  correct: number;
  wrong: number;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;

  h2 {
    margin-top: 66px;

    /* H5 20 Semibold */
    font-family: 'Poppins';
    font-style: normal;
    line-height: 130%;
    /* identical to box height, or 26px */

    text-align: justify;
    letter-spacing: 0.01em;

    color: #F2EBFF;
  }

  button svg {
    height: 24px;
    width: 24px;
  }

  h6 {
    /* H6 18 Semibold */
    font-family: 'Poppins';
    font-style: normal;
    line-height: 150%;
    /* or 27px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
  }

  hr {
    margin: 25px 0;
    height: 2px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 1px;
  }

  > p {
    margin-top: 15px;
    /* P 16 Regular */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    line-height: 150%;

    color: rgba(255, 255, 255, 0.8);
  }

  .title {
    display: flex;
    align-items: center;

    margin-bottom: 15px;

    p {
      margin-left: 15px;

      /* P 16 Regular */
      font-family: 'Poppins';
      font-style: normal;
      line-height: 150%;
      color: ${props => props.theme.newColors.green['400']};
    }
  }


  .resumeContent {
    > div + div{
      margin-top: 25px;
    }
  }

  .red p {
    color: ${props => props.theme.newColors.red['400']};
  }

  .question {
    margin-bottom: 20px;
    /* Small 14 Regular */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: justify;
    letter-spacing: 0.01em;

    color: rgba(255, 255, 255, 0.9);
  }

  .wrong {
    margin-bottom: 12px;
    /* Small 14 Regular */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.01em;

    color: rgba(255, 255, 255, 0.5);

    text-decoration-line: line-through;
    b {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .answear {
    /* Small 14 Regular */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.01em;

    color: rgba(255, 255, 255, 0.9);
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 50px;
    padding: 20px;
  }
`;

export const ResumeBars = styled.div<IContainerProps>`
  position: relative;
  margin: 4rem 0;
  padding: 2.8rem 0;
  width: 100%;
  border-radius: 0.6rem;
  border: 1px solid ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.2 })};
  background: ${({ theme: { newColors } }) => newColors.black['900']};
  > div {
    > div {
      height: 25px;

      border-radius: 0px 5px 5px 0px;
    }

    display: flex;
    align-items: center;

    + div {
      margin-top: 25px;
    }
  }

  .correctBar {
    width: calc(4rem + ${props => `(((100% - (3.6rem * 2))/${props.total}) * ${props.correct})`});
    background: ${props => props.theme.newGradients.green};
  }

  .wrongBar {
    width: calc(4rem + ${props => `(((100% - (3.6rem * 2))/${props.total}) * ${props.wrong})`});
    background: ${props => props.theme.newGradients.reddish};
  }

  small {
    margin: 0 0 0 12px;/* Small 14 Medium */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(255, 255, 255, 0.9);
  }

  .background {
    position: absolute;
    top: 0;
    margin: 0 3.6rem;
    height: 100%;
    width: calc(100% - (3.6rem * 2));
    color: ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.2 })};
    border-top-width: 0;
    border-bottom-width: 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    svg {
      height: 100%;
    }
    &::after {
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.2 })};
    }
  }
`;

export const ButtonContainer = styled.div`
  gap: 1rem;
  padding: 0 1rem;
  display: flex;
  justify-content: flex-end;
  button {
    font-size: 1rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;


import axios, { AxiosInstance } from 'axios';

export async function forumApi(): Promise<AxiosInstance> {
  const authorization = localStorage.getItem('patoToken');

  return axios.create({
    baseURL: '/api/community/',
    headers: {
      Authorization: authorization,
    },
  });
}

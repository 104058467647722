import React from 'react';
import Waves from '../../../assets/background-signup.svg';

import { Container } from './styles';

const SignUpBackground: React.FC = () => {
  return (
    <Container>
      <img src={Waves} alt="backgroundsvg" />
    </Container>
  );
};

export default SignUpBackground;

import { FiStar } from "react-icons/fi";
import styled, { keyframes } from "styled-components";
import { P } from "../../../Atoms/Texts";

export const ProgressCardContainer = styled.div`
  width: 100%;
  padding: 2.4rem;
  border-radius: .4rem;
  background: ${props => props.theme.newTheme.black['30']};
  gap: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    padding: 1.6rem;
  }
`;

const loadingHeaderText = keyframes`
  0% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
`;

export const ProgressHeader = styled.header`
  gap: 1.2rem;
  display: flex;
  align-items: center;

  &[data-loading="true"] {
    background: ${props => props.theme.newTheme.black['20']};
    animation: 1s ${loadingHeaderText} ease-in-out infinite alternate;
  }
`;

export const ProgressStarIcon = styled(FiStar)`
  font-size: 1.8rem;
  fill: ${props => props.theme.newTheme.green['20']};
  stroke: ${props => props.theme.newTheme.green['20']};
  ${ProgressHeader}[data-loading="true"] & {
    fill: transparent;
    stroke: transparent;
  }
`;

export const ProgressText = styled(P)`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  color: ${props => props.theme.newTheme.white['30']};
  b {
    font-weight: 600;
    color: ${props => props.theme.newTheme.green['20']};
  }

  ${ProgressHeader}[data-loading="true"] & {
    color: transparent;
    b {
      color: transparent;
    }
  }
`;

export const ProgressFooterText = styled(P)`
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  color: ${props => props.theme.newTheme.white['40']};
`;

export const ProgressBar = styled.progress`
  width: 100%;
  /* -webkit-appearance: none; */
  /* appearance: none; */
  height: .5rem;

  &::-webkit-progress-bar {
    border-radius: .3rem;
    background:  ${props => props.theme.newTheme.black['60']};
  }
  
  &::-webkit-progress-value {
    border-radius: .3rem;
    background:  ${props => props.theme.newTheme.green['20']};
  }

  &[data-loading="true"]::-webkit-progress-bar {
    background:  ${props => props.theme.newTheme.black['60']};
  }
  &[data-loading="true"]::-webkit-progress-value {
    background:  ${props => props.theme.newTheme.black['60']};
  }
`;

import styled from 'styled-components';
import { FiX } from 'react-icons/fi'

export const Container = styled.div`
  height: 100%;
  width: calc(100vw - 36rem);
  display: block;
  position: fixed;
  z-index: 5;
  margin: 6rem auto 0;
  right: 0;
  /* transition: all .2s ease; */
  background-color: ${props => props.theme.newTheme.black['20']};
  padding: 24px 48px;
  overflow-y: auto;
  padding-bottom: 8rem;

  &::-webkit-scrollbar{
    width: 20px;
  }

  &::-webkit-scrollbar-track{
    background-color: none;
  }

  &::-webkit-scrollbar-thumb{
    background-color: ${props => props.theme.newColors.black['400']};
    border-radius: 20px;
    height: 60px;
    background-clip: content-box;
    border: 8px solid transparent;
  }

  &::-webkit-scrollbar-button{
    background: none;
    height: 5px;
  }

  &::-webkit-scrollbar-corner{
    background: none;
  }

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.2rem;
  }
`;

export const StyledCloseIcon = styled(FiX)``;

export const CLoseButton  = styled.button`
  display: flex;
`;

export const CardTitle = styled.div`
  padding: 16px;
  background-color: ${props => props.theme.newTheme.black['50']};
  border: 1px solid ${props => props.theme.newTheme.border['20']};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
`;
import { createContext, useContext, useRef, useState } from 'react';
import { Dialog } from '../../components/Dialog';
import { DialogError } from '../../components/Dialog/DialogError';

type IPromiseResolve = () => void;
type IPromiseReject = (value?: unknown) => void;

type IPromiseLike = {
  resolve?: IPromiseResolve;
  reject?: IPromiseReject;
};

type IShowDialog = (config: IDialogConfig) => Promise<void>;

type IDialogContext = {
  dialogConfig?: IDialogConfig;
  hasCustomDialog: boolean;
  setHasCustomDialog: React.Dispatch<React.SetStateAction<boolean>>;
  showDialog: (config: IDialogConfig) => Promise<void>;
  onCancel: (message?: string) => void;
  onConfirm: () => void;
};

const DialogContext = createContext<IDialogContext>({} as IDialogContext);

export const DialogProvider: React.FC = ({ children }) => {
  const [dialogConfig, setDialogConfig] = useState<IDialogConfig | undefined>();
  const [hasCustomDialog, setHasCustomDialog] = useState(false);
  const promise = useRef<IPromiseLike>({} as IPromiseLike);

  const createPromise = (
    resolve: (value: void | PromiseLike<void>) => void,
    reject: (reason?: unknown) => void,
  ) => {
    promise.current.resolve = resolve;
    promise.current.reject = reject;
  };

  const showDialog: IShowDialog = async data => {
    setDialogConfig(data);

    const createdPromise = new Promise<void>(createPromise);

    return createdPromise;
  };

  const onCancel = (message?: string) => {
    if (promise.current?.reject) {
      promise.current.reject(new DialogError(message));
      promise.current.reject = undefined;
    }

    setDialogConfig(undefined);
  };

  const onConfirm = () => {
    if (promise.current?.resolve) {
      promise.current.resolve();
      promise.current.resolve = undefined;
    }
    setDialogConfig(undefined);
  };

  return (
    <DialogContext.Provider
      value={{
        onCancel,
        onConfirm,
        showDialog,
        dialogConfig,
        hasCustomDialog,
        setHasCustomDialog,
      }}
    >
      {children}
      {!hasCustomDialog && dialogConfig && (
        <Dialog
          dialogConfig={dialogConfig}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </DialogContext.Provider>
  );
};

export const useDialog = (): IDialogContext => {
  const context = useContext(DialogContext);
  if (!context)
    throw new Error('You must use useDialog inside a React Function Component');
  return context;
};

import { BeamerHelmet } from 'react-beamer';
import { FiBell } from 'react-icons/fi';
import { useMemo } from 'react';
import { useAuth } from '../../../../hooks/Auth';
import { useClass } from '../../../../hooks/Class';
import { H3, P, Small } from '../../../Atoms/Texts';
import stars from '../../../../assets/stars.svg';
import CircleProgress from '../../../ClassHeader/Progress/CircleProgress';
import Icon from '../../../Icon';
import {
  CourseInfoContainer,
  NotificationButton,
  CollapsibleRoot,
  CollapsibleTrigger,
  CollapsibleRateCourseTrigger,
  CollapsibleRate,
  CollapsibleProgress,
  ProgressBar,
} from './styles';
import { RateCourse } from './RateCourse';

export const CourseInfo: React.FC = () => {
  const {
    student: { email, name },
  } = useAuth();
  const {
    course,
    drawerVisible,
    allActivities: { classCount: totalCount },
    completedActivities: { classCount: completedCount },
  } = useClass();

  const rate = useMemo(() => {
    const calculatedRate = completedCount / totalCount;
    if (calculatedRate > 1) return 1;
    if (calculatedRate < 0) return 0;
    return calculatedRate;
  }, [completedCount, totalCount]);

  const realCompletedTotalCount =
    completedCount > totalCount ? totalCount : completedCount;
  return (
    <CourseInfoContainer data-is-visible={drawerVisible === 'settings'}>
      {course && (
        <div>
          <P>Você está acompanhando</P>
          <H3 data-cy="activity.sidebar.courseinfo.title">{course.title}</H3>
        </div>
      )}

      <NotificationButton
        type="button"
        className="bell beamerTrigger"
        data-cy="activity.sidebar.courseinfo.notify-button"
      >
        <Icon IconComponent={FiBell} size={27} />
        <Small>Notificações</Small>

        <BeamerHelmet
          id="nzbhqXTC45292"
          args={{
            selector: '.beamerTrigger',
            email,
            user_firstname: name,
          }}
        />
      </NotificationButton>

      <CollapsibleRoot>
        <CollapsibleTrigger data-cy="activity.sidebar.courseinfo.progress.trigger">
          <CircleProgress rate={rate} />
          <Small size={14}>Seu progresso</Small>
        </CollapsibleTrigger>
        <CollapsibleProgress data-cy="activity.sidebar.courseinfo.progress.content">
          <Small size={14}>
            <b>
              {realCompletedTotalCount} de {totalCount}
            </b>{' '}
            aulas assistidas
          </Small>
          <ProgressBar
            rate={rate}
            data-cy="activity.sidebar.courseinfo.progress.bar"
          />
          <hr />
          <Small size={14}>Keep hacking!</Small>
        </CollapsibleProgress>
      </CollapsibleRoot>


    </CourseInfoContainer>
  );
};

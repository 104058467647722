import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem;
  padding-top: 2.4rem;

  width: 100%;
  max-width: 41.6rem;
  border-radius: 4px;
  background-color: ${props => props.theme.newTheme.black['30']};

  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 100%;
    min-height: 20rem;
  }
`;

export const Header = styled.header`
  padding: 0 .8rem;

h2 {
    font-size: 1.6rem;
    color: ${props => props.theme.newTheme.white['20']};
  }

  span {
    margin-top: .4rem;
    color: ${props => props.theme.newTheme.white['40']};
    font-size: 1.4rem;
  }
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;

  background: linear-gradient(180deg, #13131600 0%, #131316 45%);
  /* background: red; */
  width: 100%;
  padding-top: 4.8rem;
  padding-bottom: 1.6rem;


  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .8rem;
    padding: .8rem 1.6rem;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.newTheme.border['10']};
    max-width: 20rem;
    margin: 0 auto;

    &:hover {
        border-color: ${({ theme }) => theme.newTheme.border['30']};
    }

    span {
      color: ${props => props.theme.newTheme.white['30']};
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
`;

import styled from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { H3, Small } from "../../../../Atoms/Texts";

export const ModuleItemContainer = styled(Accordion.Item)`
  margin-top: .8rem;
  border-radius: 4px;
`;

export const ModuleItemHeader = styled(Accordion.Header)`
  width: 100%;
  height: 6.4rem;

  background: ${props => props.theme.newTheme.black['30']};

  border: 1px solid ${props => props.theme.newTheme.border['10']};
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 4px 4px 0 0;
`;

export const ModuleItemTrigger = styled(Accordion.Trigger)`
  width: 100%;
  height: 100%;
  padding: 0 2.2rem 0 2.4rem;

  gap: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  `;

export const ModuleItemHeaderTextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media screen and (max-width: 625px) {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
`;

export const ModuleItemTitle = styled(H3)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  text-align: start;

  letter-spacing: 0.01em;
  color: ${props => props.theme.newTheme.white['10']};
`;

export const ModuleItemClassCount = styled(Small)`
  gap: 3rem;
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.5em;
  letter-spacing: 0.01em;

  color: ${props => props.theme.newTheme.white['50']};
`;

export const ModuleItemChevronDown = styled(FiChevronDown)`
  stroke-width: 1px;
  font-size: 2rem;
  ${ModuleItemContainer}[data-state="open"] &{
    display: none;
  }
`;

export const ModuleItemChevronUp = styled(FiChevronUp)`
  stroke-width: 1px;
  font-size: 2rem;
  ${ModuleItemContainer}[data-state="closed"] &{
    display: none;
  }
`;

export const ModuleItemContent = styled(Accordion.Content)`
  background: ${props => props.theme.newTheme.black['10']};
`;

export const ModuleItemClassList = styled(Accordion.Root)``;

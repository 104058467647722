import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

export const PopoverRoot = styled(Popover.Root)``;

export const PopoverTrigger = styled(Popover.Trigger)``;

export const ButtonDrop = styled.button`
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${props => props.theme.newTheme.white['40']};
  background-color: ${props => props.theme.newTheme.black['20']};
  border: 1px solid ${props => props.theme.newTheme.border['20']};
  border-radius: 0.4rem;
  height: 4.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid ${props => props.theme.newTheme.white['40']};
    margin-left: 1.6rem;
    transition: all 200ms ease-in;
    transform: rotate(180deg);
  }

  &[data-state='open'] {
    &::after {
      transform: rotate(0);
    }
  }
`;

export const PopoverContent = styled(Popover.Content)`
  border-radius: 4px;
  padding: 1.6rem;
  width: 28.8rem;
  background-color: ${props => props.theme.newTheme.black['20']};
  border: 1px solid ${props => props.theme.newTheme.border['20']};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  &:focus: {
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px violet,
  },
`;

export const HeaderContent = styled.header`
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
  button {
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

import React, { useRef, useState, useCallback } from 'react';
import * as Yup from 'yup';

import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { Container, Form } from './styles';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/Auth';
import Input from '../../components/RocketInput';
import SignUpBackground from '../../components/BackgroundComponents/SignUpBackground';
import { H3, P } from '../../components/Atoms/Texts';
import { useAlert } from '../../hooks/Alert';
import { validateSigninData } from '../../util/Yup/login';
import { getValidationErrors } from '../../util/getValidationErrors';

const { REACT_APP_RECAPTCHA_PUBLIC_KEY: token } = process.env as unknown as {
  REACT_APP_RECAPTCHA_PUBLIC_KEY: string;
};

type ISignInData = {
  email: string;
  password: string;
};

const SignInComponent: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { showMessage } = useAlert();
  const { executeRecaptcha } = useGoogleReCaptcha();

 

  const enableSignup = process.env.REACT_APP_ENABLE_SIGNUP === 'true';

  return (
    <Container>
      <section>
        <div className="image" />
        <div className="content">
        </div>
      </section>
      <SignUpBackground />
    </Container>
  );
};

const SignIn: React.FC = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey={token}
    scriptProps={{
      async: false,
    }}
  >
    <SignInComponent />
  </GoogleReCaptchaProvider>
);

export default SignIn;

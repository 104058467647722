import React, { createContext, useContext, useState } from 'react';
import AlertComponent, { IAlertProps } from '../../components/AlertComponent';
import { delay } from '../../util/delay';

type IShowMessageType = (
  data: Omit<IAlertProps, 'id'> & { clear?: true },
) => Promise<void>;
type IAlertData = IAlertProps & {
  id: string;
};
type IAlertContextData = {
  showMessage: IShowMessageType;
};

const AlertContext = createContext<IAlertContextData>({} as IAlertContextData);

const Alert: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<IAlertData[]>([]);
  const time = 0.6;

  const showMessage: IShowMessageType = async data => {
    const randomId = `${Math.floor(Math.random() * 1000)}`;
    setMessages(prev => {
      if (data.clear) return [{ ...data, id: randomId }];
      return [...prev, { ...data, id: randomId }];
    });
    await delay(4000 * time);
    setMessages(prev => prev.filter(item => item.id !== randomId));
  };

  return (
    <AlertContext.Provider value={{ showMessage } as IAlertContextData}>
      {messages.map(message => (
        <AlertComponent key={message.id} time={time} alertData={message} />
      ))}
      {children}
    </AlertContext.Provider>
  );
};

export default Alert;

export const useAlert = (): IAlertContextData => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used in a Alert Hook');
  }
  return context;
};

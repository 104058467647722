import styled from "styled-components";
import { Trigger, Content, Root } from '@radix-ui/react-collapsible';

export const CourseInfoContainer = styled.div`
  gap: 1.6rem;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background: ${props => props.theme.newTheme.black['10']};
  position: fixed;
  top: 60px;
  right: 0;
  width: 100vw;
  height: calc(100vh - 60px);
  z-index: 10;

  transform: translateX(100%);

  p {
    margin: 1.4rem 1.4rem .4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 150%;
    color: ${props => props.theme.newTheme.white['40']};
  }

  h3 {
    margin: 0 1.4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 150%;
    color: ${props => props.theme.newTheme.white['10']};
  }

  summary {
    appearance: none;
  }

  @media screen and (max-width: 768px) {
    &[data-is-visible="true"] {
      transform: translateX(0%);
    }
  }
`;

export const NotificationButton = styled.button`
  padding: 1rem;

  margin: 0 1.4rem 0;
  width: 100%;

  gap: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CollapsibleRoot = styled(Root)`
  padding: 0 1.1rem;
  width: 100%;
`;

export const CollapsibleTrigger = styled(Trigger)`
  width: 100%;
  padding: 1rem;

  gap: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CollapsibleRateCourseTrigger = styled(Trigger)`
  padding: 1rem 1.8rem;

  width: 100%;

  gap: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CollapsibleProgress = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  b {
    color: ${props => props.theme.newTheme.green['20']};
  }
  &[data-state="open"] {
    margin: 1.6rem;
  }

  @media screen and (max-width: 425px) {
    align-items: center;
  }
`;

export const CollapsibleRate = styled(Content)`
  margin: 0 1.6rem;
  padding: 1.6rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  b {
    color: ${props => props.theme.newTheme.green['20']};
  }
`;

type IProgressBarProps = {
  rate: number;
};

export const ProgressBar = styled.div<IProgressBarProps>`
  position: relative;
  margin: 15px 0;
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background: #191422;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    height: 100%;
    width: ${props => props.rate * 100}%;
    background: ${props => props.theme.newTheme.green['20']};
  }
`;

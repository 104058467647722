import React, { useEffect, useState } from 'react';

import { Container } from './styles';
import { Small } from '../../../../Atoms/Texts';

type IOptionProps = {
  data: IQuizzOption;
  cantChange?: boolean;
  quizzId: string;
  answer?: 'correct' | 'wrong';
  handleSelect?: (value: string) => void;
  isSelected: boolean;
};

const Option: React.FC<IOptionProps> = ({
  isSelected,
  data,
  handleSelect,
  answer,
  cantChange,
  quizzId,
}) => {
  const [selected, setSelected] = useState(isSelected);
  const [classId, setClassId] = useState(quizzId);

  useEffect(() => {
    if (classId !== quizzId) {
      setClassId(quizzId);
      setSelected(isSelected);
    }
    setSelected(isSelected);
  }, [classId, quizzId, isSelected]);

  const handleClick = () => {
    if (!cantChange) {
      setSelected(prev => !prev);
      if (handleSelect) handleSelect(data.id);
    }
  };
  return (
    <Container
      answer={answer}
      selected={selected}
      onClick={handleClick}
      cantChange={cantChange}
      isRight={answer === 'correct'}
    >
      <Small size={14} weight="300">
        {data.value}
      </Small>
    </Container>
  );
};

export default Option;

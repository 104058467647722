import React from 'react';
import { IUseProgressByActivity } from '../hooks/useProgressByActivity';
import { ModuleItem } from './ModuleItem';

import { Container } from './styles';

interface IModulesContainer {
  modulesMap?: Map<string, IModule>;
  groupedClasses?: IGroupedClasses[];
  courseResume?: IMyCourseResume;
  progression?: IUseProgressByActivity;
}

const ModulesContainer: React.FC<IModulesContainer> = ({
  groupedClasses,
  courseResume,
  progression,
  modulesMap,
}) => (
  <Container type="multiple">
    {groupedClasses?.map(groupedClass => (
      <ModuleItem
        module={modulesMap?.get(groupedClass.module.id)}
        groupedClass={groupedClass}
        key={groupedClass.module.id}
        progression={progression}
        courseResume={courseResume}
      />
    ))}
  </Container>
);

export default ModulesContainer;

import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`;

export const Container = styled.svg`
  animation: ${loading} .8s linear infinite;
  circle + circle {
    width: 24px;
    height: 24px;
    stroke-linecap: round;
    stroke-dasharray: 55;
    stroke-dashoffset: 5;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
  }
`;

import styled from 'styled-components';

export const Container = styled.a`
  cursor: pointer;
  flex-grow: 1;
  height: 64px;
  padding: 20px;
  border-radius: .8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${props => props.theme.newColors.black['900']};
  border: 1px solid ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.2 })};
  box-sizing: border-box;
  /* 1dp */

  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  > div{
    margin-right: 10px;
  }

  p {
    color: ${props => props.theme.colors.red}
  }
`;

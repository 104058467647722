import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { forumApi } from '@/services/api/forum.api';

export async function getLatestTopics(): Promise<IForumTopic[]> {
  const api = await forumApi();

  try {
    const response = await api.get(`/posts/latest`);

    return response.data;
  } catch (error: unknown) {
    const { response } = error as AxiosError;

    if (response?.status === 404 && response.data.length === 0) {
      return response.data;
    }
  }

  return [];
}

export function useLatestTopics(
): UseQueryResult<IForumTopic[]> {
  const { data, ...rest } = useQuery(
    ['latest-general-topics'],
    () => getLatestTopics(),
    { staleTime: 60 * 2 * 1000, refetchOnMount: 'always' },
  );

  //console.log(data);

   const parsedData = data?.map(topic => ({
     ...topic,
     posts_count: Number(topic.posts_count) - 1, // -1 because the first post is the topic itself
     author: {
       ...topic.author,
       avatar_template: `https://pato.community${topic.author.avatar_template.replace(
         '{size}',
         '60',
       ).replaceAll("//","/").replaceAll("https://pato.communityhttps://pato.community","https://pato.community")}`,
     },
   }));

  // console.log(parsedData);
  //const parsedData: IForumTopic[] = [];

  return {
    data: parsedData,
    ...rest,
  } as UseQueryResult<IForumTopic[]>;
}

import styled from "styled-components";
import { H2, P } from "../../components/Atoms/Texts";

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 146px auto;
  max-width: 72rem;
  gap: 4rem;

  padding: 0 8rem;
  text-align: center;

  ${H2} {
    line-height: 4.2rem;
  }

  ${P} {
    line-height: 150%;
    letter-spacing: 0em;
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  a {
    text-decoration: underline;
  }

  mark {
    background: none;
    font-weight: 600;
  }
  .green,
  mark {
    color: ${props => props.theme.newColors.green['400']};
  }

  img {
    max-width: 44.1rem;
  }

  @media screen and (max-width: 1024px){
    padding: 0 2rem;
  }
`;


import styled from "styled-components";
import { H3 } from "../../../Atoms/Texts";

export const LastActivitiesCarrousselContainer = styled.section`
  margin: 3.2rem 0;
`;

export const LastActivitiesCarrouseelHeader = styled.header`
  margin-bottom: 1.6rem;

  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const LastActivitiesCarrousselTitle = styled(H3)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 150%;

  color: ${props => props.theme.newTheme.white['30']};
`;

export const PaginatedButtonContainer = styled.div`
  gap: 1.6rem;
  display: flex;
  align-items: center;
`;

export const PaginatedButton = styled.button`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #1D1C21;

  border: 1px solid rgba(255, 255, 255, 0.1);

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25), inset 0px 5px 5px -20px rgba(255, 255, 255, 0.02);

  &:hover:not(:disabled) {
    background: ${props => props.theme.newTheme.black['50']};

    border: 1px solid ${props => props.theme.newTheme.border['30']};
  }

  :disabled {
    opacity: .6;
  }
`;

export const LastActivitiesCarrousselScrollContainer = styled.div`
  overflow-x: hidden;
`;

type ILastActivitiesUnorderedListProps = {
  translateValue: number;
}

export const LastActivitiesCarrousselList = styled.ul<ILastActivitiesUnorderedListProps>`
  gap: .8rem;
  display: flex;
  transition: 1s ease-out;
  align-items: flex-start;
  justify-content: flex-start;
  transform: translateX(-${props => props.translateValue}px);
`;

import React, { useState } from 'react';
import {
  FiChevronsLeft,
  FiChevronsRight,
  // FiCreditCard,
  // FiDollarSign,
  FiShield,
  FiUser,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Small } from '../Atoms/Texts';
import { useMobile } from '../ClassData/hooks/Mobile';
import discordIcon from '../../assets/discord/discord-icon.svg';
import Icon from '../Icon';

import { Container, Link, ToggleClassListButton } from './styles';

const SettingsSidebar: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { isMobileSize, showingContent, setShowingContent } = useMobile();
  const { location } = useHistory();

  const handleClick = () => {
    setIsVisible(prev => !prev);
    if (isMobileSize)
      setShowingContent(prev => (prev === 'classes' ? undefined : 'classes'));
  };

  const classListVisible = isMobileSize
    ? showingContent === 'classes'
    : isVisible;

  return (
    <Container isFull={classListVisible}>
      <ToggleClassListButton
        type="button"
        onClick={handleClick}
        isFull={classListVisible}
      >
        <Icon
          className="closeIcon"
          IconComponent={isVisible ? FiChevronsLeft : FiChevronsRight}
          size={25}
        />
      </ToggleClassListButton>
      <nav>
        <ul>
          {/*
          <Link
            colorType="grey"
            isActive={location.pathname === '/account'}
            to="/account"
          >
            <Icon IconComponent={FiUser} color="grey" size={24} isntGradient />
            <Small>Detalhes de Conta</Small>
          </Link>
          */}
          {/* <Link
            colorType="grey"
            isActive={location.pathname === '/billing'}
            to="/billing"
          >
            <Icon
              IconComponent={FiDollarSign}
              color="grey"
              size={24}
              isntGradient
            />
            <Small>Assinaturas e Pagamentos</Small>
          </Link> */}
          {/* <Link
            colorType="grey"
            isActive={location.pathname === '/creditcards'}
            to="/creditcards"
          >
            <Icon
              IconComponent={FiCreditCard}
              color="grey"
              size={24}
              isntGradient
            />
            <Small>Carteira</Small>
          </Link> */}
          {/*
          <Link
            colorType="grey"
            isActive={location.pathname === '/security'}
            to="/security"
          >
            <Icon
              IconComponent={FiShield}
              color="grey"
              size={24}
              isntGradient
            />
            <Small>Segurança</Small>
          </Link>
        */}
          <Link
            colorType="grey"
            isActive={location.pathname === '/discord'}
            to="/discord"
          >
            <img src={discordIcon} alt="Discord Icon" />
            <Small>Discord</Small>
          </Link>
        </ul>
      </nav>
    </Container>
  );
};

export default SettingsSidebar;

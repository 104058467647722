import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: white;
  height: 100%;
  padding: 16px 0;

  > * {
    margin: 0 15px;
  }

  > div:last-child {
    margin: 0;
    margin-left: 15px;
  }

  .image {
    position: relative;
  }

  small {
    /* Small 14 Regular */
    font-family: Poppins;
    line-height: 150%;
    /* identical to box height, or 21px */

    color: rgba(255, 255, 255, 0.8);
  }


  @media screen and (max-width: 1024px) {
    overflow-x: hidden;
    padding: 10px;
    flex-direction: column-reverse;
    justify-content: space-between;
    position: fixed;
    top:  88px;
    right: 0px;
    width: 299px;
    height: calc(100vh - 100px);
    transition: 1s;

    &.disable {
      transform: translateX(100%);
    }

    > hr {
      display: none;
    }

    .courses {
      display: none;
    }

    > div:last-child {
      margin: 0;
    }

    /* Black glass */
    background: ${props => props.theme.opacifyColor({ color: 'black', opacity: 0.8 })};
    border: 1px solid ${props => props.theme.opacifyColor({ color: 'black', opacity: 0.1 })};
    box-sizing: border-box;

    /* Background blur */
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 15px 0 0 15px;

    border: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 0px;

    .settings {
      position: relative;
      width: 100%;
      margin: 0;
    }
  }
`;

export const SignOutButton = styled.button`
  display: none;

  @media screen and (max-width: 1024px){
    font-size: 1rem;
    cursor: pointer;
    background: none;
    border-radius: 10px;
    border: 1px solid rgb(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 10px 15px;
    width: 100%;
    text-decoration: none;
    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
    :hover {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
`;

import { useEffect } from 'react';
import { useClass } from '@/hooks/Class';
import { Root } from '@radix-ui/react-accordion';
import CardClass from '@/components/CardClass';
import { useParams } from 'react-router';
import { useClassCard } from '@/util/hooks/useClassCard';
import {
  Container,
  InfoContent,
  LayerButton,
  ModuleAccordionOption,
  ModuleAccordionWrapper,
  StyledContent,
  StyledHeader,
  StyledItem,
  StyledTrigger,
} from './styles';

import { NextModuleButton } from './NextModuleButton';
import { StyledFiLayers } from '../ClassList/styles';

export const ClassFilter: React.FC = () => {
  const {
    moduleList,
    selectedClass,
    toggleShowLayer,
    showLayerClass,
    drawerVisible,
    selectedModuleItem,
    setSelectedModuleItem,
    classesInGroup,
  } = useClass();

  const { isntFinished, handleChecked, handleClick } = useClassCard();

  useEffect(() => {
    if (!!selectedClass?.module.id || !!moduleList[0]?.id)
      setSelectedModuleItem(selectedClass?.module.id || moduleList[0].id);
  }, [selectedClass, moduleList, setSelectedModuleItem]);

  const { id } = useParams<{ id: string }>();

  const module = moduleList.find(item => item.id === selectedModuleItem);

  const ModuleAccordion = Root;
  const ModuleAccordionItem = StyledItem;
  const ModuleAccordionTrigger = () => {
    return (
      <StyledHeader>
        <StyledTrigger>{module?.name}</StyledTrigger>
      </StyledHeader>
    );
  };

  const handleChangeModuleItem = (moduleID: string) =>
    setSelectedModuleItem(moduleID);

  const GroupAccordion = Root;
  const GroupAccordionTrigger = (title?: string, hidden?: boolean) => {
    return (
      <StyledHeader custom hidden={hidden}>
        <StyledTrigger>{title}</StyledTrigger>
      </StyledHeader>
    );
  };

  return (
    <Container
      data-cy="activity.classlist"
      data-visible={drawerVisible === 'classList'}
    >
      <header>
        <ModuleAccordionWrapper>
          <ModuleAccordion
            id="module"
            type="single"
            collapsible
            className="customAccordion"
            data-cy="activity.classlist.module.select"
          >
            <ModuleAccordionItem value={selectedModuleItem}>
              <ModuleAccordionTrigger />
              <StyledContent>
                <InfoContent>Selecione um módulo</InfoContent>
                <ul>
                  {moduleList.map(moduleItem => (
                    <li key={moduleItem.id}>
                      <ModuleAccordionOption
                        value={moduleItem.id}
                        type="button"
                        onClick={() => handleChangeModuleItem(moduleItem.id)}
                      >
                        {moduleItem.name}
                      </ModuleAccordionOption>
                    </li>
                  ))}
                </ul>
              </StyledContent>
            </ModuleAccordionItem>
          </ModuleAccordion>
        </ModuleAccordionWrapper>
        <LayerButton
          data-keywords-visible={showLayerClass}
          onClick={toggleShowLayer}
        >
          <StyledFiLayers size={20} strokeWidth="1" />
        </LayerButton>
      </header>
      <nav>
        <ul>
          {classesInGroup.map(group => (
            <li key={group.id}>
              <GroupAccordion
                type="single"
                collapsible
                {...(group?.hidden ? { defaultValue: group.id } : {})}
              >
                <ModuleAccordionItem value={group.id} custom>
                  {GroupAccordionTrigger(group.title, group.hidden)}
                  <StyledContent custom>
                    {group.classes.map(classItem => (
                      <CardClass
                        key={classItem.id}
                        classItem={classItem}
                        isntFinished={isntFinished(classItem.id)}
                        handleClick={handleClick}
                        handleChecked={() => handleChecked(classItem)}
                        isActual={id === classItem.id}
                        variant
                      />
                    ))}
                  </StyledContent>
                </ModuleAccordionItem>
              </GroupAccordion>
            </li>
          ))}
        </ul>
      </nav>
      <NextModuleButton />
    </Container>
  );
};

import { useField } from '@unform/core';
import React, { useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import Icon from '../../Icon';
import Input from '../../RocketInput';
import NetworkSelect from './NetworkSelect';

import { Container, RemoveButton } from './styles';

type INetworkItemProps = {
  index: number;
  defaultValue?: string;
  onUpdateItem: (network: IUserSocialType, index: number) => void;
  network: IUserSocialType;
  networksAvailable: IUserSocialType[];
  onRemove: (network: IUserSocialType) => void;
};

const NetworkItem: React.FC<INetworkItemProps> = ({
  index,
  network,
  onRemove,
  onUpdateItem,
  defaultValue,
  networksAvailable,
}) => {
  const { fieldName, registerField } = useField(
    `socialNetworks[${index}].name`,
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => network,
    });
  }, [fieldName, registerField, network]);

  const handleRemoveClick = () => {
    onRemove(network);
  };

  const handleChangeNetwork = (value: IUserSocialType) => {
    onUpdateItem(value, index);
  };

  return (
    <Container>
      <NetworkSelect
        network={network}
        onChange={handleChangeNetwork}
        networksAvailable={networksAvailable}
      />
      <Input
        name={`socialNetworks[${index}].url`}
        defaultValue={defaultValue}
      />
      <RemoveButton type="button" onClick={handleRemoveClick}>
        <Icon IconComponent={FiX} size={20} />
      </RemoveButton>
    </Container>
  );
};

export default NetworkItem;

import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { FiX, FiPlus } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { forumApi } from '@/services/api/forum.api';
import {
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogTitle,
  Form,
} from './styles';
import { File } from 'buffer';
import { set } from 'date-fns';

type EditForumProfileModalProps = {
  trigger: React.ReactNode;
};

export const EditForumProfileModal: React.FC<EditForumProfileModalProps> = ({
  trigger,
}) => {
  const {
    newTheme: { white },
  } = useTheme();
  const [nickname, setNickName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [profilePic, setProfilePic] = useState<File | Blob | null>(null);
  const [preview, setPreview] = useState<string | undefined>();

  const editUserMutation = useMutation(
    async (formData: { nickname: string }) => {
      const api = await forumApi();

      return api.post('/user', formData);
    },
  );




  async function handleInputFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file: any = e.target.files?.[0] || null;
    if(file) { 

    const reader = new FileReader();
    reader.onload = () => {
      setPreview(reader.result as string);
    };
    reader.readAsDataURL(file);

    // upload
    const formData = new FormData();
      formData.append("image", file as Blob)
      const api = await forumApi();

       api.post('/avatar', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });


  }

  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    
    editUserMutation.mutate({ nickname });
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const re = /^[a-zA-Z0-9._\u00C0-\u00FF]*$/;
    setNickName(e.target.value);

    if (e.target.value.length >= 23 || e.target.value.length <= 4) {
      setErrorMessage('O apelido deve ter entre 4 e 23 caracteres');
    } else if (!re.test(e.target.value)) {
      setErrorMessage('O apelido só pode conter: letras (incluindo acentuação), números, e caracteres . e _  ');
    } else {
      setErrorMessage('');
    }
  }

  console.log(editUserMutation.error);

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal container={document.getElementById('modal-wrapper')}>
        <DialogOverlay>
          <DialogContent>
            <DialogTitle>Criação de perfil</DialogTitle>
            <DialogDescription>
              Escolha como deseja ser chamado em comunidade.<br/>
              Defina o nick e a foto de perfil que deseja exibir em suas publicações em nosso fórum.<br/>
              Atenção: O nick não poderá ser alterado no futuro.
            </DialogDescription>

            <Form onSubmit={handleSubmit}>
              <label className="image-input">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleInputFileChange}
                />
                <div className="image-preview">
                  {preview ? (
                    <img alt="Foto de perfil" className="image-preview" src={preview} />
                  ) : (
                    <FiPlus size={40} stroke={white['40']} strokeWidth={1} />
                  )}
                </div>
                <span>  {preview ? "(Se mudar de ideia, clique na foto para alterá-la)" : "Escolha sua foto de perfil"}</span>
              </label>

              <input
                type="text"
                placeholder="Apelido"
                autoComplete="off"
                onChange={handleInputChange}
              />
              <span>{errorMessage}</span>

              <button type="submit">
                {editUserMutation.isLoading ? 'Carregando...' : 'Salvar'}
              </button>
            </Form>

            <Dialog.Close asChild>
              <button type="button" className="close-button">
                <FiX size={24} stroke={white['10']} />
              </button>
            </Dialog.Close>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

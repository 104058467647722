import { useMemo } from 'react';

type Props = {
  progression?: IAcivitiesProgression[];
};

export type IUseProgressByActivity = Map<string, IAcivitiesProgression>;

const compareProgressions = (
  progressionRegistered: IAcivitiesProgression | undefined,
  newProgression: IAcivitiesProgression,
): IAcivitiesProgression => {
  if (!progressionRegistered) return newProgression;

  if (
    progressionRegistered.state === 'COMPLETED' &&
    progressionRegistered.state !== newProgression.state
  )
    return progressionRegistered;

  if (newProgression.state === 'COMPLETED') return newProgression;

  return progressionRegistered.createdAt >= newProgression.createdAt
    ? progressionRegistered
    : newProgression;
};

export const useProgressByActivity = ({
  progression,
}: Props): IUseProgressByActivity | undefined =>
  useMemo(() => {
    return progression?.reduce((acc, currentProgression) => {
      const key = currentProgression?.class?.id;
      const validProgression = compareProgressions(
        acc.get(key),
        currentProgression,
      );

      acc.set(key, validProgression);
      return acc;
    }, new Map<string, IAcivitiesProgression>());
  }, [progression]);

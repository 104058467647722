import { useTheme } from 'styled-components';
import { BaseButtonProps } from '../base-button';

type ButtonProps = BaseButtonProps & {
  active: boolean;
  [key: string]: unknown;
};

export const Button = ({ active, ...props }: ButtonProps): JSX.Element => {
  const {
    newTheme: { black },
  } = useTheme();

  return (
    <button
      {...props}
      type="button"
      {...(active && { style: { backgroundColor: black['60'] } })}
    />
  );
};

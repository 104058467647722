const colors = {
  white: '#ffffff',
  darkWhite: '#F2EBFF',
  lightBlack: '#C5BED1',
  yellow: '#FFE47A',
  darkYellow: '#FFB905',
  green: '#48FFBD',
  blue: '#00DCEA',
  green2: '#48FFBD',
  darkGreen: '#02CB52',
  pastelGreen: '#88FFD4',
  orange: '#DF4909',
  reddish: '#FF7070',
  darkOrange: '#CB300E',
  pastelRed: '#FE6868',
  black: '#0a090b',
  black2: '#131316',
  darkGrey: '#110D1B',
  grey: '#191422',
  lightGrey: '#231F2C',
  red: '#FF7070',
  purple: '#EC7BFF',
};

const iconSolidColors = {
  red: '#FF7070',
  grey: '#9D9D9D',
  blue: '#00DCEA',
  black: '#0a090b',
  green: '#48FFBD',
  yellow: '#FFE47A',
  purple: '#EC7BFF',
  pastelGreen: '#88FFD4',
};

const newColors = {
  white: {
    '500': '#9D9D9D',
    '700': '#CECECE',
    '800': '#EAEAEA',
    '850': '#F5F5F5',
    '900': '#FFFFFF',
  },
  red: {
    '300': '#ff8f8f',
    '400': '#ff7070',
  },
  blue: {
    '100': '#94D0F0',
    '300': '#00DCEA',
    '400': '#62BFEF',
  },
  green: {
    '100': '#48FFBDB3',
    '300': '#99FFDA',
    '400': '#48FFBD',
  },
  black: {
    '400': '#26262C',
    '450': '#302F37',
    '500': '#1D1C21',
    '600': '#131316',
    '800': '#0a090b',
    '900': '#000000',
  },
  yellow: {
    '400': '#FFE47A',
    '900': '#FFB505',
  },
  purple: {
    '400': '#EC7BFF',
  },
};

const border = {
  '10': 'rgba(255, 255, 255, 0.1)',
  '20': 'rgba(255, 255, 255, 0.2)',
  '30': 'rgba(255, 255, 255, 0.3)',
  '60': 'rgba(255, 255, 255, 0.6)',
  '70': 'rgba(255, 255, 255, 0.7)',
};

const newTheme = {
  white: {
    '10': '#FFFFFF',
    '20': '#F5F5F5',
    '30': '#EAEAEA',
    '40': '#CECECE',
    '50': '#9D9D9D',
    '60': '#858585',
  },
  black: {
    '10': '#000000',
    '20': '#0A090B',
    '30': '#131316',
    '40': '#1D1C21',
    '50': '#26262C',
    '60': '#302F37',
    transparent: 'rgba(0, 0, 0, 0.8)',
    'transparent-50': 'rgba(0, 0, 0, 0.5)',
  },
  blue: {
    '10': '#2eb1f4',
    '20': '#62bfef',
    '30': '#94d0f0',
  },
  green: {
    '10': '#1fffae',
    '20': '#48ffbd',
    '30': '#99ffda',
    '70': 'rgba(72, 255, 189, 0.7)',
  },
  red: {
    '10': '#ff5e5e',
    '20': '#ff7070',
    '30': '#ff8f8f',
    '70': 'rgba(255, 112, 112, 0.7)',
  },
  yellow: {
    '10': '#ffdd55',
    '20': '#ffe47a',
    '30': '#ffeb99',
    '70': 'rgba(255, 228, 122, 0.7)',
  },
  border,
  gradient: {
    green:
      'linear-gradient(90deg, rgba(72, 255, 189, 0.16) 0%, rgba(72, 255, 189, 0) 100%), #000000',
    yellow:
      'linear-gradient(90deg, rgba(255, 228, 122, 0.16) 0%, rgba(255, 228, 122, 0) 100%), #000000',
    red: 'linear-gradient(90deg, rgba(255, 112, 112, 0.16) 0%, rgba(255, 112, 112, 0) 100%), #000000',
  },
  skeleton: '#0E0E11',
};

const shadows = {
  white: '#EAEAEA',
  green: '#00DF59',
  reddish: '#FA1202',
  yellow: '#FFB505',
  purple: '#EC7BFF',
  blue: '#00DCEA',
};

type IOpacifyColorProps = {
  opacity?: number;
  color: keyof typeof colors;
};
type IOpacifyShadowProps = {
  opacity?: number;
  color: keyof typeof shadows;
};

const theme = {
  colors,
  border,
  newTheme,
  newColors,
  iconSolidColors,
  buttonGradients: {
    green: 'linear-gradient(90deg, #A1FFBB 0%, #5EFFE2 100%)',
    yellow: 'linear-gradient(90deg, #FFE88E 0%, #FFA1A1 100%)',
    reddish: 'linear-gradient(90deg, #FF9D9D 0%, #FF509A 100%)',
    purple: 'linear-gradient(90deg, #EC7BFF 0%, #6E85FF 100%)',
    blue: 'linear-gradient(90deg, #00DCEA 0%, #80C2FF 100%)',
    black: 'linear-gradient(90deg, #00DCEA 0%, #80C2FF 100%)',
  },
  newGradients: {
    green: 'linear-gradient(90deg, #75FF9C 0%, #5EF5FF 100%)',
    reddish: 'linear-gradient(90deg, #FF9D9D 0%, #FF509A 100%)',
    yellow: 'linear-gradient(90deg, #FFE88E 0%, #FFA1A1 100%)',
    purple: 'linear-gradient(90deg, #EC7BFF 0%, #6E85FF 100%)',
    blue: 'linear-gradient(90deg, #00DCEA 0%, #80C2FF 100%)',
    pink: 'linear-gradient(90deg, #F59FF7 0%, #FF59BD 100%)',
  },
  iconColors: {
    white: {
      firstColor: '#CECECE',
      secondColor: '#CECECE',
      lighColor: '#CECECE',
      darkColor: '#CECECE',
    },
    iconGrey: {
      firstColor: '#000000',
      secondColor: '#000000',
      lighColor: '#000000',
      darkColor: '#000000',
    },
    black: {
      firstColor: '#000000',
      secondColor: '#000000',
      lighColor: '#000000',
      darkColor: '#000000',
    },
    yellow: {
      firstColor: '#FFCD05',
      secondColor: '#FF5F05',
      lighColor: '#FFCD05',
      darkColor: '#FF5F05',
    },
    error: {
      firstColor: '#ff6868',
      secondColor: '#ff6868',
      lighColor: '#ff6868',
      darkColor: '#ff6868',
    },
    red: {
      firstColor: '#DF7009',
      secondColor: '#FF0000',
      lighColor: '#DF7009',
      darkColor: '#FF0000',
    },
    green: {
      firstColor: '#00D72F',
      secondColor: '#00F69E',
      lighColor: '#00F69E',
      darkColor: '#00D72F',
    },
    pastelGreen: {
      firstColor: '#19FF59',
      secondColor: '#88FFD4',
      lighColor: '#88FFD4',
      darkColor: '#19FF59',
    },
    grey: {
      firstColor: 'rgba(255,255,255,0.6)',
      secondColor: 'rgba(255,255,255,0.6)',
      lighColor: 'rgba(255,255,255,0.6)',
      darkColor: 'rgba(255,255,255,0.6)',
    },
    purple: {
      firstColor: '#EC7BFF',
      secondColor: '#6E85FF',
      lighColor: '#EC7BFF',
      darkColor: '#6E85FF',
    },
    blue: {
      firstColor: '#00DCEA',
      secondColor: '#80C2FF',
      lighColor: '#00DCEA',
      darkColor: '#80C2FF',
    },
  },
  gradients: {
    yellow: ({
      invert = false,
      webkit = false,
      revert = false,
      firstColor = 10,
      secondColor = 100,
    }: {
      invert?: boolean;
      webkit?: boolean;
      revert?: boolean;
      firstColor?: number;
      secondColor?: number;
    }): string => {
      const degrees = invert ? -10 : 170;
      const gradientText = revert
        ? `(${degrees}deg, #FF5F05 ${firstColor}%, #FFCD05 ${secondColor}%)`
        : `(${degrees}deg, #ffcd05 ${firstColor}%, #ff5f05 ${secondColor}%)`;
      return webkit
        ? `-webkit-linear-gradient${gradientText}`
        : `linear-gradient${gradientText}`;
    },
    reddish: ({
      invert = false,
      webkit = false,
      revert = false,
      firstColor = 10,
      secondColor = 100,
    }: {
      invert?: boolean;
      webkit?: boolean;
      revert?: boolean;
      firstColor?: number;
      secondColor?: number;
    }): string => {
      const degrees = invert ? -10 : 170;
      const gradientText = revert
        ? `(${degrees}deg, #ff0000 ${firstColor}%, #DF7009 ${secondColor}%)`
        : `(${degrees}deg, #DF7009 ${firstColor}%, #ff0000 ${secondColor}%)`;
      return webkit
        ? `-webkit-linear-gradient${gradientText}`
        : `linear-gradient${gradientText}`;
    },
    pastelGreen: ({
      degrees,
      invert = false,
      webkit = false,
      revert = false,
      firstColor = 10,
      secondColor = 100,
    }: {
      degrees?: number;
      invert?: boolean;
      webkit?: boolean;
      revert?: boolean;
      firstColor?: number;
      secondColor?: number;
    }): string => {
      const defaultDegrees = invert ? -10 : 90;
      const degree = degrees || defaultDegrees;
      const gradientText = revert
        ? `(${degree}deg, #19FF59 ${firstColor}%, #88FFD4 ${secondColor}%)`
        : `(${degree}deg, #88FFD4 ${firstColor}%, #19FF59 ${secondColor}%)`;
      return webkit
        ? `-webkit-linear-gradient${gradientText}`
        : `linear-gradient${gradientText}`;
    },
    green: ({
      invert = false,
      webkit = false,
      revert = false,
      firstColor = 10,
      secondColor = 100,
    }: {
      invert?: boolean;
      webkit?: boolean;
      revert?: boolean;
      firstColor?: number;
      secondColor?: number;
    }): string => {
      const degrees = invert ? -10 : 170;
      const gradientText = revert
        ? `(${degrees}deg, #00D72F ${firstColor}%, #00F69E ${secondColor}%)`
        : `(${degrees}deg, #00F69E ${firstColor}%, #00D72F ${secondColor}%)`;
      return webkit
        ? `-webkit-linear-gradient${gradientText}`
        : `linear-gradient${gradientText}`;
    },
    purple: ({
      invert = false,
      webkit = false,
      revert = false,
      firstColor = 10,
      secondColor = 100,
    }: {
      invert?: boolean;
      webkit?: boolean;
      revert?: boolean;
      firstColor?: number;
      secondColor?: number;
    }): string => {
      const degrees = invert ? -10 : 170;
      const gradientText = revert
        ? `(${degrees}deg, #4F00F7 ${firstColor}%, #B900D7 ${secondColor}%)`
        : `(${degrees}deg, #B900D7 ${firstColor}%, #4F00F7 ${secondColor}%)`;
      return webkit
        ? `-webkit-linear-gradient${gradientText}`
        : `linear-gradient${gradientText}`;
    },
  },
  opacifyColor: ({ color, opacity = 0.8 }: IOpacifyColorProps): string => {
    const realOpacity = opacity > 1 ? 1 : opacity;
    return `${colors[color]}${(realOpacity * 256)
      .toString(16)
      .replace(/\.\w+/, '')
      .replace('100', 'ff')
      .padStart(2, '0')}`;
  },
  opacifyShadow: ({ color, opacity = 0.8 }: IOpacifyShadowProps): string => {
    const realOpacity = opacity > 1 ? 1 : opacity;
    return `${shadows[color]}${(realOpacity * 256)
      .toString(16)
      .replace(/\.\w+/, '')
      .replace('100', 'ff')
      .padStart(2, '0')}`;
  },
  tagBackground: {
    green: '#042525',
    yellow: '#2A1F0E',
    red: '#2A000D',
  },
};

export default theme;

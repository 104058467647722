import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { P } from '../../../Atoms/Texts';
import Icon from '../../../Icon';

import { Container } from './styles';

type IPracticeProps = {
  practice: IPractice;
};

const PracticeLink: React.FC<IPracticeProps> = ({ practice }) => {
  const onClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    localStorage.setItem('last-practice', practice.url);
    window.open(practice.url, '_blank');
  };

  return (
    <Container
      onClick={onClick}
      href={practice.url}
      className="blackGlass backgroundBlur"
    >
      <Icon IconComponent={FiExternalLink} color="red" isntGradient size={24} />
      <P>Acesse o ambiente sandbox</P>
    </Container>
  );
};

export default PracticeLink;

import { ILessonExtra } from '@/@types/card';
import CardMaterial from '@/components/CardMaterial';
import ButtonBackHeader from '@/components/Dashboard/ButtonBackHeader';
import FilterForm from '@/components/Dashboard/FilterForm';
import HeaderInfo from '@/components/Dashboard/HeaderInfo';
import { IFilter } from '@/components/DropdownWithCheckbox';
import { useAuth } from '@/hooks/Auth';
import { getClassesFromStudentCourse } from '@/services/api';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Container, ContainerResult, GroupCards } from './styles';

type IFilterSearch = {
  search: string;
  modules: IFilter[];
  types: IFilter[];
};

const DashboardLibary: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const { user } = useAuth();
  const [extras, setExtras] = useState<ILessonExtra[]>([]);
  const [filterResults, setFilterResults] = useState<ILessonExtra[]>([]);
  const [modules, setModules] = useState<(IModuleBase | undefined)[]>([]);
  const [filters, setFilters] = useState<IFilterSearch>({
    modules: [],
    search: ' ',
    types: [],
  });

  const { data: classes = [] } = useQuery({
    queryKey: ['student-course-classes', courseId],
    queryFn: () => getClassesFromStudentCourse(courseId),
    staleTime: Infinity,
    refetchOnMount: 'always',
  });

  useEffect(() => {
    const filterExtras = classes
      .flatMap(classe => ({
        extra: classe.extra,
        module: classe.module,
        lesson: classe.title,
      }))
      .filter(lesson => lesson.extra.length > 0);

    const extrasMapper: ILessonExtra[] = [];

    filterExtras.forEach(itemExtra => {
      if (itemExtra.extra.length > 0) {
        itemExtra.extra.forEach(value => {
          extrasMapper.push({
            lesson: itemExtra.lesson,
            module: itemExtra.module,
            extra: value,
          });
        });
      }
    });

    const filterModules = classes.map(classe => classe.module);

    const uniqueModules = Array.from(new Set(filterModules.map(a => a.id))).map(
      id => {
        return filterModules.find(a => a.id === id);
      },
    );

    setModules(uniqueModules);
    setExtras(extrasMapper);
    setFilterResults(extrasMapper);
  }, [classes]);

  const getFilterSearch = (search: string) => {
    setFilters({
      ...filters,
      search,
    });
  };

  const getFilterOfMaterials = (data: IFilter[]) => {
    setFilters({
      ...filters,
      types: data,
    });
  };

  const getFilterOfModules = (data: IFilter[]) => {
    setFilters({
      ...filters,
      modules: data,
    });
  };

  useEffect(() => {
    const handleFiltersSearch = (data: IFilterSearch) => {
      let contentFiltered = extras.filter(
        extra =>
          extra.lesson.includes(data?.search ?? '') ||
          extra.extra.description.includes(data?.search ?? ''),
      );

      if (data.modules.length > 0) {
        contentFiltered = contentFiltered.filter(value =>
          data?.modules.some(filter =>
            value.module.id.includes(filter.value ?? ''),
          ),
        );
      }

      if (data.types.length > 0) {
        contentFiltered = contentFiltered.filter(value =>
          data?.types.some(filter =>
            value.extra.type.includes(filter.value ?? ''),
          ),
        );
      }

      setFilterResults(contentFiltered);
    };
    handleFiltersSearch(filters);
  }, [extras, filters]);

  return (
    <Container>
      <ButtonBackHeader />
      <HeaderInfo
        namePage="Materiais"
        student={user?.name ?? ''}
        message="Bem-vindo(a) ao seu Espaço Coleção! <br /> Aqui você encontra os materiais extras de todas as aulas, organizadas por módulos."
      />
      <FilterForm
        sendEvent={getFilterSearch}
        modules={modules}
        sendMaterialsSelected={getFilterOfMaterials}
        sendModulesSelected={getFilterOfModules}
      />
      <ContainerResult>
        {filterResults.length > 0 ? (
          <h2>Resultados</h2>
        ) : (
          <h2> Não há resultados </h2>
        )}
        <GroupCards>
          {filterResults.map((item, index) => (
            <CardMaterial material={item} key={index.toString()} />
          ))}
        </GroupCards>
      </ContainerResult>
    </Container>
  );
};

export default DashboardLibary;

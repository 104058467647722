import { FiChevronLeft } from 'react-icons/fi';
import Button from '../../../Button';
import { MobileNavigationButtons } from '../../ClassPageComponents/MobileNavigationButtons';
import { usePaginated } from '../../hooks/Paginated';

import { PracticeButtonsContainer, NavigationButton } from './styles';

type IPracticeButtonsProps = {
  isCompleted: boolean;
  handleNextClass: () => void;
};

export const PracticeButtons: React.FC<IPracticeButtonsProps> = ({
  isCompleted,
  handleNextClass,
}) => {
  const { page, pageIdList, prevPage, nextPage } = usePaginated();

  const isVideoPage = page === 0 && pageIdList.length > 1;
  const hasVideoPage = pageIdList.length > 1;

  return (
    <MobileNavigationButtons>
      <PracticeButtonsContainer>
        {!isVideoPage ? (
          <>
            {hasVideoPage && (
              <NavigationButton type="button" onClick={prevPage}>
                <FiChevronLeft size={20} />
              </NavigationButton>
            )}
            <Button
              isGradient
              size="large"
              hasSmallText
              type="button"
              colorType="blue"
              onClick={isCompleted ? handleNextClass : undefined}
            >
              {isCompleted ? 'Próxima atividade' : 'Concluir'}
            </Button>
          </>
        ) : (
          <Button colorType="blue" type="button" onClick={nextPage}>
            Acesso ao exercício
          </Button>
        )}
      </PracticeButtonsContainer>
    </MobileNavigationButtons>
  );
};

import api from './main.api';

type IStartGetLicenseRequestParams = {
  courseId: string;
  classId: string;
};

export const startGetLicense = async ({
  classId,
  courseId,
}: IStartGetLicenseRequestParams): Promise<void> => {
  const authorization = localStorage.getItem('patoToken');

  await api.get(`/student/courses/${courseId}/classes/${classId}/licence`, {
    headers: { authorization },
  });
};

import { usePaginated } from '..';
import {
  PaginatedContaierStyled,
  PaginatedContent,
  PageSliderContainer,
} from './styles';

export const PaginatedContainer: React.FC = ({ children }) => {
  const { page } = usePaginated();
  return (
    <PaginatedContaierStyled>
      <PaginatedContent>
        <PageSliderContainer page={page}>{children}</PageSliderContainer>
      </PaginatedContent>
    </PaginatedContaierStyled>
  );
};

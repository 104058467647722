import React, { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import ActiveConnection from '../../components/ActiveConnection';
import { H2 } from '../../components/Atoms/Texts';
import Button from '../../components/Button';
import Input from '../../components/RocketInput';
import SettingsSidebar from '../../components/SettingsSidebar';
import { useAuth } from '../../hooks/Auth';

import { Container, Connections } from './styles';
import { getConnections } from '../../services/api';
import { useAlert } from '../../hooks/Alert';
import { validateUpdatePassword } from '../../util/Yup/updatePassword';
import { getValidationErrors } from '../../util/getValidationErrors';

type IChangePassword = {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
};

const Security: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [list, setList] = useState<IAuthEvents>([]);
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { showMessage } = useAlert();

  useEffect(() => {
    getConnections().then(setList);
  }, []);

  const handleSubmit = async (data: IChangePassword) => {
    setIsLoading(true);
    try {
      await validateUpdatePassword(data);
      showMessage({
        message: 'Alteração realizada com sucess',
        type: 'SUCCESS',
      });
      formRef.current?.reset();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        return;
      }
      showMessage({
        message: 'Ocorreu um Erro, tente novamente',
        type: 'ERROR',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <SettingsSidebar />
      <div>
        <section>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <H2 size={20} weight="600">
              Redefinir senha
            </H2>
            <Input
              name="oldPassword"
              type="password"
              width="100%"
              title="Antiga senha"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
            />
            <Input
              name="password"
              type="password"
              width="100%"
              title="Nova senha"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
            />
            <Input
              name="passwordConfirm"
              type="password"
              width="100%"
              title="Confirmar nova senha"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
            />
            <div className="buttonContainer">
              <Button
                isGradient
                size="medium"
                type="submit"
                colorType="blue"
                isLoading={isLoading}
              >
                Redefinir
              </Button>
            </div>
          </Form>
        </section>
      </div>
    </Container>
  );
};

export default Security;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: stretch;

  > div {
    width: 100%;
    margin: 80px 0;
  }
  h2 {
    margin-bottom: 5px;

    line-height: 130%;

    letter-spacing: 0.01em;

    color: #F2EBFF;
  }

  form {
    width: 100%;
    max-width: 720px;
    margin: 30px auto;
    display: flex;
    justify-content: center;

    > div {
      width: 100%;
      display: flex;
      flex-direction: row;

      > section {
        margin-top: calc(39px + 2rem);
      }

      > * {
        width: 100%;
      }
    }
  }

  .buttonContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
  }

  .buttonContainer button {
    align-self: flex-end;
    font-size: 1rem;
  }

  @media screen and (max-width: 750px){
    form {
      padding: 0 2rem;
    }
  }
`;

export const InputSection = styled.section`
  margin-top: 45px;

  > section {
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  section > fieldset {
    margin-top: 25px;
  }

  > fieldset {
    max-width: none !important;
  }
`;

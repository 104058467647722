import React from 'react';
import { FiX } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { H3, P } from '../Atoms/Texts';
import Button from '../Button';
import { pufiryHtmlText } from '../../util/purifyHtmlText';
import { Container, Content, CloseButton, ButtonsContainer } from './styles';
import { OptionalPortal } from '../OptionalPortal';

type IDialogProps = {
  dialogConfig: IDialogConfig;
  onCancel: () => void;
  onConfirm: () => void;
};

type IClickHandler = React.MouseEventHandler<HTMLDivElement>;

export const Dialog: React.FC<IDialogProps> = ({
  dialogConfig: {
    title,
    message,
    cancelButton,
    portalElement,
    hasBackgroundBlur,
    disableCloseButton,
    disableConfirmButton = false,
  },
  onConfirm,
  onCancel,
}) => {
  const {
    newColors: { white },
  } = useTheme();
  const disablePropagation: IClickHandler = event => event.stopPropagation();

  const handleCancelClick = () => onCancel();

  const hasPortal = !!portalElement;

  return (
    <OptionalPortal id="root">
      <Container onClick={handleCancelClick} data-has-blur={hasBackgroundBlur}>
        <OptionalPortal id={portalElement} hasPortal={hasPortal}>
          <Content onClick={disablePropagation}>
            {!disableCloseButton && (
              <CloseButton onClick={onConfirm}>
                <FiX size={25} color={white['900']} strokeWidth={1} />
              </CloseButton>
            )}
            <H3 size={18}>{title}</H3>
            <P dangerouslySetInnerHTML={{ __html: pufiryHtmlText(message) }} />
            <ButtonsContainer>
              {cancelButton && (
                <Button
                  isntFilled
                  size="medium"
                  colorType="reddish"
                  onClick={handleCancelClick}
                >
                  Cancelar
                </Button>
              )}
              {!disableConfirmButton && (
                <Button size="medium" onClick={onConfirm}>
                  {cancelButton ? 'Confirma' : 'OK'}
                </Button>
              )}
            </ButtonsContainer>
          </Content>
        </OptionalPortal>
      </Container>
    </OptionalPortal>
  );
};

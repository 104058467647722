import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { SSOPage } from '@/pages/SSO';
import Route from './Route';
import SignIn from '../AuthPages/SignIn';


import CoursePage from '../pages/Course';
import ClassPage from '../pages/ClassPage';
import Account from '../pages/Account';
import Security from '../pages/Security';

import Lab from '../pages/Lab';
import { Discord } from '../pages/Discord';
import { LabRedirect } from '../pages/LabRedirect';
import DashboardLibary from '../pages/Dashboard/Library';
import DashboardFavorites from '../pages/Dashboard/Favorites';

const Routes: React.FC = () => (
  <Switch>
    <Route
      hasHeader
      isPrivate
      path="/lab/redirect"
      hasFooter
      component={LabRedirect}
    />
    <Route isPrivate path="/sso" component={SSOPage} />
    <Route hasHeader isPrivate path="/lab" exact component={Lab} hasFooter />
    <Route isPrivate hasHeader hasFooter path="/account" component={Account} />
    <Route
      isPrivate
      hasHeader
      hasFooter
      path="/security"
      component={Security}
    />
    
    <Route isPrivate hasHeader hasFooter path="/discord" component={Discord} />
    <Route isAuth path="/signin" component={SignIn} />

   
    <Route isPrivate path="/course/:courseId/class/:id" component={ClassPage} />
    <Route
      isPrivate
      hasHeader
      hasFooter
      path="/course/:courseId/library"
      component={DashboardLibary}
    />
    <Route
      isPrivate
      hasHeader
      hasFooter
      path="/course/:courseId/favorites"
      component={DashboardFavorites}
    />
    <Route
      isPrivate
      hasHeader
      hasFooter
      path="/course/:courseId"
      component={CoursePage}
    />
    <Redirect path="*" to="/signin" />
  </Switch>
);

export default Routes;
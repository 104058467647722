import convertTimeToMinutes from '@/util/convertTimeToMinutes';
import { useState } from 'react';
import { H6 } from '../Atoms/Texts';
import {
  Card,
  CardGroup,
  StyledFiClock,
  // StyledFiLink2,
  InfoCard,
  TypeClass,
  StyledCheckbox,
  Head,
  StyledIndicator,
} from './styles';

type IClassItem = {
  classItem: IFinalClass;
  isntFinished: boolean;
  handleClick: (classItem: IFinalClass) => void;
  handleChecked: () => void;
  variant?: boolean;
  isActual?: boolean;
};

type IClassTypes = {
  [key in string]: {
    type: string;
    color: 'green' | 'yellow' | 'red';
  };
};

const CardClass: React.FC<IClassItem> = ({
  classItem,
  isntFinished,
  handleClick,
  handleChecked,
  variant = false,
  isActual = false,
}) => {
  const [checked, setChecked] = useState(false);

  const classTypes: IClassTypes = {
    FLAG: {
      type: 'Capture the flag',
      color: 'red',
    },
    PRACTICE: {
      type: 'Practical Lab',
      color: 'red',
    },
    QUIZ: {
      type: 'Quiz',
      color: 'yellow',
    },
    CLASS: {
      type: 'videoaula',
      color: 'green',
    },
  };

  const getClassType = classTypes[classItem.type];

  const TimerClass = () => {
    if (classItem.type === 'CLASS') {
      return (
        <InfoCard>
          <StyledFiClock size={10} />
          {convertTimeToMinutes(classItem.class.duration, true)}
        </InfoCard>
      );
    }

    if (classItem.estimatedDuration) {
      return (
        <InfoCard>
          <StyledFiClock size={10} />
          {classItem.estimatedDuration}
        </InfoCard>
      );
    }

    return null;
  };

  const Info = () => {
    return (
      <InfoCard>
        {/* <StyledFiLink2 size={14} /> */}
        {/* Conceitos básicos */}
      </InfoCard>
    );
  };

  const Checkbox = StyledCheckbox;
  const CheckboxIndicator = StyledIndicator;

  return (
    <CardGroup variant={variant} isActual={isActual} color={getClassType.color}>
      <Checkbox
        id={classItem.id}
        onCheckedChange={() => {
          handleChecked();
          setChecked(true);
        }}
        {...((isntFinished || checked) && { checked: isntFinished || checked })}
      >
        <CheckboxIndicator />
      </Checkbox>
      <Card onClick={() => handleClick(classItem)}>
        <Head>
          <TypeClass color={getClassType.color}>{getClassType.type}</TypeClass>
          <H6 size={14}>{classItem.title}</H6>
        </Head>
        <TimerClass />
        <Info />
      </Card>
    </CardGroup>
  );
};

export default CardClass;

import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top:0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  img {
    overflow: hidden;
    min-width: 1280px;
    width: 100%;
    object-fit: cover;
    height: 100%;
  }

  @media (max-width: 768px){
    img {
      min-height: 100vh;
      min-width: 100vw;
    }
  }

  @media (max-width: 425px){
    img {
      transform: rotate(-25deg) translateX(-30%) translateY(-10%);
      min-width: 100vh;
      height: 100vh;
      object-fit: fill;
    }
  }
`;

import { CourseProgressDisclaimer } from './CourseProgressDisclaimer';
import {
  ProgressBar,
  ProgressText,
  ProgressHeader,
  ProgressStarIcon,
  ProgressFooterText,
  ProgressCardContainer,
} from './styles';

type ICourseProgressCardProps = {
  /**
   * Progresso do curso de 0 à 100
   */
  rate?: number;
  /**
   * Se o progresso está carregando ainda
   */
  isLoading?: boolean;
};

const getPercentMessage = (rate: number) => {
  if (rate >= 100) return 'Parabéns por chegar até aqui! Você fez por merecer!';
  if (rate >= 95) return 'Agora é pra fechar com chave de ouro!';
  if (rate >= 75) return 'Falta pouco, você consegue!';
  if (rate >= 50) return 'Você está quase lá!';
  if (rate >= 20) return 'Consistência leva a perfeição!';
  return 'Keep hacking!';
};

export const CourseProgressCard: React.FC<ICourseProgressCardProps> = ({
  rate = 0,
  isLoading,
}) => {
  const isLoadingRate = isLoading;
  const percentMessage =
    (!isLoadingRate && getPercentMessage(Math.floor(rate))) || '';

  return (
    <ProgressCardContainer
      data-loading={isLoadingRate}
      title={`Curso ${Math.floor(rate)}% concluído`}
    >
      <ProgressHeader data-loading={isLoadingRate}>
        <ProgressStarIcon size={20} />
        <ProgressText>
          Curso <b>{Math.floor(rate)}%</b> concluído
        </ProgressText>
      </ProgressHeader>
      <ProgressBar max={100} data-loading={isLoadingRate} value={rate} />
      <footer>
        <ProgressFooterText data-loading={isLoadingRate} title={percentMessage}>
          {percentMessage}
        </ProgressFooterText>
        <CourseProgressDisclaimer />
      </footer>
    </ProgressCardContainer>
  );
};

import styled from 'styled-components';

interface IInputContainer {
  required?: boolean;
  width?: string;
  isFocused: boolean;
  hasError?: boolean;
  disabled?: boolean;
}

export const Container = styled.fieldset<IInputContainer>`
  position: relative;
  width: ${props => (props.width ? props.width : "330px")};
  &[data-mask="cep"],
  &[data-name="cpf"],
  &[data-type="date"],
  &[data-name="postalCode"] {
    font-size: 1rem;
    max-width: 17.6em;
  }

  &[data-mask="credit"] {
    min-width: 32.8rem;
  }

  &[data-name="cvv"],
  &[data-name="state"],
  &[data-mask="validity"] {
    width: 15.5rem;
  }

  text-align: start;

  p {
    line-height: 150%;
    ::after {
      ${props => (props.required ? 'content: " *"' : 'content: ""')};
    }
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    width: 100%;
    height: 47px;
    margin: 8px 0;
    font-size: 18px;
    background-color: none;

    .icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
  input {
    border-radius: 4px;
    cursor: ${props => props.disabled ? 'not-allowed' : 'initial'};
    border: 1px ${props => props?.hasError
    ? props.theme.colors.pastelRed
    : props.theme.opacifyColor({ color: 'white', opacity: 0.2 })};
    background-color: ${props => props.theme.newColors.black['900']};
    border-style: ${props => props.disabled ? 'dashed' : 'solid'};
    width: 100%;
    height: 100%;
    padding: 0 16px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:hover,
  input:focus,
  input:active
  {
    border-color: ${({ theme, disabled }) => !disabled && theme.opacifyColor({ color: 'white', opacity: 0.5 })};
    box-shadow: 0 0 0 30px ${props => props.theme.colors.black} inset !important;
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.colors.black} inset !important;
  }
  input:-webkit-autofill
  {
    -webkit-text-fill-color: ${props => props.theme.colors.white} !important;
  }

  input::placeholder {
    color: ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.6 })};
  }

  input[type="password"]::placeholder {
    padding-top: 15px;
  }

  > small{
    position: absolute;
    letter-spacing: 0.01em;
    line-height: 1.3em;
    color: rgba(255,255,255,0.6);
    margin-left: 16px;
  }

  .error {
    color: ${props => props.theme.newColors.red['400']};
  }

  .search {
    margin: 0;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    display: grid;
    place-items: center;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 0 4px 4px 0;
    border: 1px solid ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.2 })};
    background: ${props => props.theme.colors.black};
  }
`;

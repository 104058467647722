import ButtonBackHeader from '@/components/Dashboard/ButtonBackHeader';
import HeaderInfo from '@/components/Dashboard/HeaderInfo';
import { useAuth } from '../../../hooks/Auth';
import { useLastActivities } from './hooks/useLastActivities';
import { useProgressByActivity } from './hooks/useProgressByActivity';
import { LastActivities } from './LastActivities';
import ModulesContainer from './ModulesContainer';
import { Container } from './styles';

type ICourseData = {
  courseResume?: IMyCourseResume;
  modulesMap?: Map<string, IModule>;
  groupedClasses?: IGroupedClasses[];
  progression?: IAcivitiesProgression[];
};

export const CourseGrade: React.FC<ICourseData> = ({
  courseResume,
  groupedClasses,
  modulesMap,
  progression,
}) => {
  const { user } = useAuth();
  const progressionMap = useProgressByActivity({ progression });
  const { isLoading, activities } = useLastActivities({
    modules: groupedClasses,
  });

  return (
    <Container>
      <ButtonBackHeader />
      <HeaderInfo
        namePage="conteúdo do curso"
        student={user?.name ?? ''}
        message="Bem-vindo(a)! <br /> Aqui estão listados todos os módulos e aulas do curso para a sua consulta"
      />
      <LastActivities isLoading={isLoading} lastActivities={activities} />
      <ModulesContainer
        modulesMap={modulesMap}
        courseResume={courseResume}
        progression={progressionMap}
        groupedClasses={groupedClasses}
      />
    </Container>
  );
};

import { CardContainer } from '@/components/CardContainer/styles';
import DropdownWithCheckbox, {
  IFilter,
} from '@/components/DropdownWithCheckbox';
import Input from '@/components/RocketInput';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { Container, ContainerInputForm, StyledFiSearch } from './styles';

type IFilterForm = {
  sendEvent: (search: string) => void;
  sendModulesSelected: (modules: IFilter[]) => void;
  sendMaterialsSelected: (materials: IFilter[]) => void;
  modules: (IModuleBase | undefined)[];
};

export const FilterForm: React.FC<IFilterForm> = ({
  sendEvent,
  sendModulesSelected,
  sendMaterialsSelected,
  modules,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [mapperModules, setMapperModules] = useState<IFilter[]>([]);
  const listOfMaterials = [
    {
      name: 'Pdf',
      value: 'PDF',
      checked: false,
    },
    {
      name: 'Códigos',
      value: 'CODE',
      checked: false,
    },
    {
      name: 'Artigos',
      value: 'ARTICLE',
      checked: false,
    },
    {
      name: 'Links',
      value: 'URL',
      checked: false,
    },
    {
      name: 'Aulas',
      value: 'CLASS',
      checked: false,
    },
  ];

  const handleSubmit = ({ search }: string) => {
    if (typeof search === 'string') {
      sendEvent(search);
    }
  };

  useEffect(() => {
    setMapperModules(
      modules.map(module => {
        return {
          name: module?.name,
          value: module?.id,
          checked: false,
        };
      }),
    );
  }, [modules]);

  const onChange = () => {
    sendEvent(formRef.current?.getData()?.search);
  };

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} className="form">
        <ContainerInputForm className="input-search">
          <button type="submit">
            <StyledFiSearch size={24} />
          </button>
          <Input
            width="100%"
            type="text"
            name="search"
            placeholder="Nome do material ou da aula"
            onChange={onChange}
          />
        </ContainerInputForm>
        <DropdownWithCheckbox
          title="Módulos"
          className="button-module"
          data={mapperModules}
          sendFiltersSelected={sendModulesSelected}
        />
        <DropdownWithCheckbox
          title="Tipos de material"
          className="button-material"
          data={listOfMaterials}
          sendFiltersSelected={sendMaterialsSelected}
        />
      </Form>
      <CardContainer />
    </Container>
  );
};

export default FilterForm;

import styled, { keyframes } from 'styled-components';

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);

  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  > div {
    width: calc(100% - 360px);
    margin: 80px 0;
  }
  h2 {
    margin: 66px 0 5px;

    line-height: 130%;
    /* identical to box height, or 26px */

    letter-spacing: 0.01em;

    color: #F2EBFF;
  }

  section{
    margin: 0 auto;
    max-width: 720px;
    width: 100%;
  }

  form {
    width: 100%;
  }

  form > fieldset {
    margin-top: 25px;
  }

  .buttonContainer{
    display: flex;
    justify-content: flex-end;
    margin: 0;
    svg {
      height: 100%;
      circle {
        transform-origin: center;
        animation: 1s ${rotating} linear infinite;
      }
    }
  }

  button {
    margin-top: 25px;
    align-self: flex-end;
    font-size: 1rem;
  }

  @media screen and (max-width: 1024px){
    > div {
      width: 100%;
      padding: 0 2rem;
    }

  }
`;

export const Connections = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    /* H5 20 Semibold */
    font-family: Poppins;
    font-style: normal;
    line-height: 130%;
    /* identical to box height, or 26px */

    letter-spacing: 0.01em;

    color: ${props => props.theme.colors.darkWhite}
  }

  ul {
    margin-top: 25px;
    width: 100%;
    max-height: 180px;
    overflow: auto;
    &::-webkit-scrollbar{
      width: 20px;
    }

    &::-webkit-scrollbar-track{
      background-color: none;
    }

    &::-webkit-scrollbar-thumb{
      background-color: ${props => props.theme.colors.lightGrey};
      border-radius: 20px;
      height: 60px;
      background-clip: content-box;
      border: 8px solid transparent;
    }

    &::-webkit-scrollbar-button{
      background: none;
      height: 5px;
    }

    &::-webkit-scrollbar-corner{
      background: none;
    }
  }
`;

import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Routes from '../Routes';
import GlobalStyle from './globalStyle';
import theme from './theme';
import AuthComponent from '../hooks/Auth';
import Alert from '../hooks/Alert';
import CoursesContext from '../hooks/Courses';
import SuportFloatingButton from '../components/SuportFloatingButton';
import { DialogProvider } from '../hooks/Dialog';
import { CustomIntercomProvider } from '../hooks/intercom';
import { CookiesAlert } from '../components/CookiesAlert';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://e30741b4f29d42e1af78d670d3d9d388@o4504947326189568.ingest.sentry.io/4504968725004288',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
export const queryClient = new QueryClient();

const App = (): JSX.Element => (
  <QueryClientProvider client={queryClient}>
    <IntercomProvider appId={`${process.env.REACT_APP_INTERCOM_APP_ID}`}>
      <ThemeProvider theme={theme}>
        <DialogProvider>
          <Alert>
            <Router>
              <AuthComponent>
                <CustomIntercomProvider>
                  <CoursesContext>
                          <Routes />
                          <GlobalStyle />
                  </CoursesContext>
                  <CookiesAlert />
                </CustomIntercomProvider>
              </AuthComponent>
            </Router>
          </Alert>
        </DialogProvider>
      </ThemeProvider>
    </IntercomProvider>
    {process.env.NODE_ENV === 'development' && (
      <ReactQueryDevtools initialIsOpen={false} />
    )}
  </QueryClientProvider>
);
export default App;

import styled from "styled-components";

export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: center;


  @media screen and (max-width: 732px){
    margin-top: 3.2rem;
  }
`;

export const IconsLine = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > a + a {
    margin-left: 2.7rem;
  }
`;

export const SocialHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h3 {
    line-height: 1.4em;
    color: ${({ theme }) => theme.newColors.white['900']}
  }

  p {
    margin: 3.2rem 0 2.7rem;
    width: 25.3rem;
    line-height: 1.4em;
    color: ${({ theme }) => theme.newColors.white['500']}
  }
`;

import { forumApi } from '@/services/api/forum.api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

type IUserForumProfile = {
  avatarUrl: string;
  nickname: string;
};

export async function getUserForumProfile(): Promise<IUserForumProfile> {
  const api = await forumApi();
  const response = await api.get<IUserForumProfile>('/user');

  return response.status == 200 ? response.data : { avatarUrl: '', nickname: '' }
};


export function useForumProfile(): UseQueryResult<IUserForumProfile> {
  return useQuery(['forum-profile'], getUserForumProfile);
}

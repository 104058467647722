import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 9rem;
`;

export const SliderContainer = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

type Prop = {
  page: number;
}

export const SlideList = styled.ul<Prop>`
  display: flex;
  align-items: flex-start !important;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap !important;
  transition: all .8s;
  transform: translateX(calc(-${props => props.page} * 100%));
`;

export const Slide = styled.section`
  flex: none;
  transition: all 1s;
  width: 100%;
  padding: 0;

  &[data-visible=false]{
    height: 0;
  }
`;

export const PaginatedButtons = styled.button`
  width: 4.8rem;
  height: 4.8rem;

  display: grid;
  place-items: center;
  border-radius: 50%;

  background: ${({ theme: { newColors } }) => newColors.black['500']};
  border: 1px solid ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.1 })};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25), inset 0px 5px 5px -20px rgba(255, 255, 255, 0.02);
  color: ${({ theme: { newColors } }) => newColors.white['900']};
  &:disabled {
    display: none;
  }
  & + button {
    margin-left: 2.4rem;
  }
`;

export const NavigationButtons = styled.ul`
  position: absolute;
  display: flex;
  flex-direction: row;
  min-width: 12rem;
  right: 0;
  bottom: 0;
  z-index: 1;
  transform: translateY(calc(100% + 2rem));
`;

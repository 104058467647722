import { useAlert } from '@/hooks/Alert';
import { useMutation } from '@tanstack/react-query';
import { FormHandles } from '@unform/core';
import { useRef } from 'react';
import { rateClass } from '../../../../../services/api';
import Button from '../../../../Button';
import Input from '../../../../RocketInput';
// import { FormStars } from './FormStars';
import { FeedbackContainer } from './styles';

type IFeedbackData = {
  stars?: number;
  feedback: string;
};

type IFeedbackProps = {
  classId: string;
};

export const Feedback: React.FC<IFeedbackProps> = ({ classId }) => {
  const { showMessage } = useAlert();
  const formRef = useRef<FormHandles>(null);

  const { isLoading, mutateAsync } = useMutation<void, void, IFeedbackData>(
    data => rateClass(classId, data),
    {
      onError: () => {
        showMessage({
          type: 'ERROR',
          message: 'Ocorreu um erro! Tente novamente',
        });
      },
      onSuccess: () => {
        formRef.current?.clearField('feedback');
        showMessage({ message: 'Obrigado pelo feedback!', type: 'SUCCESS' });
      },
    },
  );
  const handleSubmit = async (data: IFeedbackData) => {
    await mutateAsync(data);
  };

  return (
    <FeedbackContainer ref={formRef} onSubmit={handleSubmit}>
      {/* <FormStars /> */}
      <Input
        name="feedback"
        disabled={isLoading}
        data-cy="activty.footer.feedback.input"
        title="Deixe sua opinião sobre essa aula"
        placeholder="Se quiser escreva sua opinião com mais detalhes"
      />
      <Button
        size="small"
        type="submit"
        colorType="blue"
        isLoading={isLoading}
        data-cy="activty.footer.feedback.submit"
      >
        Enviar
      </Button>
    </FeedbackContainer>
  );
};

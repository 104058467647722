import api from './main.api';

type IRequestData = {
  userName: string;
};

export const sendDiscordUserName = async (
  userData: IRequestData,
): Promise<unknown> => {
  const authorization = localStorage.getItem('patoToken');

  const { data } = await api.post('/service/discord', userData, {
    headers: { authorization },
  });
  return data;
};

export const getDiscordUserName = async (): Promise<IRequestData> => {
  const authorization = localStorage.getItem('patoToken');

  const { data } = await api.get<IRequestData>('/service/discord', {
    headers: { authorization },
  });

  return data;
};

import React, { useState } from 'react';
import { FiBell } from 'react-icons/fi';
import { BeamerHelmet } from 'react-beamer';
import Icon from '../../../Icon';

import { Container } from './styles';
import { useAuth } from '../../../../hooks/Auth';
// import { Small } from '../../../Atoms/Texts';

interface INotificationMenuProps {
  hasMouseLeave?: true;
}

const AlertMenu: React.FC<INotificationMenuProps> = () => {
  const [, callback] = useState<number>(0);
  const {
    student: { email, name },
  } = useAuth();

  return (
    <Container>
      <button type="button" className="bell beamerTrigger">
        <Icon IconComponent={FiBell} size={27} />

        <BeamerHelmet
          id="nzbhqXTC45292"
          args={{
            selector: '.beamerTrigger',
            email,
            user_firstname: name,
            callback,
          }}
        />
      </button>
      {/* {postCount > 0 && (
        <div className="circle">
          <Small size={10} weight="600">
            {postCount}
          </Small>
        </div>
      )} */}
    </Container>
  );
};

export default AlertMenu;

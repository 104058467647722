import { Form } from "@unform/web";
import styled from "styled-components";

export const FeedbackContainer = styled(Form)`
  gap: 1.6rem;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  fieldset {
    width: 100%;
  }

  button {

  }
`;

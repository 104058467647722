import HeaderInfo from '@/components/Dashboard/HeaderInfo';
import { useCourse } from '@/hooks/Courses';
import { useMemo } from 'react';
import { useAuth } from '../../../hooks/Auth';
import { BreakScope } from '../BreakScope';
import { CourseProgressCard } from './CourseProgressCard';
import { useCourseLastActivity } from './hooks/useCourseLastActivity';
import { Container, Row, RowItem } from './styles';
import { Spaces } from './Spaces';
import { NextActivityCard } from './NextActivityCard';
import { LastClassTopics } from './LastClassTopics';

type ICourseData = {
  modulesMap?: Map<string, IModule>;
  course?: IDetailedCourse;
  groupedClasses?: IGroupedClasses[];
  progression?: IAcivitiesProgression[];
  courseResume?: IMyCourseResume;
};

export const CoursePageV2: React.FC<ICourseData> = ({
  groupedClasses,
  progression,
  modulesMap,
  course,
}) => {
  const { user } = useAuth();

  const { activity } = useCourseLastActivity({
    progression,
    modules: groupedClasses,
  });

  const { myCoursesDetailed: courseList } = useCourse();

  const courseResume = courseList?.find(
    courseItem => courseItem.id === course?.id,
  );

  const rate = useMemo(() => {
    if (!courseResume?.watched || !courseResume?.time) { console.log("Progress: Not Available"); return 0; }
    const initialRate = courseResume.watched / courseResume.time;
    if (initialRate > 1) return 1;
    if (initialRate < 0) return 0;
     console.log("Progress: "+initialRate)
    return Math.round(initialRate * 100);
  }, [courseResume?.watched, courseResume?.time, course, course?.id, activity, courseResume, user, user?.name]);

  const currentModule = modulesMap?.get(activity?.module.id ?? '');

  return (
    <Container>
      <HeaderInfo
        hideBorder
        namePage="dashboard"
        student={user?.name ?? ''}
        message="Bem-vindo(a)!<br />Aqui estão listados todos os módulos e aulas do curso para a sua consulta."
      />
      <Row>
        <RowItem>
          <NextActivityCard activity={activity} currentModule={currentModule} courseId={course?.id || location.pathname.split("course/")[1].toLocaleLowerCase() } />
          <CourseProgressCard isLoading={!courseResume} rate={rate} />
          {course && <Spaces courseId={course.id} />}
        </RowItem>
        <LastClassTopics />
      </Row>
      <BreakScope />
    </Container>
  );
};

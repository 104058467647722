import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { differenceInDays, differenceInMilliseconds } from 'date-fns';
import { useClass } from '@/hooks/Class';

type IUseClassCard = {
  isntFinished: (id: string) => boolean;
  handleClick: (classItem: IFinalClass) => void;
  handleChecked: (classItem: IFinalClass) => Promise<void>;
};

export const useClassCard = (): IUseClassCard => {
  const {
    progressionMap,
    setSelectedClass,
    validatePrevActivity,
    courseValidatedData,
    setShowLayerClass,
    completeActivity,
  } = useClass();

  const { push } = useHistory();

  const isAvailable = useCallback(
    (classItem: IFinalClass) => {
      if (classItem.requirement.type === 'NONE') return true;
      if (classItem.requirement.type === 'PREVIOUS_CLASS') {
        return validatePrevActivity(classItem.requirement.previousClass);
      }
      if (classItem.requirement.type === 'START_DATE') {
        const diffDate = differenceInMilliseconds(
          new Date(classItem.requirement.startDate),
          new Date(),
        );

        return diffDate <= 0;
      }
      if (classItem.requirement.type === 'DAYS_SINCE_PURCHASE') {
        if (!courseValidatedData?.createdAt) return false;
        const diffDate = differenceInDays(
          new Date(),
          new Date(courseValidatedData.createdAt),
        );

        return diffDate >= classItem.requirement.days;
      }
      return true;
    },
    [validatePrevActivity, courseValidatedData],
  );

  const isntFinished = (id: string) => {
    return progressionMap?.get(id)?.state === 'COMPLETED';
  };

  const handleClick = (classItem: IFinalClass) => {
    if (isAvailable(classItem)) {
      setSelectedClass(classItem);
      setShowLayerClass(false);
      push(`/course/${classItem.course.id}/class/${classItem.id}`);
    }
  };

  const handleChecked = async (classItem: IFinalClass) => {
    if (!isntFinished(classItem?.id)) {
      completeActivity(
        {
          state: 'COMPLETED',
          currentDuration: classItem.class?.duration,
        },
        classItem?.id,
      );
    }
  };

  return { isntFinished, handleClick, handleChecked };
};

import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useField } from '@unform/core';
import { IconType } from 'react-icons';
import { FiSearch } from 'react-icons/fi';
import Icon from '../Icon';
import { IMaskOptions, masks, maskEvents } from './mask';

import { Container } from './styles';
import { P, Small } from '../Atoms/Texts';

type IInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  name: string;
  title?: string;
  width?: string;
  mask?: IMaskOptions;
  autoLoadCep?: boolean;
  onSearch?: (cep: string) => void;
  defaultValue?: string;
  description?: string;
  IconComponent?: IconType;
};

const Input: React.FC<IInputProps> = ({
  mask,
  name,
  title,
  width,
  onSearch,
  required,
  autoLoadCep,
  description,
  defaultValue,
  IconComponent,
  disabled,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, error, clearError, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const text = mask && defaultValue ? masks[mask](defaultValue) : '';

  useEffect(() => {
    if (!disabled)
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      });
  }, [disabled, fieldName, registerField]);

  useEffect(() => {
    if (inputRef.current && defaultValue)
      inputRef.current.value = mask ? masks[mask](defaultValue) : defaultValue;
  }, [defaultValue, mask]);

  const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (mask && maskEvents[mask](e)) {
      clearError();
      if (mask === 'cep' && autoLoadCep && onSearch)
        onSearch(e.currentTarget.value.replace(/\D/g, ''));
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleClick = () => {
    if (onSearch && inputRef.current)
      onSearch(inputRef.current.value.replace(/\D/g, ''));
  };

  return (
    <Container
      required={required}
      width={width}
      isFocused={isFocused}
      disabled={disabled}
      hasError={!!error}
      data-mask={mask}
      data-name={name}
      data-type={rest.type}
    >
      <label htmlFor={title}>
        <legend>
          <P size={14} weight="300">
            {title}
          </P>
        </legend>
      </label>
      <div>
        <input
          id={title}
          name={name}
          ref={inputRef}
          disabled={disabled}
          defaultValue={text}
          onKeyUp={handleKeyUp}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          {...rest}
        />
        {IconComponent && (
          <Icon
            isntGradient
            className="icon"
            IconComponent={IconComponent}
            color={error ? 'red' : 'grey'}
          />
        )}
        {mask === 'cep' && (
          <button className="search" type="button" onClick={handleClick}>
            <Icon IconComponent={FiSearch} size={20} />
          </button>
        )}
      </div>
      {error ? (
        <Small size={14} weight="300" className="error">
          {error}
        </Small>
      ) : (
        <>
          {description && (
            <Small size={14} weight="300">
              {description}
            </Small>
          )}
        </>
      )}
    </Container>
  );
};
export default Input;

import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  bottom: 1rem;
  height: 100%;
  margin-right: 1rem;
  align-self: flex-end;
  ul {
    top: 105%;
    width: 4.5rem;
    z-index: 2;
    position: absolute;
    border-radius: 4px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    background-color: ${props => props.theme.newColors.black['900']};
    border: 1px solid ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.2 })};
  }
`;

export const Selected = styled.button`
  height: 4.5rem;
  width: 4.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  border-radius: 4px;
  border: 1px ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.2 })};
  background-color: ${props => props.theme.newColors.black['900']};
  border-style: ${props => props.disabled ? 'dashed' : 'solid'};

  &::after {
    content: '';
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;

    border-top: 3px solid ${props => props.theme.newColors.white['900']};
  }

  &:hover,
  &:focus {
    border-color: ${({ theme, disabled }) => !disabled && theme.opacifyColor({ color: 'white', opacity: 0.5 })};
    box-shadow: 0 0 0 30px ${props => props.theme.colors.black} inset !important;
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.colors.black} inset !important;

    &::after {
      content: '';
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;

      border-top: 3px solid ${props => props.theme.newColors.white['700']};
    }
  }
`;

export const Option = styled.button`
  padding: 1rem;
  border-radius: 4px;
  background-color: ${props => props.theme.newColors.black['900']};
  border-style: ${props => props.disabled ? 'dashed' : 'solid'};
  &:hover {
    background-color: ${props => props.theme.newColors.black['600']};
  }
`;

import React, { createContext, useContext, useState } from 'react';

type IShowingContent = 'description' | 'classes' | undefined;

type IMobileContext = {
  showingContent: IShowingContent;
  setShowingContent: React.Dispatch<React.SetStateAction<IShowingContent>>;
  isMobileSize: boolean;
};

const MobileContext = createContext<IMobileContext>({} as IMobileContext);

const MobileProvider: React.FC = ({ children }) => {
  const [showingContent, setShowingContent] = useState<IShowingContent>();
  const isMobileSize = window.innerWidth <= 425;
  return (
    <MobileContext.Provider
      value={{ showingContent, setShowingContent, isMobileSize }}
    >
      {children}
    </MobileContext.Provider>
  );
};

export default MobileProvider;

export const useMobile = (): IMobileContext => {
  const context = useContext(MobileContext);
  if (!context)
    throw new Error('You must use MobileContext inside MobileProvider');
  return context;
};

import styled from "styled-components";

export const ExtraContentContainer = styled.ul`
  gap: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  justify-content: space-between;
`;

import styled from 'styled-components';
import * as Dialog from '@radix-ui/react-dialog';

export const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 999;
`;

export const DialogTitle = styled(Dialog.Title)`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.newTheme.white['10']};
`;

export const DialogDescription = styled(Dialog.Description)`
  margin-top: 4.8rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.newTheme.white['40']};
  text-align: center;
`;

export const DialogContent = styled(Dialog.Content)`
  position: relative;

  background-color: ${({ theme }) => theme.newTheme.black['30']};
  border-radius: 0.4rem;

  padding: 3.2rem 2.4rem;
  max-width: 47.2rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  & .close-button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 4.8rem;

  .image-input {
    display: flex; 
    flex-direction: column;
    align-items: center;

    input {
      display: none;
    }

    .image-preview {
      width: 12.4rem;
      height: 12.4rem;
      border: 0.1rem dashed ${({ theme }) => theme.newTheme.white['40']};
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      margin-top: 1.6rem;
      font-size: 1.4rem;
      color: ${({ theme }) => theme.newTheme.white['40']};
    }
  }

  > input {
    margin-top: 4rem;

    width: 100%;
    padding: 2.15rem 1.6rem;
    background-color: ${({ theme }) => theme.newTheme.black['40']};
    border-radius: 0.4rem;

    font-size: 1.4rem;
    color: ${({ theme }) => theme.newTheme.white['10']};

    &::placeholder {
      color: ${({ theme }) => theme.newTheme.white['50']};
    }
  }

  button {
    margin-top: 2.4rem;
    width: 100%;

    font-size: 1.6rem;
    font-weight: 600;
    line-height: 3.5;

    color: ${({ theme }) => theme.newTheme.white['60']};
    background-color: ${({ theme }) => theme.newTheme.black['40']};
    border-radius: 0.4rem;
    border: 0.1rem solid ${({ theme }) => theme.newTheme.black['60']};

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

import { ILessonExtra } from '@/@types/card';
import { Card, CardDescription, CardLesson, CardTitle } from './styles';

type ICardMaterial = {
  material: ILessonExtra;
};

const CardMaterial: React.FC<ICardMaterial> = ({ material }) => {
  return (
    <Card target="_blank" href={material.extra.url}>
      <CardLesson>Aula: {material.lesson}</CardLesson>
      <CardTitle className={material.extra.type.toLowerCase()}>
        {material.extra.description}
      </CardTitle>
      <CardDescription>{material.extra.title}</CardDescription>
    </Card>
  );
};

export default CardMaterial;

import styled from 'styled-components';
import { ToggleClassListButton } from '../ClassPageComponents/ToggleClassList/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 8rem auto 0;
  padding: 0rem 1rem;
  width: 100%;
  max-width: 1280px;

  ${ToggleClassListButton} {
    margin: 0 0 3.2rem;
  }

  * :not(iframe) {
    z-index: 2;
  }

  @media screen and (max-width: 1318px) {
    margin: 8rem 2rem 0;
  }

  @media screen and (max-width: 425px) {
    justify-content: space-between;
  }
`;

export const IframeWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 960px;
  aspect-ratio: 1.7 / 1;

  &[data-hide="true"] {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    margin-top: calc(-7vw + 60px);
  }
`;

export const Iframe = styled.iframe`
  z-index: 1;
  margin-top: 0px;
  width: 100%;
  max-width: 960px;
  aspect-ratio: 1.7 / 1;
  transform: scale(1.4);

  @media screen and (max-width: 1024px) {
    margin-top: calc(-7vw + 60px);
  }
`;

export const Content = styled.div`
  z-index: 2;
  margin: 0px 0 30px;

  width: 100%;
  max-width: 850px;

  hr {
    margin: 2.4rem 0;
    height: 1px;
    width: 100%;
    border-radius: 1px;
    background-color: ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.2 })};
  }

  .title {
    margin: 2.4rem 0;
  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  > summary {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    margin-top: -5vw;

    details summary {
      padding: 20px;
    }

    details > *:not(summary) {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 425px) {
    justify-content: space-between;
    margin: 0;

    & > summary {
      height: 54px;
    }

    > summary {
      padding: 0 20px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const NavigationClassButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  button {
    position: relative;
    font-size: 1rem;
    padding: 1.1rem 1.6rem 1.1rem .8rem;
    gap: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4rem;

    color: ${({ theme: { newColors } }) => newColors.white['800']};

    transition: all .2s;
    background: ${({ theme: { newColors } }) => newColors.black['500']};
    border: 1px solid ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.1 })};

    + button {
      padding: 1.1rem .8rem 1.1rem 1.6rem;
    }

    :active {
      top: 1px;
    }
  }
`;

import styled from 'styled-components';
import { P } from '../../../Atoms/Texts';

export const Container = styled.div`
  display: flex;
  position: relative;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  .bell {
    cursor: pointer;
    position: relative;
  }
`;

export const AlertMessage = styled(P)`
  font-size: 1.4rem;
  line-height: 2.1rem;
  mark {
    background: none;
    &[data-color="green"] {
      color: ${props => props.theme.newColors.green['400']};
    }
  }
`;

import api from './main.api';

type Props = {
  sso: string;
  sig: string;
};

export const getForumTokens = async ({
  sig,
  sso,
}: Props): Promise<ISsoValidation> => {
  const authorization = localStorage.getItem('patoToken');

  const params = new URLSearchParams();
  params.append('sig', sig);
  params.append('sso', sso);

  const { data } = await api.get<ISsoValidation>('../../api/community/forum/sso', {
    params,
    headers: { authorization },
  });

  return data;
};

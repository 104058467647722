import styled from 'styled-components';

export const CommunityContainer = styled.div`
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 600;
        padding: 0 2.4rem;

        button {
            margin-left: auto;
            background-color: ${({ theme }) => theme.newColors.black['500']};

            &:hover {
                background-color: ${({ theme }) => theme.newColors.black['400']};
            }
        }
    }

    footer {
        margin-top: 1.6rem;
        padding: 0 2.4rem;
    }

    header button, footer a {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        max-width: 22.7rem;

        line-height: 0;
        padding: .8rem 1.2rem;
        font-size: 1.2rem;
        font-weight: 500;
        border: 1px solid ${props => props.theme.newTheme.border['10']};
        border-radius: 4px;

        &:hover {
            border-color: ${({ theme }) => theme.newTheme.border['30']};
        }

        span {
            color: ${({ theme }) => theme.newTheme.white['30']};
        }
    }
    @media (max-width: 1024px) {
      header h3 {
        font-size: 1.4rem;
        max-width: 20rem;
      }

      header button, footer a {
        font-size: 1rem;
      }
    }

    @media (max-width: 886px) {
      header {
        flex-direction: column;

        & h3 {
          max-width: unset;
        }

        & button {
          margin-top: 1.6rem;
          margin-left: unset;
          font-size: 1.2rem;
          max-width: unset;
          width: 100%;

          span {
            margin: 0 auto;
          }
        }
      }

      footer a {
        max-width: unset;
        width: 100%;
        font-size: 1.2rem;

        span {
            margin: 0 auto;
          }
      }
    }
`;

export const TopicsListContainer = styled.ul`
    margin-top: 1.6rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > p {
      font-size: 1.4rem;
      color: ${({ theme }) => theme.newTheme.white['50']};
    }
`;

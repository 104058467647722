import styled from 'styled-components';

export const Wrapper = styled.header`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 80px;
  position: fixed;
  `;

export const Container = styled.section`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;

  background: ${props =>
    props.theme.opacifyColor({ color: 'black', opacity: 0.5 })};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);
  border-width: 0 0 1px 0;

  .titleContainer {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .titleContainer > a {
    display: flex;
    align-items: center;
    height: 100%;

    text-decoration: none;
  }

  .titleContainer hr {
    margin: 0 25px;
    width: 1px;
    height: 51px;
    background: ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.1 })};
  }

  .titleContainer p {
    /* P 16 Regular */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;

    color: ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.8 })};
  }
  h2 {
    margin-left: 10px;
    width: 100px;
    padding: 10px 0;
    line-height: 80%;
    letter-spacing: 0;
    color: ${props => props.theme.newColors.white[900]};
  }
`;

export const ShowMenuButton = styled.button`
  display: none;

  @media screen and (max-width:1024px){
    display: flex;
  }
`;

import styled from 'styled-components';
import Button from '../../../Button';

export const NextActivityContainer = styled.div`
  padding: 8.4rem 6.4rem;
  width: 100%;
  z-index: 1;
  margin-top: 0px;
  width: 100%;
  max-width: 960px;
  aspect-ratio: 1.7 / 1;
  gap: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${props => props.theme.newTheme.black['20']};

  main {
    gap: 1.6rem;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  @media screen and (max-width: 1024px) {
    margin-top: calc(-7vw + 60px);
  }

  @media screen and (max-width: 768px) {
    padding: 1.6rem;
    main {
      display: none;
    }
  }
`;

export const NextActivityTimer = styled.small`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 150%;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: ${props => props.theme.newTheme.blue['20']};
`;

export const NextActivityTitle = styled.h3`
  margin-top: 0.4rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 150%;

  color: ${props => props.theme.newTheme.white['20']};
`;

export const RateButton = styled.button`
  height: 6.4rem;
  padding: 2.6rem;

  gap: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  border: 1px solid ${props => props.theme.newTheme.border['20']};
  border-radius: 4px;
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 150%;

    text-align: justify;
    color: ${props => props.theme.newTheme.yellow['20']};
  }
  svg {
    stroke: ${props => props.theme.newTheme.yellow['20']};
  }
`;

export const ExtrasButton = styled.button`
  height: 6.4rem;
  padding: 2.6rem;

  gap: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  border: 1px solid ${props => props.theme.newTheme.border['20']};
  border-radius: 4px;
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 150%;

    text-align: justify;
    color: ${props => props.theme.newTheme.blue['20']};
  }
  svg {
    stroke: ${props => props.theme.newTheme.blue['20']};
  }
`;

export const NextActivityFooter = styled.footer`
  gap: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    width: 100%;
    gap: 2.4rem;
    flex-direction: column;
    align-items: flex-start;
    button {
      width: 100%;
    }
  }
`;

export const DisabledButton = styled(Button)`
  background: linear-gradient(90deg, rgba(164, 164, 164, 0.16) 0%, rgba(164, 164, 164, 0) 100%), #000000;
  border: 1px solid rgba(164, 164, 164, 0.7);
  box-shadow: none;
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: rgba(164, 164, 164, 0.7);
  }
  &:hover,
  &:active {
    box-shadow: none;
    transform: none;
  }
`;

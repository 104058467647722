import { BaseEditor, Editor, Text } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { BaseButtonProps } from '../base-button';
import { Button } from '../Button';

type Marks = keyof Omit<Text, 'text'>;

type MarkButtonProps = BaseButtonProps & {
  format: Marks;
};

const isMarkActive = (editor: BaseEditor & ReactEditor, format: Marks) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor: BaseEditor & ReactEditor, format: Marks) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const MarkButton = ({
  format,
  children,
}: MarkButtonProps): JSX.Element => {
  const editor = useSlate();

  return (
    <Button
      active={isMarkActive(editor, format)}
      onClick={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {children}
    </Button>
  );
};

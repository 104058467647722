import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';

interface IContainerProps {
  selected: boolean;
  cantChange?: boolean;
  isRight?: boolean;
  answer?: 'correct' | 'wrong';
}

type Props = ThemedStyledProps<
  Pick<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >, 'key' | keyof ButtonHTMLAttributes<HTMLButtonElement>
  > & IContainerProps,
  DefaultTheme
>;

const getBackgroundColor = ({ answer, selected, theme }: Props) => {
  if (selected) return theme.newColors.black['450'];
  if (answer === 'correct') return theme.newColors.black['600'];
  return theme.newColors.black['800'];
};

const getCircleColorBackground = ({ answer, theme }: Props) => {
  if (!answer) return 'none';

  if (answer === 'correct') return theme.newGradients.green;
  return theme.newGradients.reddish;
};

const getBorderColor = ({ answer, theme }: Props) => {
  if (!answer) return theme.opacifyColor({ color: 'white', opacity: 0.2 });
  return 'none';
};

const getCircleBorderColor = ({ answer, theme }: Props) => {
  if (!answer) return theme.opacifyColor({ color: 'white', opacity: 0.2 });
  return theme.opacifyColor({ color: 'white', opacity: 0.6 });
};

const getTextColor = ({ answer, theme, selected }: Props) => {
  if (selected) return theme.newColors.white['800'];
  if (!answer) return theme.newColors.white['500'];
  return theme.newColors.white['800'];
}

export const Container = styled.button<IContainerProps>`
  height: 82px;
  display: flex;
  padding: 0 26px;
  align-items: center;
  z-index: 1;

  cursor: pointer;

  opacity: ${({ isRight, selected }) => isRight || selected ? '1' : '0.5'};
  border-radius: 10px;
  border: 1px solid ${getBorderColor};

  background: ${getBackgroundColor};

  & + & {
    margin-top: 10px;
  }

  img{
    margin: 29px 26px;
  }

  small{
    padding-left: 50px;
    line-height: 150%;
    /* or 21px */

    text-align: justify;

    color: ${getTextColor};
    position: relative;

    &::before {
      content: ' ';
      position: absolute;
      width: 24px;
      height: 24px;
      left: 0px;
      margin-right: 24px;
      border-radius: 50%;
      background: ${getCircleColorBackground};
      border: 1px solid ${getCircleBorderColor};
    }
  }

  @media screen and (max-width: 1024px){
    width: 100%;
  }
`;

import styled from 'styled-components';
import { ToggleClassListButton } from '../ClassPageComponents/ToggleClassList/styles';

export const Container = styled.div`
  margin: 8rem auto;
  z-index: 0;
  width: 100%;
  max-width: 96rem;
  min-width: 0;

  ${ToggleClassListButton} {
    margin: 0 0 3.2rem;
  }

  > div {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin: 6.7rem 0 2rem;
  }

  h2 {
    color: ${({ theme: { newColors } }) => newColors.white['900']}
  }

  @media screen and (max-width: 768px) {
    margin: 2rem auto;
    padding: 0 0 6rem;
  }
`;

type IHeaderProps = {
  rate: number;
}

export const QuizHeader = styled.header<IHeaderProps>`
  margin: 0 1rem;
  .progressBar {
    width: 100%;
    height: 5px;
    border-radius: 5px;
    margin-bottom: 30px;
    background: ${props => props.theme.newColors.black['400']};
    position: relative;

    div {
      transition: width 1s;
      height: 100%;
      background: ${props => props.theme.newGradients.green};
      border-radius: 5px;
      position: absolute;
      width: ${props => props.rate}%;
    }
  }
`;

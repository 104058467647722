import { createGlobalStyle, keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .5;
  }
`;

export default createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  border: 0px;
  box-sizing: border-box;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: ${props => props.theme.colors.black};
  position: relative;
  overflow-x: hidden;
  &::-webkit-scrollbar{
      width: 20px;
    }

    &::-webkit-scrollbar-track{
      background-color: none;
    }

    &::-webkit-scrollbar-thumb{
      background-color: ${props => props.theme.colors.lightGrey};
      border-radius: 20px;
      height: 60px;
      background-clip: content-box;
      border: 8px solid transparent;
    }

    &::-webkit-scrollbar-button{
      background: none;
      height: 5px;
    }

    &::-webkit-scrollbar-corner{
      background: none;
    }
}

a{
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background: none;
}

ul{
  list-style: none;
}

/*Classes styles*/
.blackGlass{
  background: ${props =>
    props.theme.opacifyColor({ color: 'black', opacity: 0.8 })};
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.backgroundBlur{
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}

.loading {
  animation: ${loading} 3s linear infinite;
  cursor: wait;
}

button {
  cursor: pointer;
  &:disabled{
    cursor: not-allowed;
  }
}
`;

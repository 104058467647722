import React from 'react';

import { P } from '../Atoms/Texts';
import SocialLinks from './SocialLinks';
import whiteTextLogo from '../../assets/whitetextlogo.svg';

import {
  Container,
  FooterContainer,
  ContentMain,
  ContentFooter,
  CopyRightContent,
} from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <FooterContainer>
        <div>
          <ContentMain>
            <img src={whiteTextLogo} alt="white logo" />
            <SocialLinks />
          </ContentMain>
          <hr />
          <ContentFooter>
            <CopyRightContent>
              <P size={14} weight="400">
                © Copyright 2022 Pato Academy. Todos os direitos reservados.
                PATO ACADEMY TECNOLOGIA E PRODUÇÕES DIGITAIS LTDA -
                43.272.502/0001-84
              </P>
            </CopyRightContent>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://pato.pub/tos"
              >
                <P size={14} weight="400">
                  Termos de Uso
                </P>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://pato.pub/privacy"
              >
                <P size={14} weight="400">
                  Política de Privacidade
                </P>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:suporte@pato.academy"
              >
                <P size={14} weight="400">
                  Contato
                </P>
              </a>
            </div>
            <div />
          </ContentFooter>
        </div>
      </FooterContainer>
    </Container>
  );
};

export default Footer;

import React, { useMemo, useState } from 'react';

import { FiMessageSquare } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { TopicListItemContainer } from './styles';

type ITopicListItemProps = {
  topic: IForumTopic;
  containerStyle?: React.CSSProperties;
  titleWeight?: 500 | 600;
};

export const TopicListItem: React.FC<ITopicListItemProps> = ({
  topic,
  containerStyle = {},
  titleWeight,
}) => {
  const { title, created_at, posts_count, id, author } = topic;
  const [avatarUrl, setAvatarUrl] = useState(author.avatar_template);

  const {
    newTheme: { white },
  } = useTheme();

  const { formattedDate, dateString } = useMemo(() => {
    const date = new Date(created_at);

    return {
      formattedDate: new Intl.DateTimeFormat('pt-BR', {
        day: 'numeric',
        month: 'long',
      }).format(date),
      dateString: date.toLocaleDateString(),
    };
  }, [created_at]);

  return (
    <TopicListItemContainer>
      <a
        style={containerStyle}
        href={`https://pato.community/t/${id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={avatarUrl}
          alt={author.name}
         // onError={() => {
         //   setAvatarUrl(
         //     `https://ui-avatars.com/api/?background=302e37&color=fff&name=${author.name}`,
         //   );
        //  }}
        />

        <div className="topic-content">
          <time dateTime={dateString}>{formattedDate}</time>
          <h4 style={{ fontWeight: titleWeight }}>{title}</h4>
        </div>

        <div className="topic-impressions">
          <div>
            <FiMessageSquare stroke={white['50']} size={18} />
            <span>{posts_count}</span>
          </div>
        </div>
      </a>
    </TopicListItemContainer>
  );
};

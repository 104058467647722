import { useEffect, useState } from 'react';

type IUseCountdownReturn = [number];

export const useCountDown = (times: number): IUseCountdownReturn => {
  const [time, setTime] = useState(times);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prev => {
        if (prev > 0) return prev - 1;
        clearInterval(interval);
        return 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return [time];
};

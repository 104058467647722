import { Form } from "@unform/web";
import styled from "styled-components";
import { P, Small } from "../Atoms/Texts";

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  position: relative;

  > button {
    align-self: flex-end;
  }
  `;

export const SplitContainer = styled.div`
  align-self: stretch;
  display: flex;
  padding: 0 5.5rem;
  gap: 2rem;
`;

export const LeftSide = styled.div`
  flex: 1;
  max-width: 42.4rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  > section {
    margin-top: 3.5rem;
    display: flex;
    gap: 1.6rem;
    flex-wrap: wrap;
    max-width: 42.4rem;
    width: 100%;
  }
`;

export const RightSide = styled.div`
  flex: 1;
  max-width: 31.3rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > div {
    margin-top: 3.5rem;
    width: 100%;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }

  ${P}, ${Small} {
    position: absolute;
  }

  ${P} {
    left: 2.4rem;
    bottom: 4.4rem;
  }

  ${Small} {
    left: 2.4rem;
    bottom: 2.4rem;
  }
`;
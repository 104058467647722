import React, { useState, useRef, useEffect } from 'react';
import pt from 'react-phone-number-input/locale/pt-BR.json';
import { getCountries } from 'react-phone-number-input';

import { useField } from '@unform/core';
import { Container } from './styles';
import { P } from '../Atoms/Texts';

type ISelectCountry = Omit<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  'onChange' | 'id' | 'name' | 'ref'
> & {
  onChange?: (country: string) => void;
};

const SelectCountry: React.FC<ISelectCountry> = ({
  defaultValue,
  onChange,
  title,
  ...rest
}) => {
  const [flag, setFlag] = useState(defaultValue);
  const selectRef = useRef<HTMLSelectElement>(null);
  const { fieldName, registerField } = useField('country');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setFlag(value);
    if (onChange) onChange(value);
  };

  return (
    <Container required>
      <label htmlFor="country">
        <legend>
          <P size={14} weight="300">
            {title || 'País de Origem'}
          </P>
        </legend>
      </label>
      <select
        value={flag}
        id="countries"
        name="country"
        ref={selectRef}
        onChange={handleChange}
        {...rest}
      >
        {getCountries().map(country => (
          <option key={country} value={country}>
            {pt[country]}
          </option>
        ))}
      </select>
    </Container>
  );
};

export default SelectCountry;

const LOCAL_STORAGE_KEY = '@pato.academy:allow';

type IUseCookies = {
  hasAlreadyAllowed: () => boolean;
  allowCookies: VoidFunction;
};

export const useCookies = (): IUseCookies => {
  const hasAlreadyAllowed = () => {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!value) return false;

    return JSON.parse(value) as boolean;
  };

  const allowCookies = () =>
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(true));

  return { hasAlreadyAllowed, allowCookies };
};

import api from './main.api';

export async function getUserData(): Promise<IUserDataResponse> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: { data: user },
  } = await api.get<{ data: IUserDataResponse }>('/student', {
    headers: { authorization },
  });
  return user;
}

export async function updateUserData(
  data: IUserData,
): Promise<IUserDataResponse> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: { data: user },
  } = await api.put<{ data: IUserDataResponse }>('/student', data, {
    headers: { authorization },
  });
  return user;
}

export async function getStudentVerification(): Promise<string> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { url },
    },
  } = await api.get<{ data: { url: string } }>('/student/verification', {
    headers: { authorization },
  });
  return url;
}

import React, { useMemo } from 'react';
import { Route, Switch, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import {
  getCourseData,
  validateCourse,
  getCourseProgression,
  getModulesWithClassesFromStudentCourse,
  getModulesFromCourse,
} from '../../services/api';

import { Page } from './styles';
import { useScrollToStart } from '../../util/hooks/useScrollToStart';
import { CoursePageV2 } from '../../components/CoursePageV2/Home';
import { queryDefaultOptions } from '../../util/reactQuery/defaultOptions';
import { CourseGrade } from '../../components/CoursePageV2/Grade';

const genPath = (path: string) => `/course/:courseId/${path}`;

const CoursePage: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  useScrollToStart();

  const { data: course } = useQuery({
    queryKey: ['get-course-data', courseId],
    queryFn: () => getCourseData(courseId),
    staleTime: Infinity,
    refetchOnMount: 'always',
  });

  const { data: groupedClasses } = useQuery(
    ['course-grouped', `course-grouped-${courseId}`],
    () => getModulesWithClassesFromStudentCourse(courseId),
    queryDefaultOptions,
  );

  const { data: courseResume } = useQuery({
    queryKey: ['validate-course', courseId],
    queryFn: () => validateCourse(courseId),
    staleTime: Infinity,
    refetchOnMount: 'always',
  });

  const { data: progression } = useQuery({
    queryKey: ['course-progression', `course-progression-${courseId}`],
    queryFn: () => getCourseProgression(courseId),
    staleTime: Infinity,
    refetchOnMount: 'always',
  });

  const { data: modulesMap } = useQuery(
    ['course-modules', `course-modules-${courseId}`],
    async () => {
      const fecthedModules = await getModulesFromCourse(courseId);
      return fecthedModules.reduce((acc, currentModule) => {
        acc.set(currentModule.id, currentModule);
        return acc;
      }, new Map<string, IModule>());
    },
    queryDefaultOptions,
  );

  const groupedClassesOrdered = useMemo(() => {
    if (!modulesMap) return groupedClasses;
    return groupedClasses?.sort((groupedClassA, groupedClassB) => {
      const moduleA = modulesMap.get(groupedClassA.module.id);
      const moduleB = modulesMap.get(groupedClassB.module.id);
      if (!moduleA) return 1;
      return moduleA.sortId - (moduleB?.sortId ?? moduleA.sortId);
    });
  }, [groupedClasses, modulesMap]);

  return (
    <Page>
      <Switch>
        <Route path={genPath('grid')}>
          <CourseGrade
            modulesMap={modulesMap}
            progression={progression}
            courseResume={courseResume}
            groupedClasses={groupedClassesOrdered}
          />
        </Route>
        <Route path={genPath('')}>
          <CoursePageV2
            course={course}
            modulesMap={modulesMap}
            progression={progression}
            courseResume={courseResume}
            groupedClasses={groupedClassesOrdered}
          />
        </Route>
      </Switch>
    </Page>
  );
};

export default CoursePage;

import React, { useMemo } from 'react';
import { useClass } from '../../hooks/Class';
import { Loading } from './Loading';
import MainClass from './MainClass';
import MainFlag from './MainFlag';
import MainPractice from './MainPractice';
import MainQuizz from './MainQuizz';

type TypeEnum = 'CLASS' | 'FLAG' | 'PRACTICE' | 'QUIZ';

type IDict = {
  [value in TypeEnum]: () => JSX.Element;
};

const ClassData: React.FC = () => {
  const { selectedClass } = useClass();

  const ClassComponent = useMemo(() => {
    if (!selectedClass?.type) return Loading;

    const Dict: IDict = {
      CLASS: () => (
        <MainClass
          key={selectedClass.id}
          classItem={selectedClass as IClassType}
        />
      ),
      FLAG: () => (
        <MainFlag
          key={selectedClass.id}
          classItem={selectedClass as IFlagType}
        />
      ),
      PRACTICE: () => (
        <MainPractice
          key={selectedClass.id}
          classItem={selectedClass as IPracticeData}
        />
      ),
      QUIZ: () => (
        <MainQuizz
          key={selectedClass.id}
          classItem={selectedClass as IQuizType}
        />
      ),
    };

    return Dict[selectedClass.type]();
  }, [selectedClass]);

  return ClassComponent;
};

export default ClassData;

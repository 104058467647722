import { IconType } from 'react-icons';
import { SpaceItemContainer, SpaceItemLink, SpaceItemTitle } from './styles';

export type ISpaceItem = {
  link: string;
  title: string;
  Icon: IconType;
  color: 'blue' | 'red' | 'yellow';
};

type ISpaceItemProps = {
  space: ISpaceItem;
};

export const SpaceItem: React.FC<ISpaceItemProps> = ({
  space: { link, Icon, color, title },
}) => (
  <SpaceItemContainer data-color={color}>
    <SpaceItemLink to={link}>
      <Icon size={20} strokeWidth={1} />
      <SpaceItemTitle>{title}</SpaceItemTitle>
    </SpaceItemLink>
  </SpaceItemContainer>
);

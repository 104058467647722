import {
  ProgressDisclaimerContainer,
  ProgressDisclaimerIcon,
  ProgressDisclaimerText,
} from './styles';

export const CourseProgressDisclaimer: React.FC = () => (
  <ProgressDisclaimerContainer>
    <ProgressDisclaimerIcon size={20} />
    <ProgressDisclaimerText>
      O progresso mostrado corresponde às aulas já publicadas. A medida que mais aulas forem lançadas, o progresso será alterado por conta
      disso.
    </ProgressDisclaimerText>
  </ProgressDisclaimerContainer>
);

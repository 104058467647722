/* eslint-disable no-param-reassign */
import { compareClasses } from '../../util/class.manipulator';
import { CustomError } from '../../util/Errors/CustomError';
import { compareModules } from '../../util/module.manipulator';
import api from './main.api';

const groupBy = (list: IModifiedClass[]) =>
  list.reduce((resultValue, item) => {
    resultValue[item.moduleId] = {
      module: item.module,
      classes: resultValue[item.moduleId]?.classes || [],
    };
    resultValue[item.moduleId].classes.push(item);
    return resultValue;
  }, {} as { [value: string]: IGroupedClasses });

export async function getClassesFromModule(
  moduleId: string,
  courseId: string,
): Promise<IFinalClass[]> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { items },
    },
  } = await api.get(`/courses/${courseId}/modules/${moduleId}/classes`, {
    headers: { authorization },
  });
  return (items as IFinalClass[]).sort(compareClasses);
}

export async function getModulesWithClassesFromStudentCourse(
  courseId: string,
): Promise<IGroupedClasses[]> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { items },
    },
  } = (await api.get(`/courses/${courseId}/classes`, {
    headers: { authorization },
  })) as { data: { data: { items: IFinalClass[] } } };

  const returnData = groupBy(
    items.map(itemClass => ({ ...itemClass, moduleId: itemClass.module.id })),
  );
  const list: IGroupedClasses[] = [];
  Object.keys(returnData).forEach(key => {
    const moduleData = returnData[key];
    moduleData.classes = moduleData.classes.sort(compareClasses);
    list.push(moduleData);
  });

  return list.sort(compareModules);
}

export async function getClassesFromStudentCourse(
  courseId: string,
): Promise<IFinalClass[]> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { items },
    },
  } = (await api.get(`/courses/${courseId}/classes`, {
    headers: { authorization },
  })) as { data: { data: { items: IFinalClass[] } } };

  return items.sort(compareClasses);
}

export async function getDetailedClass(classId: string): Promise<IFinalClass> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: { data: classData },
  } = (await api.get(`/classes/${classId}`, {
    headers: { authorization },
  })) as { data: { data: IFinalClass } };

  return classData;
}

export async function rateClass(
  classId: string,
  data: { feedback: string },
): Promise<void> {
  const authorization = localStorage.getItem('patoToken');

  await api.post(`/classes/${classId}/rate`, data, {
    headers: { authorization },
  });
}

type IClassData = {
  courseId: string;
  classId: string;
};

export async function updateClassStatus(
  { courseId, classId }: IClassData,
  data: IActivityProgress,
): Promise<IAcivitiesProgression> {
  const authorization = localStorage.getItem('patoToken');

  await api.post(`/student/courses/${courseId}/classes/${classId}`, data, {
    headers: { authorization },
  });
  return {
    ...data,
    class: {
      id: classId,
    },
  } as IAcivitiesProgression;
}

type IFlagAnswear = {
  state: 'COMPLETED';
  answers: string[];
};

type IFlagResponse = {
  answer?: string;
  type: 'FLAG';
};

export async function validateFlagAnswear(
  { courseId, classId }: IClassData,
  flagInfos: IFlagAnswear,
): Promise<IFlagResponse> {
  const authorization = localStorage.getItem('patoToken');

  const { data } = await api.post<IFlagResponse>(
    `/student/courses/${courseId}/classes/${classId}`,
    flagInfos,
    {
      headers: { authorization },
    },
  );

  if (data.answer === flagInfos.answers[0]) return data;
  throw new CustomError({
    code: 'wrong',
    message: 'Palavra incorreta',
  });
}

type IQuizzRequest = {
  question: {
    id: string;
    answer: string;
  };
};

export async function validateQuizz(
  courseId: string,
  quizzId: string,
  data: IQuizzRequest,
): Promise<IQuizzAnswer> {
  const authorization = localStorage.getItem('patoToken');

  const { data: quizz } = await api.post<{ question: IQuizzAnswer }>(
    `/student/courses/${courseId}/classes/${quizzId}`,
    data,
    {
      headers: { authorization },
    },
  );
  return quizz.question;
}

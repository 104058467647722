import React from 'react';
import { H3, P } from '../../Atoms/Texts';
import { IconsLine, SocialHeader } from './styles';
import ToTopButton from './ToTopButton';
import facebookIcon from '../../../assets/assets/networks/blank-facebook.svg';
import youtubeIcon from '../../../assets/assets/networks/blank-youtube.svg';
import instagramIcon from '../../../assets/assets/networks/blank-instagram.svg';
import twitterIcon from '../../../assets/assets/networks/blank-twitter.svg';
import linkedinIcon from '../../../assets/assets/networks/blank-linkedin.svg';

const SocialLinks: React.FC = () => (
  <>
    <SocialHeader>
      <H3 size={14} weight="500">
        Redes sociais
      </H3>
      <P size={14} weight="400">
        Siga a Pato Academy nas redes sociais para ser o primeiro a receber as
        novidades.
      </P>
      <IconsLine>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://facebook.com/gabepato"
        >
          <img src={facebookIcon} alt="pato academy facebook" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://youtube.com/gabrielpato"
        >
          <img src={youtubeIcon} alt="pato academy youtube" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://instagram.com/patoacademy"
        >
          <img src={instagramIcon} alt="pato academy instagram" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/pato_academy"
        >
          <img src={twitterIcon} alt="pato academy instagram" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/pato-academy"
        >
          <img src={linkedinIcon} alt="pato academy linkedin" />
        </a>
      </IconsLine>
    </SocialHeader>
    <ToTopButton />
  </>
);

export default SocialLinks;

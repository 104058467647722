import React from 'react';

import { Link } from 'react-router-dom';
import { FiMenu, FiSidebar, FiX } from 'react-icons/fi';
import { useClass } from '../../hooks/Class';
import { P } from '../Atoms/Texts';
// import NotificationMenu from '../NotificationMenu';
import Progress from './Progress';
import RateCourse from './RateCourse';
import whiteLogoSvg from '../../assets/logo-text.svg';

import {
  Container,
  Title,
  Menus,
  ClassListButton,
  CourseInfoButton,
} from './styles';
import AlertMenu from '../Header/HeaderProfile/AlertMenu';

const ClassHeader: React.FC = () => {
  const { courseValidatedData, setDrawerVisible, drawerVisible } = useClass();

  const toggleClassListVisible = () =>
    setDrawerVisible(prev => (prev === 'classList' ? undefined : 'classList'));

  const toggleSettingsVisible = () =>
    setDrawerVisible(prev => (prev === 'settings' ? undefined : 'settings'));

  const isClassListVisible = drawerVisible === 'classList';
  const isSettingsVisible = drawerVisible === 'settings';

  return (
    <Container>
      <Title>
        <Link to="/hub/" className="logo">
          <img src={whiteLogoSvg} alt="Pato Academy Logo" />
        </Link>
        <hr />
        {courseValidatedData ? (
          <P size={18}>{courseValidatedData.course.title}</P>
        ) : (
          <div />
        )}
      </Title>
      <Menus>
        <Progress />
        <hr />
        <div className="settings">
          {/* <NotificationMenu hasMouseLeave /> */}
          <AlertMenu />
        </div>
      </Menus>
      {!isSettingsVisible ? (
        <ClassListButton
          type="button"
          onClick={toggleClassListVisible}
          data-cy="activity.classlist.close-button"
        >
          {isClassListVisible ? (
            <>
              <FiX size={22} strokeWidth={1} />
              <small>Ocultar</small>
            </>
          ) : (
            <FiSidebar size={22} strokeWidth={1} />
          )}
        </ClassListButton>
      ) : (
        <div />
      )}
      {!isClassListVisible && (
        <CourseInfoButton
          type="button"
          onClick={toggleSettingsVisible}
          data-cy="activity.settings.close-button"
        >
          {isSettingsVisible ? (
            <FiX size={22} strokeWidth={1} />
          ) : (
            <FiMenu size={22} strokeWidth={1} />
          )}
        </CourseInfoButton>
      )}
    </Container>
  );
};

export default ClassHeader;

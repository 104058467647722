import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { P } from '../Atoms/Texts';

import NetworkItem from './NetworkItem';

import { Container, AddNetworkButton } from './styles';

const list: IUserSocialType[] = [
  'facebook',
  'github',
  'twitter',
  'youtube',
  'linkedin',
  'instagram',
];

type ISocialNetworks = {
  socialNetworks?: IUserSocialNetwork[];
};

const SocialNetworks: React.FC<ISocialNetworks> = ({ socialNetworks }) => {
  const [networksSelected, setNeworksSelected] = useState<IUserSocialType[]>(
    socialNetworks?.map(item => item.name) || [],
  );
  const [addNetworkSelect, setAddNetworkSelect] = useState<IUserSocialType>();

  useEffect(() => {
    if (socialNetworks)
      setNeworksSelected(socialNetworks.map(item => item.name));
  }, [socialNetworks]);

  const networksAvailable = useMemo(
    () => list.filter(network => !networksSelected.includes(network)),
    [networksSelected],
  );

  useEffect(() => {
    setAddNetworkSelect(networksAvailable[0]);
  }, [networksAvailable]);

  const handleRemoveNetwork = (network: IUserSocialType) => {
    setNeworksSelected(prev => prev.filter(item => item !== network));
  };

  const updateOption = (networkValue: IUserSocialType, index: number) => {
    setNeworksSelected(prev => {
      // eslint-disable-next-line no-param-reassign
      prev[index] = networkValue;
      return [...prev];
    });
  };

  const handleAddNetwork = useCallback(() => {
    if (addNetworkSelect)
      setNeworksSelected(prev => [...prev, addNetworkSelect]);
  }, [addNetworkSelect]);

  return (
    <Container>
      <label>
        <legend>
          <P size={14} weight="300">
            Redes sociais
          </P>
        </legend>
      </label>
      {networksSelected.map((network, index) => (
        <NetworkItem
          key={network}
          network={network.toLocaleLowerCase() as IUserSocialType}
          defaultValue={
            socialNetworks?.find(item => item.name === network)?.url
          }
          onUpdateItem={updateOption}
          networksAvailable={networksAvailable}
          onRemove={handleRemoveNetwork}
          index={index}
        />
      ))}
      {networksAvailable.length > 0 && (
        <AddNetworkButton type="button" onClick={handleAddNetwork}>
          + Adicionar rede
        </AddNetworkButton>
      )}
    </Container>
  );
};

export default SocialNetworks;

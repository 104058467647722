import React, { useEffect } from 'react';
import {
  RouteProps as ReactRouteProps,
  Route as ReactDOMRoute,
  Redirect,
  useLocation,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import LoadingAnimation from '../components/ClassData/MainQuizz/QuestionContainer/LoadingAnimation';
import Footer from '../components/Footer';
import Header from '../components/Header';

const TRACKING_ID = 'G-ELDWH0N2B5';
ReactGA.initialize(TRACKING_ID);

interface RouteProps extends ReactRouteProps {
  isPrivate?: boolean;
  isAuth?: boolean;
  hasHeader?: boolean;
  hasFooter?: boolean;
  skipValidation?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isAuth = false,
  hasHeader = false,
  hasFooter = false,
  skipValidation = false,
  component: Component,
  ...rest
}) => {

  const user = localStorage.getItem("patoToken") ? { name:"Teste2"} : null;

  const locationPath = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: locationPath.pathname + locationPath.search + locationPath.hash,
    });
  }, [locationPath]);
  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (skipValidation) return <Component />;

        if (isAuth && !user) return <Component />;

        if (isAuth && user)
          return (
            <Redirect
              to={{
                pathname: '/course/do-bug-ao-bounty',
              }}
            />
          );

        if (isPrivate && user)
          return (
            <>
              {hasHeader && <Header />}
              <Component />
              {hasFooter && <Footer />}
            </>
          );

        if (isPrivate && !user)
          return (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: location.pathname },
              }}
            />
          );
        return <LoadingAnimation />;
      }}
    />
  );
};

export default Route;

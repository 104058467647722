import { ValidationError } from 'yup';

interface IErrors {
  [key: string]: string;
}

export function getValidationErrors(err: ValidationError): IErrors {
  const validationErrors: IErrors = {};
  err.inner.forEach(error => {
    if (error.path)
      validationErrors[error.path] = error.message
        .replace('passwordConfirm must be one of the following values:', '')
        .replace(', Ref(password)', '.');
  });
  return validationErrors;
}

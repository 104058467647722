import React, { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';

import { Container } from './styles';

export type IAlertType = 'SUCCESS' | 'ERROR' | 'WARNING';

export type IAlertProps = {
  id: string;
  message: string;
  type: IAlertType;
};

type IAlertComponentProps = {
  time: number;
  alertData: IAlertProps;
};

const AlertComponent: React.FC<IAlertComponentProps> = ({
  alertData: { message, type },
  time,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    let onView = true;
    setTimeout(() => {
      if (onView) setIsVisible(false);
    }, time * 2000);
    return () => {
      onView = false;
    };
  }, [time]);

  const handleClick = () => {
    setIsVisible(false);
  };

  return (
    <Container type={type} duration={`${time}s`} isVisible={isVisible}>
      <p>{message}</p>
      <button type="button" onClick={handleClick}>
        <FiX size={22} />
      </button>
    </Container>
  );
};

export default AlertComponent;

import {
  LastActivityItemContainer,
  LastActivityItemHeader,
  LastActivityType,
  LastActivityTitle,
  LastActivityModule,
} from './styles';

type Props = {
  activity: IFinalClass;
};

type IActivityText = {
  [key in IClassStyles]: string;
};

const activityText: IActivityText = {
  CLASS: 'videoaula',
  FLAG: 'flag',
  PRACTICE: 'prática',
  QUIZ: 'quiz',
};

export const LastActivityItem: React.FC<Props> = ({ activity }) => {
  const url = `/course/${activity.course.id}/class/${activity.id}`;
  const activityTitle = activity.title;
  const moduleName = activity.module.name;

  return (
    <LastActivityItemContainer to={url} title={activityTitle}>
      <LastActivityItemHeader>
        <LastActivityType data-type={activity.type} title={activity.type}>
          {activityText[activity.type]}
        </LastActivityType>
        <LastActivityTitle>{activityTitle}</LastActivityTitle>
      </LastActivityItemHeader>
      <LastActivityModule title={moduleName}>{moduleName}</LastActivityModule>
    </LastActivityItemContainer>
  );
};

import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useClass } from '../../../hooks/Class';
import { pufiryHtmlText } from '../../../util/purifyHtmlText';
import { H1, H2, P } from '../../Atoms/Texts';
import Button from '../../Button';
import { ToggleClassList } from '../ClassPageComponents/ToggleClassList';
import DescriptionVideo from '../DescriptionVideo';
import Extra from '../Extra';
import { PaginatedProvider, usePaginated } from '../hooks/Paginated';
import { PaginatedContainer } from '../hooks/Paginated/PaginatedContainer';
import { PaginatedItem } from '../hooks/Paginated/PaginatedItem';
import { Loading } from '../Loading';
import { FlagCustomNextButton } from '../MainFlag/CustomNextButton';
import { PaginatedButtons } from '../MainFlag/styles';
import Section from '../Section';
import { PracticeButtons } from './PracticeButtons';
import PracticeLink from './PracticeLink';

import {
  Content,
  Container,
  ButtonsContainer,
  DescriptionButtonContainer,
} from './styles';

type IMainPractice = {
  classItem: IPracticeData;
};

const MainPractice: React.FC<IMainPractice> = ({ classItem }) => {
  const { completeActivity, activitiesProgression } = useClass();
  const { nextPage, prevPage, pageIdList } = usePaginated();

  const handleClick = () =>
    completeActivity({
      state: 'COMPLETED',
      currentDuration: 0,
    });

  if (!classItem.practice) return Loading;

  const isCompleted =
    activitiesProgression.find(item => item.class.id === classItem.id)
      ?.state === 'COMPLETED';

  const hasVideoPage = pageIdList.length > 1;

  return (
    <Container>
      <ToggleClassList />
      <PaginatedContainer>
        {classItem.practice?.videoUrl && (
          <PaginatedItem id="description">
            <DescriptionVideo
              title={classItem.title}
              description={classItem.description}
              url={classItem.practice.videoUrl}
            />
            <DescriptionButtonContainer>
              <FlagCustomNextButton onClick={nextPage} />
            </DescriptionButtonContainer>
          </PaginatedItem>
        )}
        <PaginatedItem id="practice">
          <Content>
            <H1 size={18} weight="500">
              {classItem.title}
            </H1>
            <P
              className="description"
              size={16}
              dangerouslySetInnerHTML={{
                __html: pufiryHtmlText(classItem.description),
              }}
            />
            <H2 size={16} weight="500">
              Instruções
            </H2>
            <P
              className="description"
              size={16}
              dangerouslySetInnerHTML={{
                __html: pufiryHtmlText(classItem.practice.description),
              }}
            />
            <PracticeLink practice={classItem.practice} />
            {classItem.extra.length > 0 && (
              <Section
                text={
                  <H2 size={16} weight="500">
                    Links
                  </H2>
                }
                startsExpanded
              >
                <ul>
                  {classItem.extra.map(extraData => (
                    <Extra extra={extraData} key={extraData.url} />
                  ))}
                </ul>
              </Section>
            )}
            <ButtonsContainer>
              {hasVideoPage && (
                <PaginatedButtons type="button" onClick={prevPage}>
                  <FiChevronLeft size={20} />
                </PaginatedButtons>
              )}
              <Button
                onClick={handleClick}
                isGradient
                size="large"
                colorType="blue"
              >
                {isCompleted ? 'Próxima atividade' : 'Concluir prática'}
              </Button>
            </ButtonsContainer>
          </Content>
        </PaginatedItem>
        <PracticeButtons
          isCompleted={isCompleted}
          handleNextClass={handleClick}
        />
      </PaginatedContainer>
    </Container>
  );
};

interface IMainFlagProps {
  classItem: IPracticeData;
}

export default ({ classItem }: IMainFlagProps): JSX.Element => (
  <PaginatedProvider>
    <MainPractice classItem={classItem} />
  </PaginatedProvider>
);

import styled from 'styled-components';
import { FiSearch } from 'react-icons/fi' 

export const Container = styled.div`
  margin-top: 2.4rem;

  .form {
    display: grid;
    gap: 0.8rem;
    grid-template-areas: 'search search' 'module material';

    @media (min-width: 1024px) {
      grid-template-areas: 'search module material';
      grid-auto-columns: 73.7% 10% 15%;
      justify-self: stretch;
      align-self: stretch;
      justify-content: space-beetween;
    }

    .input-search {
      grid-area: search;
    }

    .button-module {
      grid-area: module;
    }

    .button-material {
      grid-area: material;
    }
  }
`
export const ContainerInputForm = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  > button {
    position: absolute;
    z-index: 1;
  }

  [data-name="search"] {
    > div {
      margin: 0;
      > input {
        padding-left: 5.6rem;
      }
    }
  }

`
export const StyledFiSearch = styled(FiSearch)`
  margin-left: 1.6rem;
`
import api from './main.api';

export async function getConnections(): Promise<IAuthEvents> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { items },
    },
  } = (await api.get('/student/auth/events', {
    headers: { authorization },
  })) as {
    data: { data: { items: IAuthEvents } };
  };
  return items;
}

export async function validateMeuIdCredentials(data: {
  code: string;
  code_verifier: string;
}): Promise<void> {
  const authorization = localStorage.getItem('patoToken');

  await api.post('/student/verification/webhook/meuid', data, {
    headers: { authorization },
  });
}

export async function labAuthentication(): Promise<string> {
  const authorization = localStorage.getItem('patoToken');

  const {
    data: {
      data: { userLabToken },
    },
  } = await api.get<{ data: { userLabToken: string } }>('/auth/lab', {
    headers: { authorization },
  });
  return userLabToken;
}

import * as Yup from 'yup';

export type IAddressData = {
  state: string;
  city: string;
  postalCode: string;
  neighborhood: string;
  street: string;
  number: string;
  additional: string;
};

export const accountAddressSchema = Yup.object().shape({
  address: Yup.object().shape({
    state: Yup.string()
      .required('Campo obrigatório')
      .min(2, 'Coloque a sigla do estado'),
    city: Yup.string().required('Campo obrigatório'),
    postalCode: Yup.string()
      .required('Campo obrigatório')
      .min(4, 'Muito curto')
      .matches(/(\d{5}-\d{3})|(\d+)/, 'Cep incompleto'),
    street: Yup.string().required('Campo obrigatório'),
    number: Yup.string().required('Campo obrigatório'),
  }),
});

type IValidation = (data: {
  address: IAddressData;
}) => Promise<Yup.Asserts<typeof accountAddressSchema>>;

export const validateAccountAddressData: IValidation = async data =>
  accountAddressSchema.validate(data, { abortEarly: false });

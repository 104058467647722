import React from 'react';
import { IconType, IconBaseProps } from 'react-icons';

import { useTheme } from 'styled-components';

import { Container } from './styles';

type IColorTupe =
  | 'yellow'
  | 'red'
  | 'green'
  | 'pastelGreen'
  | 'blue'
  | 'grey'
  | 'black'
  | 'purple';

interface IconProps extends IconBaseProps {
  IconComponent: IconType;
  isntGradient?: boolean;
  isFilled?: boolean;
  color?: IColorTupe;
}

const Icon: React.FC<IconProps> = ({
  IconComponent,
  color,
  isFilled,
  isntGradient,
  ...rest
}) => {
  const { iconColors, iconSolidColors } = useTheme();
  const randomId = Math.random();
  const firstColor = (() => {
    if (isntGradient && color && iconSolidColors[color])
      return iconSolidColors[color];
    return color ? iconColors[color].firstColor : '#F2EBFF';
  })();
  const secondColor = (() => {
    if (isntGradient && color && iconSolidColors[color])
      return iconSolidColors[color];
    return color ? iconColors[color].secondColor : '#F2EBFF';
  })();
  return (
    <Container>
      <svg width="0" height="0">
        <linearGradient
          id={`${randomId}`}
          x1="12.8917"
          y1="7.57877"
          x2="12.8917"
          y2="21.9488"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-45)"
        >
          <stop stopColor={firstColor} offset="0%" />
          <stop stopColor={secondColor} offset="100%" />
        </linearGradient>
      </svg>
      <IconComponent
        style={{
          stroke: `url(#${randomId})`,
          fill: isFilled ? `url(#${randomId})` : 'none',
        }}
        strokeWidth={rest.strokeWidth || 1}
        {...rest}
      />
    </Container>
  );
};

export default Icon;

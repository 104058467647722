import styled from 'styled-components';

export const Page = styled.div`
  max-width: 1440px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 100px;
  hr {
    width: 100%;
    height: 1px;
    margin: 33px 0;
    opacity: 0.1;
    background: ${props => props.theme.colors.white};
  }
  h2 {
    letter-spacing: 0.01em;
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 100px auto 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0 4.8rem;
  
  @media screen and (max-width: 768px) {
    padding: 0 2.4rem;
  }

  @media screen and (max-width: 425px) {
    padding: 0 1.6rem;
  }
`;
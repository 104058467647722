import styled from "styled-components";

export const LabContainer = styled.main`
  max-width: 56.3rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  height: 100vh;

  > p {
    text-align: center;
    margin-bottom: 4rem;

    mark {
      background: none;
      color: #ff8f8f;
    }
  }

  > small {
    color: #cecece;
  }
`;

export const ButtonsContainer = styled.div`
  margin: 2.4rem 0 4rem;

  gap: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & + small {
    color: #9d9d9d;
  }
`;

import React from 'react';
import { TopicListItem } from '@/components/TopicListItem';

import { Ul } from './styles';

type ILastClassTopicsListProps = {
  topics: IForumTopic[];
};

export const LastClassTopicsList: React.FC<ILastClassTopicsListProps> = ({
  topics,
}) => {
  return (
    <Ul>
      {topics.length === 0 ? (
        <p>Sem tópicos por enquanto.</p>
      ) : (
        topics.map(topic => <TopicListItem key={topic.id} topic={topic} />)
      )}
    </Ul>
  );
};

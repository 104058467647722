import styled from "styled-components";

export const ToTopButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 35rem;

  p {
    line-height: 1.4em;
    color: ${({ theme: { newColors } }) => newColors.white['500']}
  }

  button {
    width: 4rem;
    height: 4rem;
    display: grid;
    place-items: center;
    margin-left: 2.4rem;
    border-radius: 0.4rem;
    background: ${({ theme: { newColors } }) => newColors.black['500']};
    border: 1px solid ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.1 })};
  }
`;

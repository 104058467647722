import { Form } from "@unform/web";
import styled from "styled-components";

export const Container = styled(Form)`
  display: flex;

  button {
    height: 4.7rem;
    width: 4.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.newColors.black['900']};
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0 4px 4px 0;
    position: relative;
    top: 2.9rem;
  }
  input {
    border-radius: 4px 0 0 4px;
  }
`;

import styled, { keyframes } from 'styled-components';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

export const StyledItem = styled(AccordionPrimitive.Item)`
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledHeader = styled(AccordionPrimitive.Header)`
  background-color: ${props => props.theme.newTheme.black['30']};
  border: 1px solid ${props => props.theme.newTheme.border['20']};
  display: flex;
  border-radius: 0.4rem;
  transition: all 300ms ease;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.newTheme.black['40']};
  }

  &[hidden] {
    display: none;
  }
`;

export const StyledTrigger = styled(AccordionPrimitive.Trigger)`
  display: flex;
  width: 100%;
  cursor: default;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${props => props.theme.newTheme.white['50']};
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state="closed"] {
    &::after {
      transform: rotate(180deg);
    }
  }
`;

export const StyledContent = styled(AccordionPrimitive.Content)`
  overflow: hidden;
  margin-top: 1.6rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.8rem;
  width: calc(100% - 4.0em);
  align-items: flex-start;

  &[data-state="open"] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state="closed"]:not([hidden]) {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1) 200ms;
  }
`;

export const Container = styled.div`
  margin-top: 1.6rem;
  display: flex;
  justify-content: flex-end;

  .customAccordion {
    width: calc(100% - 4.8rem);
  }
`;

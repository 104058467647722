import styled from "styled-components";

export const PaginatedContaierStyled = styled.div`
  width: 100%;
`;

type IProps = {
  page: number;
}

export const PaginatedContent = styled.div`
  margin: 0;
  width: 100%;
  overflow: hidden;
`;

export const PageSliderContainer = styled.ul<IProps>`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;
  flex-wrap: nowrap;
  transition: all .8s;
  transform: translateX(calc(-${props => props.page} * 100% - ${props => props.page} * 1rem));
`;

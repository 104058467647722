import styled from "styled-components";
import { Editable as SlateEditable } from "slate-react";

type IEditorWrapperProps = {
  hasError: boolean;
}

export const EditorWrapper = styled.div<IEditorWrapperProps>`
  margin-top: 2.4rem;
  background-color: ${({ theme }) => theme.newTheme.black['20']};
  border: 1px solid ${({ theme, hasError }) => hasError ? theme.newTheme.red['20'] : 'rgba(255, 255, 255, 0.2)'};
  border-radius: 4px;
  outline: 0;

  &:focus-within {
    border-color: rgba(255, 255, 255, 0.2);
  }

  .text-editor {
    top: 1
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    padding: 1.6rem;
    border-radius: 4px;

    p {
      font-size: 1.4rem;
    }

    h2 {
      font-size: 2.4rem;
      font-weight: 600;

      span, strong, code {
        font-size: inherit;
      }

      strong {
        font-weight: 700;
      }
    }

    > ul {

      li {
        font-size: 1.4rem;
        line-height: 2.2rem;

        &:before {
          content: '';
          display: inline-block;
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.newTheme.white['10']};
          margin-right: 0.8rem;
        }
      }
    }

    blockquote {
      font-size: 1.4rem;
      position: relative;
      padding-left: 1.2rem;

      &:before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 0.4rem;
        height: 100%;
        border-radius: 2px;
        background-color: ${({ theme }) => theme.newTheme.white['10']};
        margin-right: 0.8rem;
        left: 0;
        bottom: 1px;
      }
    }

    code {
      font-size: 1.4rem;
      font-family: 'Roboto Mono', monospace;
      background-color: ${({ theme }) => theme.newTheme.white['60']};
      color: ${({ theme }) => theme.newTheme.black['10']};
    }

    em * {
      font-size: 1.4rem;
      font-style: italic;
    }

    strong {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
`;

export const Editable = styled(SlateEditable)`
  min-height: 16rem !important;
  max-height: 32rem;
  overflow-y: auto;

  &::-webkit-scrollbar{
    width: 20px;
  }

  &::-webkit-scrollbar-track{
    background-color: none;
  }

  &::-webkit-scrollbar-thumb{
    background-color: ${props => props.theme.colors.lightGrey};
    border-radius: 20px;
    height: 60px;
    background-clip: content-box;
    border: 8px solid transparent;
  }

  &::-webkit-scrollbar-button{
    background: none;
    height: 5px;
  }

  &::-webkit-scrollbar-corner{
    background: none;
  }

  & span[data-slate-placeholder="true"] {
    color: ${({ theme }) => theme.newTheme.white['50']};
    opacity: 1 !important;
    top: 15px !important;
  }
`;

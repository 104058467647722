import styled from "styled-components";

export const TopicListItemContainer = styled.li`
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.newTheme.black['40']};
  }

  & + li {
        border-top: 1px solid ${({ theme }) => theme.newTheme.border['10']};
    }

  a {
    display: flex;
    align-items: center;
    padding: 1.6rem 2.4rem;
    gap: 1.6rem;

    img {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        object-fit: contain;
    }

    div.topic-content {
        display: flex;
        flex-direction: column;
        gap: .4rem;

        time {
            color: ${({ theme }) => theme.newTheme.white['50']};
            font-size: 1.2rem;
            font-weight: 500;
            text-transform: uppercase;
        }

        h4 {
            color: ${({ theme }) => theme.newTheme.white['40']};
            font-size: 1.4rem;
            font-weight: 600;
            text-transform: none;
            max-height: 4.2rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    div.topic-impressions {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: .8rem;
        align-items: flex-start;

        div {
            display: flex;
            align-items: center;
            gap: .8rem;
            width: 3.8rem;

            font-size: 1.2rem;
            font-weight: 500;

            span {
                color: ${({ theme }) => theme.newTheme.white['50']};
            }
        }
    }
  }
`

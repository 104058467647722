import { FiArrowRight } from 'react-icons/fi';
import { useClass } from '../../../../hooks/Class';
import { Small } from '../../../Atoms/Texts';
import { NextModuleButtonContainer } from './styles';

export const NextModuleButton: React.FC = () => {
  const { selectedModuleItem, setSelectedModuleItem, modules } = useClass();

  const handleClick = () => {
    const possibleModules = modules.filter(
      moduleItem => moduleItem.classes.length > 0,
    );
    const actualModuleIndex = possibleModules.findIndex(
      item => item.module.id === selectedModuleItem,
    );
    if (actualModuleIndex >= 0 && possibleModules[actualModuleIndex + 1])
      setSelectedModuleItem(possibleModules[actualModuleIndex + 1].module.id);
  };

  return (
    <NextModuleButtonContainer>
      <button
        type="button"
        onClick={handleClick}
        data-cy="activity.classlist.next-module-button"
      >
        <Small>Próximo módulo</Small>
        <FiArrowRight size={20} />
      </button>
    </NextModuleButtonContainer>
  );
};

import styled from 'styled-components';
import { Slide } from '../PaginatedActivity/styles';
import { ToggleClassListButton } from '../ClassPageComponents/ToggleClassList/styles';

export const Container = styled.div`
  margin: 8rem auto 1.6rem;
  padding: 0rem 1rem;
  width: 100%;
  max-width: 960px;
  z-index: 0;

  display: flex;
  flex-direction: column;
  
  ${ToggleClassListButton} {
    margin: 0 0 3.2rem;
  }

  > h1 {
    line-height: 130%;
    /* identical to box height, or 26px */

    letter-spacing: 0.01em;

    color: ${props => props.theme.newColors.white['900']};
  }

  .description {
    margin: 1.6rem 0 4rem;

    line-height: 150%;
    /* or 24px */

    text-align: justify;

    color: ${({ theme: { newColors } }) => newColors.white['700']};
  }

  h2 {
    line-height: 150%;
    /* identical to box height, or 27px */

    text-align: justify;
    color: ${props => props.theme.newColors.white['900']};
  }

  hr {
    width: 100%;
    height: 1px;
    opacity: 0.1;
    background: ${props => props.theme.colors.white};
  }

  ${Slide} {
    padding: 0 1rem;
  }

  @media screen and (max-width: 1024px) {
    hr + form {
      padding: 0 20px;
    }

    details {
      > * {
        padding: 0 20px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > button {
    margin: 4rem 0;
    align-self: flex-end;
  }
`;

export const DescriptionButtonContainer = styled.div`
  margin-top: 1.6rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  gap: 1.6rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 6.7rem;

  button {
    height: 4.8rem;
    padding: 0;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
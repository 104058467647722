import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
  h1 {
    margin: 0;
    /* H5 20 Semibold */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* identical to box height, or 26px */

    text-align: justify;
    letter-spacing: 0.01em;

    color: rgba(255, 255, 255, 0.8);
  }

  p {
    margin: 1.6rem 0 4rem;
    /* P 16 Regular */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    line-height: 150%;
    /* or 24px */
    color: rgba(255, 255, 255, 0.8);
  }

  iframe {
    width: 100%;
    height: 100%;
    max-width: 1600px;
    max-height: 860px;
    aspect-ratio: 1.86 / 1;
    z-index: 1;
  }
`;

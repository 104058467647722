import { differenceInWeeks } from 'date-fns';
import { useMemo } from 'react';

type Props = {
  modules?: IGroupedClasses[];
};

type IUseLastActivities = {
  activities?: IFinalClass[];
  isLoading: boolean;
};

const maxWeeksDifference = 4;

const recentActivitiesFilter = (classItem: IFinalClass): boolean =>
  Math.abs(differenceInWeeks(new Date(classItem.createdAt), new Date())) <=
  maxWeeksDifference;

const sortActivitiesByReverseDate = (
  classA: IFinalClass,
  classB: IFinalClass,
) =>
  new Date(classA.createdAt).getTime() - new Date(classB.createdAt).getTime();

export const useLastActivities = ({ modules }: Props): IUseLastActivities => {
  const activities = useMemo(
    () =>
      modules
        ?.map(module => module.classes.filter(recentActivitiesFilter))
        .flat()
        .sort(sortActivitiesByReverseDate),
    [modules],
  );

  return { activities, isLoading: !activities };
};

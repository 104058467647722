import styled from 'styled-components';

export const ContainerHeader = styled.header`
  margin-top: 0.8rem;
  padding-bottom: 2.4rem;
  border-bottom: 1px solid ${props => props.theme.newTheme.border['20']};
  &[data-hide-border="true"] {
    border-bottom-color: transparent;
  }

  > span {
    text-transform: uppercase;
    color: ${props => props.theme.newTheme.blue['20']};
    font-size: 1.4rem;
    line-height: 150%;
    letter-spacing: 0.1em;
  }

  > h4 {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 150%;
    color: ${props => props.theme.newTheme.white['10']};
  }

  > p {
    margin-top: 1.6rem;
    font-size: 1.6rem;
    font-weight: normal;
    color: ${props => props.theme.newTheme.white['40']};
  }
`
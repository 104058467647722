import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL,
});

export default api;

const requestMapper = new Map<string, number>();

api.interceptors.request.use(requestInfo => {
  const key = `${requestInfo.url}-${window.location.pathname}`;
  requestMapper.set(key, (requestMapper.get(key) || 0) + 1);
  localStorage.setItem(
    'requestMapper',
    JSON.stringify(Array.from(requestMapper)),
  );

  return requestInfo;
});

api.interceptors.response.use((response) => response, (error) => {
  const statusCode = error.response ? error.response.status : null;
  if(statusCode == 429) { 
    localStorage.clear();
    location.href="/account/signin"
  }
  throw error;
});

/* eslint-disable no-param-reassign */
export const groupClassByModule = (
  list: IModifiedClass[],
): { [value: string]: IGroupedClasses } =>
  list.reduce((resultValue, item) => {
    resultValue[item.moduleId] = {
      module: item.module,
      classes: resultValue[item.moduleId]?.classes || [],
    };
    resultValue[item.moduleId].classes.push(item);
    return resultValue;
  }, {} as { [value: string]: IGroupedClasses });

export const compareClasses = (
  first: IFinalClass,
  second: IFinalClass,
): number => {
  if (first.sortId && second.sortId) return first.sortId - second.sortId;

  return first.createdAt > second.createdAt ? 1 : -1;
};

import { FiPlay } from 'react-icons/fi';
import {
  NextActivityCardContainer,
  NextActivityNamesContainer,
  NextActivityModuleText,
  NextActivityClassText,
  NextActivityLinkButton,
  PlayIconContainer,
} from './styles';

type Props = {
  activity?: IFinalClass;
  currentModule?: IModule;
  courseId?: string | undefined;
};

export const NextActivityCard: React.FC<Props> = ({
  activity,
  currentModule,
  courseId,
}) => {

  const classId = (activity && activity?.id && activity?.id.length > 5) ? activity.id : (courseId == 'do-bug-ao-bounty' ? '382d5e6e-1c97-4aed-b826-3a4ad49c98d9' :  '4473d824-ea65-43f4-b5a1-d655153eab2b');

  const classUrl = `/course/${courseId}/class/${classId}`;
  const moduleName = currentModule?.name || "";
  const className = activity?.title;

  return (
    <NextActivityCardContainer>
      <NextActivityNamesContainer>
        <NextActivityModuleText>
          {className && className.length > 1 ? 'Você está no módulo' : 'Você ainda não iniciou o curso. Que tal começar agora? 🚀'} <b title={moduleName}>{moduleName}</b>
        </NextActivityModuleText>
        <NextActivityClassText>{className && className.length ? "- Aula: "+className : ""}</NextActivityClassText>
      </NextActivityNamesContainer>
      <NextActivityLinkButton to={classUrl} title={className}>
        <PlayIconContainer>
          <FiPlay size={16} />
        </PlayIconContainer>
        {className ? 'Continuar de onde parou' : 'Iniciar sua jornada!'}
      </NextActivityLinkButton>
    </NextActivityCardContainer>
  );
};

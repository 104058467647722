import { Descendant, Element, Text } from 'slate';

const WHITE_SPACE_AT_START_REGEX = /^\s+/;
const WHITE_SPACE_AT_END_REGEX = /\s+$/;
const AVAILABLE_FORMATS_TO_STYLE = [
  'paragraph',
  'heading',
  'list-item',
  'block-quote',
];
const STYLES = {
  bold: '**',
  italic: '*',
  code: '`',
};

const isBold = (child: Text) => !!child.bold;
const isItalic = (child: Text) => !!child.italic;
const isCode = (child: Text) => !!child.code;

type AddSymbolProps = {
  text: string;
  symbolType: keyof typeof STYLES;
  nextElement: Text | undefined;
  prevElement: Text | undefined;
};

const addSymbol = ({
  text,
  symbolType,
  nextElement,
  prevElement,
}: AddSymbolProps) => {
  let childText = text;

  if ((prevElement && !prevElement[symbolType]) || !prevElement) {
    childText = STYLES[symbolType].concat(childText);
  }

  if ((nextElement && !nextElement[symbolType]) || !nextElement) {
    childText = childText.concat(STYLES[symbolType]);
  }

  return childText;
};

const addStyle = (node: Element) => {
  const { children, type } = node;

  if (AVAILABLE_FORMATS_TO_STYLE.includes(type)) {
    return children.reduce((acc, child, index, array) => {
      const prevElement = array[index - 1] as Text | undefined;
      const nextElement = array[index + 1] as Text | undefined;

      const { text } = child;

      const matchStart = text.match(WHITE_SPACE_AT_START_REGEX);
      const matchEnd = text.match(WHITE_SPACE_AT_END_REGEX);
      const whiteSpaceAtStart = matchStart ? matchStart[0] : '';
      const whiteSpaceAtEnd = matchEnd ? matchEnd[0] : '';

      let childText = text.trim();

      if (isBold(child)) {
        childText = addSymbol({
          text: childText,
          symbolType: 'bold',
          nextElement,
          prevElement,
        });
      }
      if (isItalic(child)) {
        childText = addSymbol({
          text: childText,
          symbolType: 'italic',
          nextElement,
          prevElement,
        });
      }
      if (isCode(child)) {
        childText = addSymbol({
          text: childText,
          symbolType: 'code',
          nextElement,
          prevElement,
        });
      }

      // add white space at start and end if it exists in the original text
      childText = whiteSpaceAtStart.concat(childText);
      childText = childText.concat(whiteSpaceAtEnd);

      // parse all '\n' to '\' + '\n'
      childText = childText.replace(/\n/g, '\\\n');

      return text.trim() === '' ? acc.concat(text) : acc.concat(childText);
    }, '');
  }

  throw new Error(
    `Invalid node type. It must be one of the following:
    ${AVAILABLE_FORMATS_TO_STYLE.join(', ')}`,
  );
};

export const serialize = (data: Descendant[]): string => {
  const finalMarkdown = data.reduce((acc, node) => {
    const { type, children } = node as Element;
    let sentence = '';

    switch (type) {
      case 'heading':
        sentence = addStyle(node as Element);

        return acc.concat(`# ${sentence}`, '\n\n');

      case 'block-quote':
        sentence = addStyle(node as Element);

        return acc.concat(`> ${sentence}`, '\n\n');

      case 'bulleted-list':
        // 'bulleted-list' type has children: CustomElement<'list-item'>[]
        children.forEach(child => {
          const listItem = addStyle(child as unknown as Element);
          sentence = sentence.concat(`- ${listItem}`, '\n');
        });

        return acc.concat(sentence, '\n\n');

      // The element is 'paragraph'
      default:
        sentence = addStyle(node as Element);
        return acc.concat(sentence, '\n\n');
    }
  }, '');

  return finalMarkdown;
};

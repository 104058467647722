import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 0 1rem 2rem;
  height: 100%;

  h3 {
    margin: 1.6rem 0 4rem;
  }

  ul {
    flex: 1;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  > button {
    margin-top: 4rem;
  }


  @media screen and (max-width: 768px) {
    padding: 0 1rem 5rem;
  }
`;

export const Content = styled.div`
  gap: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FooterButton = styled.footer`
  left: 0;
  bottom: 0;
  z-index: 5;
  padding: 1.6rem;
  position: fixed;
  width: 100vw;
  background: ${props => props.theme.newTheme.black['10']};

  gap: 1.6rem;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;

  button {
    flex: 1;
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const FooterRow = styled.div`
  flex: 1;
  gap: 1.6rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  button {
    height: 4.8rem;
  }
`;

export const NavigateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.newTheme.black['40']};
  border: 1px solid ${props => props.theme.newTheme.border['10']};
  border-radius: 4px;
`;

export const PaginatedButtons = styled.button`
  width: 4.8rem;
  height: 4.8rem;

  display: grid;
  place-items: center;
  border-radius: 50%;

  background: ${({ theme: { newColors } }) => newColors.black['500']};
  border: 1px solid ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.1 })};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25), inset 0px 5px 5px -20px rgba(255, 255, 255, 0.02);
  color: ${({ theme: { newColors } }) => newColors.white['900']};

  &:disabled {
    filter: brightness(.8);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ConfirmButtonContainer = styled.div`
  margin-top: 3rem;
  align-self: flex-end;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

import styled from "styled-components";

export const NextModuleButtonContainer = styled.footer`
  padding: .8rem;
  max-height: 8rem;
  background: ${props => props.theme.newTheme.black['30']};
  display: flex;
  border-top: 1px solid ${props => props.theme.border['10']};

  button {
    padding: .8rem 1.6rem .8rem 1.2rem;
    flex: 1;
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: space-between;
    height: 4.8rem;

    background: ${props => props.theme.newTheme.black['30']};
    border: 1px solid ${props => props.theme.newTheme.border['10']};
    border-radius: 4px;
    position: relative;

    &:active {
      top: 1px;
    }
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
  }
`;

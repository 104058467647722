import styled from 'styled-components';
import * as Dialog from '@radix-ui/react-dialog';

export const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 999;
`;

export const DialogTitle = styled(Dialog.Title)`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.newTheme.white['10']};
`;

export const DialogDescription = styled(Dialog.Description)`
  margin-top: 2.4rem;
  display: flex;
  gap: 4.8rem;

  & h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.newTheme.white['40']};
    text-transform: uppercase;
  }

  & p {
    margin-top: 0.4rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: ${({ theme }) => theme.newTheme.white['10']};
  }
`;

export const DialogContent = styled(Dialog.Content)`
  background-color: ${({ theme }) => theme.newTheme.black['30']};
  border-radius: 0.4rem;

  padding: 3.2rem 2.4rem;
  max-width: 76.6rem;
  width: 100%;

  display: flex;
  flex-direction: column;

  position: relative;

  footer {
    display: flex;
    align-items: center;
    margin-top: 1.2rem;
    padding-top: 1.2rem;

    border-top: 1px solid ${({ theme }) => theme.newTheme.black['60']};

    .error-message {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.newTheme.red['20']};
    }

    button {
    padding: 0 3.2rem;
    margin-left: auto;

    font-size: 1.4rem;
    font-weight: 600;
    line-height: 4.8rem;

    color: ${({ theme }) => theme.newTheme.black['30']};
    background-color: ${({ theme }) => theme.newTheme.blue['20']};

    border-radius: 4px;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }

    &:disabled {
      filter: brightness(0.5);
      cursor: not-allowed;
    }

    &[disabled='true']:hover {
      filter: initial;
    }
  }
  }

  & .close-button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  @media (max-width: 600px) {
    height: 100vh;
    justify-content: center;
  }
`;

type IFormProps =  {
  titleError: string;
};

export const Form = styled.form<IFormProps>`
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;

  label {
    font-size: 1.4rem;
    font-weight: 400;
    color: ${({ theme }) => theme.newTheme.white['10']};

    display: flex;
    flex-direction: column;

    input {
      margin-top: 0.4rem;
      padding: 1.2rem 1.6rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: ${({ theme }) => theme.newTheme.white['30']};
      background-color: ${({ theme }) => theme.newTheme.black['20']};
      outline: 0;
      border-radius: 4px;
      border: 1px solid ${({ theme, titleError }) => titleError ? theme.newTheme.red['20'] : 'rgba(255, 255, 255, 0.2)'};

      &::placeholder {
        color: ${({ theme }) => theme.newTheme.white['50']};
      }

      &:focus {
        border-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .error-message {
    font-size: 1rem;
    color: ${({ theme }) => theme.newTheme.red['20']};
    margin-top: 0.4rem;
  }
`;

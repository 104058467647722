import { LoadingCircle } from './styles';

const GreyCircleLoading = (): JSX.Element => (
  <LoadingCircle viewBox="0 0 100 100" fill="none">
    <clipPath id="clip">
      <path d="M 50 0 a 50 50 0 0 1 0 100 40 40 0 0 1 0 -105 v 8 a 40 40 0 0 0 0 94 40 40 0 0 0 0 -94" />
    </clipPath>

    <foreignObject x="0" y="0" width="100" height="100" clipPath="url(#clip)">
      <div className="gradient" />
    </foreignObject>
  </LoadingCircle>
);

export default GreyCircleLoading;

import styled from "styled-components";

export const SwitcherLabel = styled.label`
  gap: 1.6rem;
  display: flex;
  align-items: center;
`;

export const SwitcherInput = styled.input`
  appearance: none;
  display: none;
`;

export const SwithcerIndicator = styled.div`
  position: relative;
  width: 4.5rem;
  height: 2.4rem;
  border-radius: 17.5px;
  background: ${props => props.theme.newTheme.border['30']};
  ${SwitcherInput} + &::after {
    position: absolute;
    content: ' ';
    width: 2rem;
    height: 2rem;
    top: 50%;
    left: 50%;
    transition: .5s;

    background: ${props => props.theme.newTheme.black['10']};
    border-radius: 50%;
    transform: translate(-100%, -50%);
  }
  ${SwitcherInput}:checked + &{
    background: ${props => props.theme.newTheme.blue['20']};
    &::after {
      transform: translate(0%, -50%);
    }
  }
`;

export const SwitcherText = styled.small`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  color: ${props => props.theme.newTheme.white['40']};
`;

import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Container as Icon } from '../Icon/styles';

interface IContainerProps {
  isFull?: boolean;
}

export const ToggleClassListButton = styled.button<IContainerProps>`
  position: relative;
  transition: ${({ isFull }) => isFull ? 'all 1s .1s' : 'all .75s .1s'};

  left: ${({ isFull }) => isFull ? '30rem' : '4.6rem'};
  align-self: flex-end;


  background: ${({ theme: { newColors } }) => newColors.black['600']};

  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 4px;

  > div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 1024px){
    display: none;
  }
`;

export const Container = styled.aside<IContainerProps>`
  position: sticky;
  left: .8rem;
  padding-top: 2.4rem;

  transition: all 1s;

  height: ${props => props.isFull ? `calc(100vh - 100px)` : `0`};
  min-width: ${props => props.isFull ? 36 : 0}rem;
  width: ${props => props.isFull ? 36 : 0}rem;
  margin: 90px 0 10px;
  z-index: 2;

  background: ${props => props.theme.newColors.black['900']};

  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  border-radius: 8px;

  small {
    margin-left: 14px;
  }

  nav {
    width: 100%;
    overflow: hidden;
  }

  @media screen and (max-width: 800px) {
    position: fixed;
    max-width: 100%;
    left: 0;
  }
`;


type IColorTupe =
  | 'yellow'
  | 'red'
  | 'green'
  | 'pastelGreen'
  | 'grey'
  | 'black'
  | 'purple';

type LinkProps = {
  colorType: IColorTupe;
  isActive?: boolean;
};

export const Link = styled(RouterLink) <LinkProps>`
  background: none;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  min-width: 202px;
  text-decoration: none;
  height: 6.4rem;

  small {
    color: ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.6 })} !important;
  }

  ${Icon} svg {
    stroke: ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.6 })} !important;
  }

  :hover {
    filter: brightness(.8);
  }

  :active {
    border-color: ${({ theme, colorType }) => theme.colors[colorType]}
  }

  ${({ isActive, theme: { colors, newColors }, colorType: color }) => isActive && css`
    background-color: ${newColors.black['600']};

    border-color: ${colors[color]};
    ${Icon} svg {
      stroke: ${newColors.white['900']} !important;
    }

    small {
      color: ${newColors.white['900']} !important;
    }

    :hover {
      border-color: ${newColors.white['900']};
    }
`}
`;

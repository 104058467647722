import styled from "styled-components";

export const ClassPaginationContainer = styled.div`
  margin: 1.6rem 0;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  h2 {
    display: flex;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 150%;

    text-align: center;

    color: ${props => props.theme.newTheme.white['10']};
  }
`;

export const NavigationButton = styled.button`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4.8rem;
  height: 4.8rem;
  border: 4px;
  background: ${props => props.theme.newTheme.black['30']};
  border: 1px solid ${props => props.theme.newTheme.border['10']};
  border-radius: 0.4rem;
`;

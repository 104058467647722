import styled, { keyframes } from 'styled-components';

const detailsShow = keyframes`
  from {
    height: 0;
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    height: 100%;
  }
`;

export const Container = styled.details`
  & + &,
  hr + & {
    margin-top: 0;
  }

  &[open] > *:not(summary) {
    animation: ${detailsShow} .8s ease;
  }

  &[open] .icon {
    transform: rotate(180deg);
  }

  width: 100%;

  > summary {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    font-size: 1rem;
    min-height: 5em;
  }

  > div {
    width: 100%;
    margin: 10px 0 20px;
  }
`;

import styled, { keyframes } from "styled-components";

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

export const LoadingCircle = styled.svg`
  height: 64px;
  width: 64px;
  animation: ${rotate} 1s infinite linear;

  .gradient {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-image: conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 255, 0) 0deg, #FFFFFF 320deg);
  }
`;

import React from 'react';

import { Container } from './styles';

const LoadingAnimation: React.FC = () => (
  <Container
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle
        cy="12"
        cx="12"
        r="10"
        strokeWidth="2"
        stroke="url(#teste_42)"
        fill="none"
      />
      <circle
        cy="12"
        cx="12"
        r="10"
        strokeWidth="2"
        stroke="url(#resposta_42)"
        fill="none"
      />
    </g>
    <defs>
      <linearGradient
        id="teste_42"
        x1="0"
        y1="0"
        x2="24"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="rgba(255, 255, 255, 0.2)" />
      </linearGradient>
      <linearGradient
        id="resposta_42"
        x1="0"
        y1="0"
        x2="24"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFFFFF" />
      </linearGradient>
    </defs>
  </Container>
);

export default LoadingAnimation;

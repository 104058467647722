import styled from 'styled-components';


interface ISelectContainer {
  required?: boolean;
}

export const Container = styled.fieldset<ISelectContainer>`
  width: 32.8rem;
  p {
    line-height: 150%;
    ::after {
      ${props => (props.required ? 'content: " *"' : 'content: ""')};
    }
  }
  select {
    height: 47px;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.2 })};
    background: ${props => props.theme.colors.black};
    width: 100%;
    padding: 0 8px;

    margin: 8px 0;
    &:disabled{
      border-style: dashed;
    }
  }
  option {
    background: ${props => props.theme.colors.black};
  }
`;

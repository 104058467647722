import { FiBold, FiCode, FiItalic, FiList } from 'react-icons/fi';
import { FaHeading } from 'react-icons/fa';
import { GoQuote } from 'react-icons/go';
import { useTheme } from 'styled-components';

import { MarkButton } from './components/MarkButton';
import { Container } from './styles';
import { BlockButton } from './components/BlockButton';

export const Toolbar = (): JSX.Element => {
  const {
    newTheme: { white },
  } = useTheme();

  return (
    <Container>
      <ul>
        <li>
          <MarkButton format="bold">
            <FiBold size={16} stroke={white['50']} />
          </MarkButton>
        </li>
        <li>
          <MarkButton format="italic">
            <FiItalic size={16} stroke={white['50']} />
          </MarkButton>
        </li>
        <li>
          <MarkButton format="code">
            <FiCode size={16} stroke={white['50']} />
          </MarkButton>
        </li>
        <li>
          <BlockButton format="heading">
            <FaHeading size={16} fill={white['50']} />
          </BlockButton>
        </li>
        <li>
          <BlockButton format="block-quote">
            <GoQuote size={16} fill={white['50']} />
          </BlockButton>
        </li>
        <li>
          <BlockButton format="bulleted-list">
            <FiList size={16} stroke={white['50']} />
          </BlockButton>
        </li>
        {/* <li>
          <button
            type="button"
            onClick={_ => {
              alert('Not implemented yet :(')
            }}
          >
            <FiLink size={16} stroke={white['50']} />
          </button>
        </li> */}
        {/* <li>
          <button
            type="button"
            onClick={_ => {
              alert('Not implemented yet :(')
            }}
          >
            <FiImage size={16} stroke={white['50']} />
          </button>
        </li> */}
      </ul>
    </Container>
  );
};

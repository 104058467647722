import React, { useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import {
  FiFacebook,
  FiGithub,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
  FiYoutube,
} from 'react-icons/fi';
import { Container, Selected, Option } from './styles';

type INetworkSelectProps = {
  network: IUserSocialType;
  networksAvailable: IUserSocialType[];
  onChange: (value: IUserSocialType) => void;
};

const Disc: {
  [key in IUserSocialType]: IconType;
} = {
  github: FiGithub,
  twitter: FiTwitter,
  youtube: FiYoutube,
  facebook: FiFacebook,
  linkedin: FiLinkedin,
  instagram: FiInstagram,
};

const NetworkSelect: React.FC<INetworkSelectProps> = ({
  networksAvailable,
  onChange,
  network,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    setIsOpen(prev => !prev);
  };

  const closeSelect = () => setIsOpen(false);

  const handleSelect = (value: IUserSocialType) => {
    onChange(value);
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('click', () => {
      closeSelect();
    });
  }, []);

  const Icon = Disc[network];

  return (
    <Container>
      <Selected type="button" onClick={toggleIsOpen}>
        <Icon />
      </Selected>
      {isOpen && (
        <ul>
          {networksAvailable.map(item => (
            <Option key={item} onClick={() => handleSelect(item)}>
              {Disc[item]({})}
            </Option>
          ))}
        </ul>
      )}
    </Container>
  );
};

export default NetworkSelect;

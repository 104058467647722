import styled from "styled-components";

export const Container = styled.header`
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.8rem;
  background-color: ${({ theme }) => theme.newTheme.black['30']};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  ul {
    display: flex;
    gap: 0.6rem;
    align-items: center;

    li {
      position: relative;
      &:nth-child(4), &:nth-child(6), &:nth-child(8) {
        margin-left: 0.9rem;

        &::before {
          content: '';
          position: absolute;
          width: 1px;
          height: 2.4rem;
          background-color: rgba(255, 255, 255, 0.2);
          top: 50%;
          transform: translateY(-50%);
          left: -0.8rem;
        }
      }
    }

    button {
      line-height: 0;
      padding: 8px;
      border-radius: 4px;
      transition: background-color 0.2s;

      &:hover {
        background-color: ${({ theme }) => theme.newTheme.black['60']};
      }
    }
  }
`;

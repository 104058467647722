import {
  IUseProgressByActivity,
  useProgressByActivity,
} from '@/components/CoursePageV2/Grade/hooks/useProgressByActivity';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useHistory, useParams } from 'react-router';
import {
  getClassesFromStudentCourse,
  getCourseProgression,
  updateClassStatus,
  getDetailedClass,
  validateCourse,
  getCourseData,
  getModulesWithClassesFromStudentCourse,
  getModulesFromCourse,
} from '../../services/api';
import { startGetLicense } from '../../services/api/player.api';
import { useAlert } from '../Alert';
import { useAuth } from '../Auth';


type IDrawerOptions = 'classList' | 'settings' | undefined;

type IGroup = {
  id: string;
  title: string;
  hidden?: boolean;
};

type IClassContextData = {
  course: ICourse | undefined;
  classes: IFinalClass[];
  moduleList: IModule[];
  keywords: string[];
  drawerVisible: IDrawerOptions;
  setDrawerVisible: React.Dispatch<React.SetStateAction<IDrawerOptions>>;
  selectedKeywords: string[];
  setSelectedKeywords: React.Dispatch<React.SetStateAction<string[]>>;
  courseId: string;
  selectedClassId: string;
  courseValidatedData: IMyCourseResume | undefined;
  setSelectedClassId: (activityId: string) => void;
  validatePrevActivity: (activityId: string) => boolean;
  completeActivity: (
    data: IActivityProgress,
    classId?: string,
  ) => Promise<void>;
  setSelectedClass: (value: IFinalClass) => void;
  findNextActivity: (lastActivity: string) => boolean;
  findPreviousActivity: (lastActivity: string) => boolean;
  updateProgress: () => void;
  modules: IGroupedClasses[];
  selectedClass: IFinalClass | undefined;
  activitiesProgression: IAcivitiesProgression[];
  findActivityById: (activityId: string) => IAcivitiesProgression | undefined;
  completedActivities: IClassesCompleted;
  allActivities: IClassesDetail;
  selectedModuleItem: string;
  setSelectedModuleItem: React.Dispatch<React.SetStateAction<string>>;
  getNextActivity: (
    lastActivityId: string,
    isFirst?: boolean | undefined,
    type?: IClassStyles | undefined,
  ) => IFinalClass | undefined;
  showLayerClass: boolean;
  toggleShowLayer: () => void;
  setShowLayerClass: React.Dispatch<React.SetStateAction<boolean>>;
  progressionMap?: IUseProgressByActivity;
  classesInGroup: {
    classes: IFinalClass[];
    id: string;
    title: string;
    hidden?: boolean;
  }[];
};

const ClassContext = createContext<IClassContextData>({} as IClassContextData);

const ClassProvider: React.FC = ({ children }) => {
  const { courseId, id } = useParams<{ id: string; courseId: string }>();
  const queryClient = useQueryClient();

  const [showLayerClass, setShowLayerClass] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState('');
  const [selectedModuleItem, setSelectedModuleItem] = useState('');
  const [modules, setModules] = useState<IGroupedClasses[]>([]);
  const [selectedClass, setSelectedClass] = useState<IFinalClass>();
  const [moduleList, setModuleList] = useState<IModule[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] =
    useState<IDrawerOptions>('classList');

  const { data: classes = [] } = useQuery({
    queryKey: ['student-course-classes', courseId],
    queryFn: () => getClassesFromStudentCourse(courseId),
    staleTime: 60000,
    refetchOnMount: 'always',
  });

  const keywords = useMemo(
    () =>
      classes
        .reduce((accKeywords, classItem) => {
          const isSameModule = classItem.module.id === selectedModuleItem;
          return [
            ...(!!classItem.keywords && isSameModule ? classItem.keywords : []),
            ...accKeywords,
          ];
        }, [] as string[])
        .reduce((accKeywords, keywordItem) => {
          if (!accKeywords.includes(keywordItem)) accKeywords.push(keywordItem);
          return accKeywords;
        }, [] as string[]),
    [classes, selectedModuleItem],
  );

  const { data: activitiesProgression = [] } = useQuery({
    queryKey: ['course-progression', `course-progression-${courseId}`],
    queryFn: () => getCourseProgression(courseId),
    staleTime: 60000,
    refetchOnMount: 'always',
  });

  const progressionMap = useProgressByActivity({
    progression: activitiesProgression,
  });

  const classesMap = useMemo(() => {
    const map = new Map<string, IFinalClass>();
    classes.forEach(classItem => map.set(classItem.id, classItem));
    return map;
  }, [classes]);

  const { push } = useHistory();
  const {
    student: { address },
  } = useAuth();
  const { showMessage } = useAlert();

  const { data: course } = useQuery({
    queryKey: ['get-course-data', courseId],
    queryFn: () => getCourseData(courseId),
    staleTime: Infinity,
    refetchOnMount: 'always',
  });

  const { data: courseValidatedData } = useQuery({
    queryKey: ['validate-course', courseId],
    queryFn: () => validateCourse(courseId),
    staleTime: Infinity,
    refetchOnMount: 'always',
  });

  const updateProgress = useCallback(() => {
    getModulesWithClassesFromStudentCourse(courseId).then(setModules);
    getModulesFromCourse(courseId).then(setModuleList);
  }, [courseId]);

  useEffect(() => {
    if (selectedClassId === 'id') return;
    if (modules.length === 0) return;
    if (selectedModuleItem === '') return;

    const moduleIndex = modules.findIndex(
      moduleItem => moduleItem.module.id === selectedModuleItem,
    );

    const findedModules = modules.slice(moduleIndex);

    const findedClass = findedModules.reduce<IFinalClass | undefined>(
      (acc, moduleItem) => {
        if (acc?.type === 'CLASS') return acc;

        const classIndex = moduleItem.classes.findIndex(
          classItem => classItem.id === selectedClassId,
        );
        if (classIndex < 0)
          return moduleItem.classes.find(
            classItem => classItem.type === 'CLASS',
          );
        const slicedClassList = moduleItem.classes.slice(classIndex);
        return slicedClassList.find(classItem => classItem.type === 'CLASS');
      },
      {} as IFinalClass,
    );

    if (!findedClass) return;
    startGetLicense({
      classId: findedClass.id,
      courseId,
    });
  }, [selectedClassId, modules, selectedModuleItem, courseId]);

  useEffect(() => {
    updateProgress();
  }, [updateProgress]);

  const getNextActivity = useCallback(
    (lastActivityId: string, isFirst?: boolean, type?: IClassStyles) => {
      const moduleIndex = modules.findIndex(
        moduleItem => moduleItem.module.id === selectedClass?.module.id,
      );

      const findedModule = modules[moduleIndex];

      if (!findedModule || findedModule.classes.length === 0) return undefined;

      const classIndex = findedModule.classes.findIndex(
        classItem =>
          classItem.id === lastActivityId && (!type || classItem.type === type),
      );

      const destinyClassId = ((): IFinalClass | undefined => {
        if (classIndex >= 0) {
          const hasNextClass = !!findedModule.classes[classIndex + 1];
          if (hasNextClass) return findedModule.classes[classIndex + 1];

          const findedNextModuleIndex = modules.findIndex(
            (moduleItem, index) =>
              !!moduleItem.classes[0] && index > moduleIndex,
          );

          if (findedNextModuleIndex < 0) return undefined;

          const isLastModuleClass =
            classIndex === findedModule.classes.length - 1;
          const hasNextModuleClass =
            !!modules[findedNextModuleIndex].classes[0];

          if (isLastModuleClass && hasNextModuleClass)
            return modules[findedNextModuleIndex].classes[0];
        }

        if (isFirst) return findedModule.classes[0];

        return undefined;
      })();

      return destinyClassId;
    },
    [modules, selectedClass],
  );

  const findNextActivity = useCallback(
    (lastActivityId: string, isFirst?: boolean) => {
      const destinyClassId = getNextActivity(lastActivityId, isFirst);

      if (destinyClassId?.id) {
        push(`/course/${courseId}/class/${destinyClassId.id}`);
        return true;
      }

      return false;
    },
    [push, courseId, getNextActivity],
  );

  const findPreviousActivity = useCallback(
    (lastActivityId: string) => {
      if (classes.length === 0) return false;

      const classIndex = classes.findIndex(
        classItem => classItem.id === lastActivityId,
      );

      if (classIndex === 0) return false;

      if (classes[classIndex - 1]) {
        push(`/course/${courseId}/class/${classes[classIndex - 1].id}`);
        return true;
      }

      return false;
    },
    [classes, push, courseId],
  );

  const validatePrevActivity = useCallback(
    (activityId: string) => {
      const findedActivity = activitiesProgression.find(
        activity => activity?.class?.id === activityId,
      );

      return findedActivity ? findedActivity?.state === 'COMPLETED' : false;
    },
    [activitiesProgression],
  );

  const findActivityById = useCallback(
    (activityId: string) =>
      activitiesProgression.find(
        activityProgressionItem =>
          activityProgressionItem?.class?.id === activityId,
      ),
    [activitiesProgression],
  );

  const completeActivity = useCallback(
    async (data: IActivityProgress, classId?: string) => {
      try {
        if (selectedClass?.type === 'CLASS')
          localStorage.removeItem(`time-${selectedClass.id}`);
        await updateClassStatus(
          {
            courseId,
            classId: classId ?? selectedClassId,
          },
          data,
        );
        queryClient.invalidateQueries(['course-progression']);
        findNextActivity(classId ?? selectedClassId);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          error as AxiosError;
          if (error.response?.data.error.message.includes('already completed'))
            findNextActivity(selectedClassId);
        }
      } finally {
        updateProgress();
      }
    },
    [
      courseId,
      queryClient,
      selectedClass,
      updateProgress,
      selectedClassId,
      findNextActivity,
    ],
  );

  const completedActivities = useMemo(
    () =>
      activitiesProgression.reduce(
        (prev, currentClass) => {
          const classesDetails: IClassesCompleted = prev;
          if (currentClass.questions) {
            classesDetails.quizCount += 1;
            currentClass.questions.forEach(item => {
              const answearType = item.result
                ? 'rightAnswears'
                : 'wrongAnswears';

              classesDetails[answearType] += 1;
              classesDetails.totalAnswears += 1;
            });
          }
          if (
            ['PRACTICE', 'CLASS'].includes(
              classesMap.get(currentClass?.class?.id)?.type || '',
            ) &&
            currentClass.state === 'COMPLETED'
          ) {
            classesDetails.classCount += 1;
          }
          if (currentClass.answers) {
            classesDetails.practiceCount += 1;
          }
          return classesDetails;
        },
        {
          classCount: 0,
          practiceCount: 0,
          quizCount: 0,
          rightAnswears: 0,
          wrongAnswears: 0,
          totalAnswears: 0,
        } as IClassesCompleted,
      ),
    [activitiesProgression, classesMap],
  );

  const allActivities = useMemo(
    () =>
      classes.reduce(
        (prev, currentClass) => {
          const classesDetails: IClassesDetail = prev;
          switch (currentClass.type) {
            case 'CLASS':
              classesDetails.classCount += 1;
              break;
            case 'PRACTICE':
              classesDetails.classCount += 1;
              break;
            case 'FLAG':
              classesDetails.practiceCount += 1;
              break;
            case 'QUIZ':
              classesDetails.quizCount += 1;
              break;
            default:
              break;
          }
          return classesDetails;
        },
        {
          classCount: 0,
          practiceCount: 0,
          quizCount: 0,
        } as IClassesDetail,
      ),
    [classes],
  );

  const findFirstActivity = useCallback(() => {
    const activityFinded = activitiesProgression.find(
      activityItem => activityItem.state === 'PENDING',
    );
    if (activityFinded) {
      const classFinded = classes.find(
        classItem => classItem.id === activityFinded.class.id,
      );
      if (classFinded) {
        push(`/course/${courseId}/class/${classFinded.id}`);
      }
    }
  }, [push, classes, courseId, activitiesProgression]);

  const toggleShowLayer = () => setShowLayerClass(prev => !prev);

  useEffect(() => {
    function handleGetDetailedClass(classData: IFinalClass) {
      setSelectedClass(classData);
      localStorage.setItem('last:class', classData.id);
      setSelectedClassId(classData.id);
      setIsLoading(false);
    }
    if (id === 'id') {
      findFirstActivity();
      return;
    }
    if (!isLoading && selectedClassId !== id) {
      setIsLoading(true);
      getDetailedClass(id)
        .then(handleGetDetailedClass)
        .catch(reason => {
          switch (reason?.response?.data?.error?.reason) {
            case 'RequirementIDValidationNotMet':
              if (address?.country?.toLocaleLowerCase() === 'br')
            //    showModal('error');
              setIsLoading(false);

              break;
            case 'RequirementDaysSincePurchaseNotMet':
            case 'RequirementStartDateNotMet':
              findFirstActivity();
              setIsLoading(false);
              showMessage({
                type: 'ERROR',
                message: 'Essa aula ainda não foi liberada',
              });
              break;
            case 'RequirementPreviousClassNotMet':
              setIsLoading(false);
              findFirstActivity();
              showMessage({
                type: 'ERROR',
                message: 'Você pulou uma aula',
              });
              break;
            default:
              findFirstActivity();
              showMessage({
                type: 'ERROR',
                message: 'Erro inesperado!',
              });
              break;
          }
        });
    }
  }, [
    id,
    push,
    address,
//    showModal,
    isLoading,
    showMessage,
    selectedClassId,
    findFirstActivity,
  ]);

  const classesInGroup = useMemo(() => {
    const groups: IGroup[] = [];
    const filteredClasses = classes
      .filter(classItem => classItem.module.id === selectedModuleItem)
      .map(classesItem => {
        if (Object.values(classesItem.group).length === 0) {
          return {
            ...classesItem,
            group: {
              id: 'group-0',
              title: 'Mais aulas',
              hidden: false,
            },
          };
        }

        return classesItem;
      });

    filteredClasses.forEach(classItem => {
      if (
        !groups.find(group => group.id === classItem.group.id) &&
        Object.values(classItem.group).length > 0
      ) {
        groups.push({
          ...classItem.group,
        });
      }
    });

    if (groups.length === 1) {
      if (groups[0].id === 'group-0') {
        groups[0].hidden = true;
      }
    }

    return groups
      .map(group => {
        return {
          ...group,
          classes: filteredClasses.filter(
            classItem => classItem.group.id === group.id,
          ),
          sortId: filteredClasses.filter(
            classItem => classItem.group.id === group.id,
          )[0].sortId,
        };
      })
      .sort((a, b) => a.sortId - b.sortId);
  }, [classes, selectedModuleItem]);

  return (
    <ClassContext.Provider
      value={{
        course,
        classes,
        keywords,
        courseId,
        moduleList,
        drawerVisible,
        setDrawerVisible,
        selectedKeywords,
        setSelectedKeywords,
        progressionMap,
        allActivities,
        setSelectedClass,
        completedActivities,
        courseValidatedData,
        getNextActivity,
        findNextActivity,
        findPreviousActivity,
        selectedClassId,
        setSelectedClassId,
        completeActivity,
        validatePrevActivity,
        updateProgress,
        modules,
        selectedClass,
        activitiesProgression,
        findActivityById,
        selectedModuleItem,
        setSelectedModuleItem,
        showLayerClass,
        toggleShowLayer,
        setShowLayerClass,
        classesInGroup,
      }}
    >
      {children}
    </ClassContext.Provider>
  );
};

export default ClassProvider;

export const useClass = (): IClassContextData => {
  const context = useContext(ClassContext);
  if (!context) {
    throw new Error('Você deve usar o hook dentro do ClassProvider');
  }
  return context;
};

import React, { useCallback, useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Descendant, Element } from 'slate';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components';
import { useMutation } from '@tanstack/react-query';

import { FiX } from 'react-icons/fi';
import { invalidateTopTopicsFromClassQuery } from '@/services/hooks/forum/topics/useTopTopicsFromClass';
import {
  useClassDataByIdQuery,
  invalidateClassDataByIdQuery,
} from '@/services/hooks/class/useClassDataByIdQuery';

import { forumApi } from '@/services/api/forum.api';
import { AxiosError, AxiosResponse } from 'axios';
import { serialize } from './TextEditor/helpers/toMarkdown';

import {
  DialogOverlay,
  DialogContent,
  DialogTitle,
  DialogDescription,
  Form,
} from './styles';

import { TextEditor } from './TextEditor';

const textEditorInitialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

type NewTopicModalProps = {
  trigger: React.ReactNode;
};

type CreateNewTopicSuccess = AxiosResponse<{
  success: boolean;
  postId: number;
}>;

type CreateNewTopicError = AxiosError<{
  errorList: Array<string>;
}>;

type CreateNewTopicFormData = {
  title: string;
  message: string;
};

export const NewTopicModal: React.FC<NewTopicModalProps> = ({ trigger }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id: classId } = useParams() as { id: string };
  const [topicTitle, setTopicTitle] = useState('');
  const [textEditorValue, setTextEditorValue] = useState(
    textEditorInitialValue,
  );
  const [isTextEditorEmpty, setIsTextEditorEmpty] = useState(true);
  const [formFieldsErrorMessage, setFormFieldsErrorMessage] = useState({
    title: '',
    message: '',
  });

  const { data, isLoading } = useClassDataByIdQuery(classId);

  const createNewTopic = useMutation<
    CreateNewTopicSuccess,
    CreateNewTopicError,
    CreateNewTopicFormData
  >(
    async formData => {
      const api = await forumApi();

      return api.post(`/posts/class/${classId}`, {
        title: formData.title,
        message: formData.message,
      });
    },
    {
      onSuccess: (data) => {

        console.log("Created post > " + data.data);
        

        invalidateTopTopicsFromClassQuery(classId);
        invalidateClassDataByIdQuery(classId);
        location.href="https://pato.community/t/" + data.data.postId;
      },
    },
  );

  const {
    newTheme: { white },
  } = useTheme();

  useEffect(() => {
    if (!isOpen) {
      setTextEditorValue(textEditorInitialValue);
      setFormFieldsErrorMessage({ title: '', message: '' });
    }
  }, [isOpen]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (!!formFieldsErrorMessage.message || !!formFieldsErrorMessage.title) {
        return;
      }

      const toMd = serialize(textEditorValue);

      try {
        await createNewTopic.mutateAsync({
          title: topicTitle,
          message: toMd,
        });

        return setIsOpen(false);
      } catch {
        // do nothing
      }
    },
    [
      textEditorValue,
      topicTitle,
      createNewTopic,
      formFieldsErrorMessage.title,
      formFieldsErrorMessage.message,
    ],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopicTitle(e.target.value);

    if (formFieldsErrorMessage.title) {
      setFormFieldsErrorMessage({ ...formFieldsErrorMessage, title: '' });
    }

    if (!e.target.value) {
      setFormFieldsErrorMessage({
        ...formFieldsErrorMessage,
        title: 'Esse campo não pode estar vazio',
      });
    }
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setFormFieldsErrorMessage({
        ...formFieldsErrorMessage,
        title: 'Esse campo não pode estar vazio',
      });
    }
  };

  const handleRichTextEditorChange = useCallback(
    (value: Descendant[]) => {
      const isEmpty = value.every(element => {
        const textEditorElement = element as Element;

        if (textEditorElement.type !== 'bulleted-list') {
          return textEditorElement.children[0].text === '';
        }

        return textEditorElement.children.every(child => {
          const listItem = child as unknown as Element;

          return listItem.children[0].text === '';
        });
      });
      setIsTextEditorEmpty(isEmpty);
      if (!isEmpty) {
        setFormFieldsErrorMessage({
          ...formFieldsErrorMessage,
          message: '',
        });
      }

      setTextEditorValue(value);
    },
    [formFieldsErrorMessage],
  );

  const handleRichTextEditorBlur = useCallback(() => {
    if (isTextEditorEmpty) {
      setFormFieldsErrorMessage(prevState => ({
        ...prevState,
        message: 'Esse campo não pode estar vazio',
      }));
    }
  }, [isTextEditorEmpty]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal container={document.getElementById('modal-wrapper')}>
        <DialogOverlay>
          <DialogContent>
            <DialogTitle>Começar um novo tópico</DialogTitle>
            <DialogDescription as="div">
              <div>
                <h3>Aula</h3>
                <p>{isLoading ? 'Carregando...' : data?.title}</p>
              </div>
              <div>
                <h3>Módulo</h3>
                <p>{isLoading ? 'Carregando...' : data?.module.name}</p>
              </div>
            </DialogDescription>
            <Form
              id="form"
              onSubmit={handleSubmit}
              titleError={formFieldsErrorMessage.title}
            >
              <label>
                Título
                <input
                  type="text"
                  placeholder="Um título interessante"
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />
                {!!formFieldsErrorMessage.title && (
                  <span className="error-message">
                    {formFieldsErrorMessage.title}
                  </span>
                )}
              </label>
              <TextEditor
                value={textEditorValue}
                onChange={handleRichTextEditorChange}
                errorMessage={formFieldsErrorMessage.message}
                onBlur={handleRichTextEditorBlur}
              />
            </Form>

            <footer>
              {createNewTopic.isError && (
                <span className="error-message">
                  {`Erro: ${
                    createNewTopic.error.response?.data.errorList[0] ||
                    'Houve um erro ao criar o tópico'
                  }`}
                </span>
              )}

              <button
                type="submit"
                form="form"
                disabled={
                  createNewTopic.isLoading ||
                  !!formFieldsErrorMessage.title ||
                  !!formFieldsErrorMessage.message
                }
              >
                {createNewTopic.isLoading ? 'Carregando...' : 'Publicar'}
              </button>
            </footer>

            <Dialog.Close asChild>
              <button type="button" className="close-button">
                <FiX size={24} stroke={white['10']} />
              </button>
            </Dialog.Close>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

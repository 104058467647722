import styled from "styled-components";

export const PaginatedItemStyled = styled.section`
  flex: none;
  transition: all 1s;
  width: 100%;
  padding: 0;

  &[data-disable="true"] {
    max-height: 0;
    overflow: hidden;
  }
`;

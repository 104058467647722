import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDialog } from '../../hooks/Dialog';

type IUsePostMessageHandlerProps = {
  handleNextClass: VoidFunction;
};

export const usePostMessageHandler = ({
  handleNextClass,
}: IUsePostMessageHandlerProps): null => {
  const { go } = useHistory();
  const { showDialog } = useDialog();

  useEffect(() => {
    const handleModal = async (data: IModalMessage) => {
      const {
        code,
        data: {
          message,
          title,
          hasBackgroundBlur,
          disableConfirmButton,
          disableCloseButton = true,
        },
      } = data;
      const isLicenceModal = code === 'about-licence';
      const iframeId = isLicenceModal ? 'class-iframe' : undefined;

      await showDialog({
        title,
        message,
        hasBackgroundBlur,
        disableCloseButton,
        disableConfirmButton,
        portalElement: iframeId,
      }).finally(() => {
        if (data.code === 'DRMError') go(0);
      });
    };
    const messageHandler = async (ev: MessageEvent<IPostMessage>) => {
      try {
        if (ev.origin !== window.location.origin) return;
        if (ev.data.type === 'modal') {
          await handleModal(ev.data);
        }
        if (ev.data.type === 'player' && ev.data.code === 'ended')
          handleNextClass();
      } catch {
        // no action
      }
    };
    // handleModal(licenceModalMessage);

    window.addEventListener('message', messageHandler);
    return () => window.removeEventListener('message', messageHandler);
  }, [showDialog, go, handleNextClass]);

  return null;
};

import styled, { css, keyframes }from 'styled-components';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

type IVariant = {
  custom?: boolean
}

export const Container = styled.aside`
  left: 0;
  overflow: hidden;
  top: 60px;
  z-index: 110;
  position: sticky;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;

  background: ${props => props.theme.newColors.black['900']};
  border: 1px solid ${props => props.theme.newTheme.border['10']};

  transform: translateX(-100%);
  min-width: 0;
  width: 0;

  &[data-visible="true"] {
    width: 36rem;
    min-width: 36rem;
    transform: translateX(0%);
  }

  > header {
    padding: .8rem;
    gap: .8rem;
    background: ${props => props.theme.newTheme.black['30']};
    border-bottom: 1px solid ${props => props.theme.border['10']};
    display: flex;
    align-items: stretch;
  }

  nav {
    overflow: auto;
    flex: 1;

    display: flex;
    flex-direction: column;
  }

  ul {
    width: 100%;
    overflow: overlay;

    &::-webkit-scrollbar{
      width: 20px;
    }

    &::-webkit-scrollbar-track{
      background-color: none;
    }

    &::-webkit-scrollbar-thumb{
      background-color: ${props => props.theme.colors.lightGrey};
      border-radius: 20px;
      height: 60px;
      background-clip: content-box;
      border: 8px solid transparent;
    }

    &::-webkit-scrollbar-button{
      background: none;
      height: 5px;
    }

    &::-webkit-scrollbar-corner{
      background: none;
    }
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);

    &[data-visible="true"] {
      width: 100vw;
    }
  }
`;

export const LayerButton = styled.button`
  display: none;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 4px;
  background: ${props => props.theme.newTheme.black['30']};
  border: 1px solid ${props => props.theme.border['10']};
  transition: all .2s ease;

  &[data-keywords-visible="true"] {
    background: ${props => props.theme.newTheme.black['50']};
    border: 1px solid ${props => props.theme.border['30']};
  }

  @media (min-width: 768px) {
    display: flex;
  }
`;

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

export const ModuleAccordionWrapper = styled.div`
  display: flex;
  width: 100%;
  .customAccordion {
    display: flex;
    width: 100%;
  }
`; 

export const StyledItem = styled(AccordionPrimitive.Item)<IVariant>`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${props => props.theme.newTheme.black['30']};
  border: 1px solid ${props => props.theme.newTheme.border['10']};
  border-radius: 0.4rem;
  position: relative;
  overflow: hidden;
  transition: all 200ms ease-in;

  &[data-state="open"] {
    border: 1px solid ${props => props.theme.newTheme.border['20']};
  }

  ${props => {
    if (props.custom) {
      return css`
        border-radius: 0px;
        border-color: transparent;
        border: 0px solid transparent;

        &[data-state="open"] {
          border: 0px solid transparent;
        }
      `;
    }
    
    return css``;
  }}

`;

export const StyledHeader = styled(AccordionPrimitive.Header)<IVariant>`
  display: flex;
  width: 100%;
  height: 4.8rem;
  position: relative;
  border-bottom: 1px solid transparent;
  transition: all 200ms ease-in;

  &[data-state="open"] {
    background: ${props => props.theme.newTheme.black['50']};
    border-bottom: 1px solid ${props => props.theme.newTheme.border['20']};
  }



  ${props => {
    if (props.custom) {
      return css`
        border-radius: 0px;
        border-color: transparent;
        border: 0px solid transparent;
        height: 7.4rem;

        ${({ theme: { newTheme }}) => css`
          background-color: ${newTheme.black['30']};
        `}

        &[data-state="open"] {
          border: 0px solid transparent;
          ${({ theme: { newTheme }}) => css`
            background-color: ${newTheme.black['30']};
          `}
        }

        &[hidden] {
          display: none;
          + div {
            padding-top: 16px;
          }
        }
      `;
    }
    
    return css``;
  }}
`;

export const StyledTrigger = styled(AccordionPrimitive.AccordionTrigger)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1.35rem 1.6rem 1.35rem 1.2rem;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${props => props.theme.newTheme.white['50']};
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state="closed"] {
    &::after {
      transform: rotate(180deg);
    }
  }
`;

export const StyledContent = styled(AccordionPrimitive.Content)<IVariant>`
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  &[data-state="open"] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state="closed"]:not([hidden]) {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  ${props => {
    if (props.custom) {
      return css`
        ${({ theme: { newTheme }}) => css`
          background-color: ${newTheme.black['20']};
        `}
      `;
    }

    return css``;
  }}
`;

export const InfoContent = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${props => props.theme.newTheme.white['60']};
  border-bottom: 1px solid ${props => props.theme.newTheme.border['10']};
  padding: 0.8rem 0 0.8rem 1.2rem;
  margin-bottom: 0.8rem;
  width: 100%;
`;

export const ModuleAccordionOption = styled.button`
  font-weight: 400;
  font-size: 1.4rem;
  color: ${props => props.theme.newTheme.white['20']};
  line-height: 21px;
  padding: 0.8rem 0 0.8rem 1.2rem;
  display: flex;
  width: 100%;
  transition: all .2s linear;
  border-radius: 0.4rem;

  &:hover {
    background-color: ${props => props.theme.newTheme.black['40']};;
  }
`;
import React, { useMemo } from 'react';
import { FiMessageSquare, FiPlus } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';

import { useLatestTopicsFromClass } from '@/services/hooks/forum/topics/useLatestTopicsFromClass';

import { TopicListItem } from '@/components/TopicListItem';
import { useForumProfile } from '@/services/hooks/forum/user/useForumProfile';
import { NewTopicModal } from './NewTopicModal';
import { EditForumProfileModal } from './EditForumProfileModal';

import { CommunityContainer, TopicsListContainer } from './styles';

type ICommunityProps = {
  classItem: IClassType;
};

export const Community: React.FC<ICommunityProps> = ({ classItem }) => {
  const { data, isLoading, error } = useLatestTopicsFromClass(classItem.id);

  const userForumProfile = useForumProfile();
  //console.log(userForumProfile.data);

  const Modal = (userForumProfile && userForumProfile.data && userForumProfile.data.nickname.length > 0)
    ? NewTopicModal
    : EditForumProfileModal;

  const topTopics = useMemo(() => {
    if (data) {
      return data.slice(0, 3);
    }

    return [];
  }, [data]);

  const {
    newTheme: { white },
  } = useTheme();

  return (
    <CommunityContainer>
      <header>
        <h3></h3>

        <Modal
          trigger={
            <button type="button">
              <FiMessageSquare stroke={white['30']} size={18} />
              <span>Começar um novo tópico</span>
            </button>
          }
        />
      </header>

      <TopicsListContainer>
        {isLoading ? (
          <p>Carregando...</p>
        ) : error ? (
          <p>Erro ao carregar os tópicos, tente novamente!</p>
        ) : topTopics.length === 0 ? (
          <p>Sem tópicos por enquanto.</p>
        ) : (
          topTopics.map(topic => <TopicListItem key={topic.id} topic={topic} />)
        )}
      </TopicsListContainer>

      {topTopics.length !== 0 && (
        <footer>
          <Link to="/topics">
            <FiPlus stroke={white['30']} size={18} />
            <span>Mais tópicos para essa aula</span>
          </Link>
        </footer>
      )}
    </CommunityContainer>
  );
};

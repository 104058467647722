import { IconType } from 'react-icons';
import { FiExternalLink, FiFilm, FiTerminal } from 'react-icons/fi';
import { ImFilePdf } from 'react-icons/im';
import { RiArticleLine } from 'react-icons/ri';
import {
  ExtraItemContainer,
  ExtraItemHeader,
  ExtraItemLink,
  ExtraItemDescription,
} from './styles';

type IIconDict = {
  [key in IExtraType]: IconType;
};

const ExtraIcon: IIconDict = {
  URL: FiExternalLink,
  PDF: ImFilePdf,
  CODE: FiTerminal,
  CLASS: FiFilm,
  ARTICLE: RiArticleLine,
};

type IExtraItemProps = {
  extra: IExtra;
};

export const ExtraItem: React.FC<IExtraItemProps> = ({ extra }) => {
  const Icon = ExtraIcon[extra.type];
  const isLabLink = extra.url.startsWith('https://patoacademy.run/');

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = () => {
    if (!isLabLink) return;
    localStorage.setItem('last-practice', extra.url);
  };

  return (
    <ExtraItemContainer data-type={extra.type}>
      {extra.type === 'CLASS' ? (
        <ExtraItemLink to={extra.url} data-cy="activity.extra.item">
          <Icon size={20} />
          <h3>{extra.title || extra.description}</h3>
        </ExtraItemLink>
      ) : (
        <ExtraItemHeader
          target="_blank"
          href={extra.url}
          onClick={onClick}
          data-cy="activity.extra.item"
        >
          <Icon size={20} />
          <h3>{extra.title || extra.description}</h3>
        </ExtraItemHeader>
      )}
      {extra.title && (
        <ExtraItemDescription>{extra.description}</ExtraItemDescription>
      )}
    </ExtraItemContainer>
  );
};

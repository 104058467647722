import { H4, H5 } from "@/components/Atoms/Texts";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const NextActivityCardContainer = styled.div`
  padding: 2.4rem;
  gap: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  border-radius: .4rem;
  background: ${props => props.theme.newTheme.black['30']};
`;

export const NextActivityNamesContainer = styled.div`
  flex: 1;
  gap: .4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const NextActivityModuleText = styled(H4)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  color: ${props => props.theme.newTheme.white['20']};
  b {
    text-transform: uppercase;
  }

  max-height: 1.5em;
  overflow: hidden;

  letter-spacing: 0.5px;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

export const NextActivityClassText = styled(H5)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 150%;

  color: ${props => props.theme.newTheme.white['40']};
`;

export const NextActivityLinkButton = styled(Link)`
  padding: .8rem 1.6rem .8rem .8rem;
  gap: 1.2rem;
  display: flex;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  color: ${props => props.theme.newTheme.white['30']};

  background: #00ffa814;
  /* Border/Border 10 */

  border: 1px solid ${props => props.theme.newTheme.border['10']};
  border-radius: 999px;

  &:hover {
    background: rgb(0 255 158 / 14%);

    border: 1px solid ${props => props.theme.newTheme.border['30']};
  }
`;

export const PlayIconContainer = styled.div`
  min-height: 3.2rem;
  min-width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgb(16 201 62 / 10%);
  svg {
    position: relative;
    left: 5%;
    stroke: none;
    fill: rgb(39 255 139 / 81%);
  }
`;

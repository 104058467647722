import styled from 'styled-components';
import { FiArrowLeft } from 'react-icons/fi'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 5.6rem;

  @media (min-width: 1024px) {
    max-width: 1440px;
    margin: 0 auto;
  }
`

export const StyledArrow = styled(FiArrowLeft)``

export const Title = styled.h6`
  font-size: 1.6rem;
  line-height: 150%;
  margin-left: 2.1rem;
  font-weight: normal;
`

export const ButtonGoBack = styled.button`
  display: flex;
  align-items: center;
  color: ${props => props.theme.newTheme.white['10']};
`
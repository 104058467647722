import styled from 'styled-components';

export const Container = styled.li`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;

  > fieldset {
    flex: 1;
  }
`;

export const RemoveButton = styled.button`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 1rem;
  width: 4.5rem;
  height: 4.5rem;
`;

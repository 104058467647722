import { H3, P } from '../../components/Atoms/Texts';
import SettingsSidebar from '../../components/SettingsSidebar';
import discordCopyIdImage from '../../assets/discord/discord-tag-how-to-copy.png';
import { Container, Content } from './styles';
import { DiscordButton } from '../../components/DiscordButton';

export const Discord: React.FC = () => {
  return (
    <Container>
      <SettingsSidebar />
      <Content>
        <H3 size={28} weight="700">
          Faça parte da comunidade de alunos no Discord
        </H3>
        <P size={16} weight="400">
          Não sabe o que é Discord?&#160;
          <a
            href="https://support.discord.com/hc/pt-br/articles/360045138571-Guia-do-Iniciante-para-Discord"
            target="_blank"
            rel="noopener noreferrer"
          >
            Descubra aqui
          </a>
        </P>
        <hr />
        <ul>
          <li>
            <P size={16} weight="400">
              <b>1. </b>
              Entre na comunidade por este link:&#160;
              <a
                href="https://pato.academy/discord-invite"
                target="_blank"
                rel="noopener noreferrer"
                className="green"
              >
                https://pato.academy/discord-invite
              </a>
            </P>
          </li>
          <li>
            <P size={16} weight="400">
              <b>2. </b>
              Copie sua <mark>tag de usuário</mark>, que se encontrara no canto
              inferior esquerdo
            </P>
            <img
              src={discordCopyIdImage}
              alt="Como encontrar sua tag de usuário: encontre o espaço com os botões de microfone/fone de ouvido e configurações, ao lado deve estar sua foto de perfil e seu nome, ao clicar no seu nome você terá copiado sua tag"
            />
          </li>
          <li>
            <P size={16} weight="400">
              <b>3. </b>
              Faça sua inscrição na comunidade ao enviar sua{' '}
              <mark>tag de usuário</mark> no campo abaixo
            </P>
          </li>
        </ul>
        <DiscordButton />
      </Content>
    </Container>
  );
};

import React, { useState } from 'react';
import { FiStar } from 'react-icons/fi';
import Icon from '../../../Icon';

import { Container } from './styles';

type ICircleProgressProps = {
  rate: number;
};

const CircleProgress: React.FC<ICircleProgressProps> = ({ rate }) => {
  const [id] = useState(Math.floor(Math.random() * 10 + 1));
  return (
    <Container rate={rate}>
      <svg viewBox="0 0 100 100">
        <defs>
          <linearGradient id={`circle-gradient-${id}`}>
            <stop stopColor="#48FFBD" offset="0%" />
            <stop stopColor="#48FFBD" offset="100%" />
          </linearGradient>
        </defs>
        <circle className="full" cx="50" cy="50" r="47" strokeWidth="6" />
        <circle
          cx="50"
          cy="50"
          r="47"
          stroke={`url(#circle-gradient-${id})`}
          strokeWidth="6"
        />
      </svg>
      <Icon
        isFilled
        size={14}
        isntGradient
        color="green"
        IconComponent={FiStar}
      />
    </Container>
  );
};

export default CircleProgress;

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { H2 } from '../../components/Atoms/Texts';
import Button from '../../components/Button';
import ProfileImageInput from '../../components/ProfileImageInput';
import Input from '../../components/RocketInput';
import SelectCountry from '../../components/SelectCountry';
import SettingsSidebar from '../../components/SettingsSidebar';
import SocialNetworks from '../../components/SocialNetworks';
import { useAlert } from '../../hooks/Alert';
import { useAuth } from '../../hooks/Auth';
import { updateUserData } from '../../services/api/student.api';
import { getValidationErrors } from '../../util/getValidationErrors';
import { validateAccountAddressData } from '../../util/Yup/account/address';

import { Container, InputSection } from './styles';

const Account: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddres] = useState<IUserAddress>({} as IUserAddress);

  const formRef = useRef<FormHandles>(null);

  const { showMessage } = useAlert();
  const { user, student, updateStudentData } = useAuth();

  useEffect(() => {
    if (student.address) setAddres(student.address);
  }, [student]);

  const handleSubmit = async (data: IUserData) => {
    setIsLoading(true);
    formRef.current?.setErrors({});
    try {
      if (!data.address) return;

      await validateAccountAddressData({ address: data.address });

      await updateUserData(data);
      updateStudentData();
      showMessage({
        message: 'Dados atualizados com sucesso!',
        type: 'SUCCESS',
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
      }
      showMessage({
        message: 'Ocorreu um erro ao atualizar os dados',
        type: 'ERROR',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (cep: string) => {
    const { data } = await axios.get<IFechedAddress>(
      `https://brasilapi.com.br/api/cep/v2/${cep}`,
    );
    const { city, neighborhood, state, street } = data;

    setAddres(prev => ({
      ...prev,
      city,
      state,
      street,
      neighborhood,
    }));
  };

  const isBrasilean = user && user['custom:country'] === 'BR';

  const cpfLabelTitle = isBrasilean ? 'CPF' : 'Passport';
  const cepLabelTitle = isBrasilean ? 'CEP' : 'Postal Code';
  return (
    <Container>
      <SettingsSidebar />
      <div>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <div>
              <InputSection>
                <H2 size={20} weight="600">
                  Sua Conta
                </H2>
                <Input
                  disabled
                  name="name"
                  type="text"
                  title="Nome"
                  width="100%"
                  defaultValue={user?.name}
                />
                <Input
                  disabled
                  width="100%"
                  type="email"
                  name="email"
                  title="E-mail"
                  defaultValue={user?.email}
                />
                <Input
                  disabled
                  name="cpf"
                  mask="cpf"
                  type="text"
                  title={cpfLabelTitle}
                  width="100%"
                  defaultValue={user && user['custom:cpf']}
                />
                <Input
                  type="text"
                  width="100%"
                  name="phone"
                  title="Número de contato"
                  defaultValue={user?.phoneNumber || student.phone}
                />
                <SocialNetworks socialNetworks={student.socialNetworks} />
              </InputSection>
              <InputSection>
                <H2 size={20} weight="600">
                  Endereço
                </H2>
                <section>
                  <SelectCountry
                    disabled
                    title="País"
                    defaultValue={user && user['custom:country']}
                  />
                </section>
                <section>
                  <Input
                    width="165px"
                    type="text"
                    title={cepLabelTitle}
                    name="address.postalCode"
                    mask={isBrasilean ? 'cep' : undefined}
                    onSearch={handleSearch}
                    defaultValue={address?.postalCode}
                  />
                  <Input
                    width="258px"
                    type="text"
                    title="Cidade"
                    name="address.city"
                    defaultValue={address?.city}
                  />
                  <Input
                    width="258px"
                    type="text"
                    title="Estado"
                    name="address.state"
                    defaultValue={address?.state}
                  />
                </section>
                <section>
                  <Input
                    width="258px"
                    type="text"
                    title="Bairro"
                    name="address.neighborhood"
                    defaultValue={address?.neighborhood}
                  />
                  <Input
                    width="258px"
                    type="text"
                    title="Rua/Avenida"
                    name="address.street"
                    defaultValue={address?.street}
                  />
                </section>
                <section>
                  <Input
                    width="165px"
                    type="text"
                    title="Número"
                    name="address.number"
                    defaultValue={address?.number}
                  />
                  <Input
                    width="165px"
                    type="text"
                    title="Complemento"
                    name="address.additional"
                    defaultValue={address?.additional}
                  />
                </section>
              </InputSection>
              <div className="buttonContainer">
                <Button
                  colorType="green"
                  size="large"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Salvar Alterações
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default Account;

import React, { useCallback, useMemo } from 'react';

import {
  Slate,
  withReact,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
} from 'slate-react';
import { createEditor, BaseEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';

import { Toolbar } from './Toolbar';

import { Editable, EditorWrapper } from './styles';

type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
};

type ParagraphBlockElement = {
  type: 'paragraph';
  children: CustomText[];
};

type HeadingBlockElement = {
  type: 'heading';
  children: CustomText[];
};

type BulletedListBlockElement = {
  type: 'bulleted-list';
  children: CustomText[];
};

type ListItemBlockElement = {
  type: 'list-item';
  children: CustomText[];
};

type BlockQuoteBlockElement = {
  type: 'block-quote';
  children: CustomText[];
};

type CustomElement =
  | HeadingBlockElement
  | ParagraphBlockElement
  | BulletedListBlockElement
  | ListItemBlockElement
  | BlockQuoteBlockElement;

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const Element = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.type) {
    case 'heading':
      return <h2 {...attributes}>{children}</h2>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  let nestedChildren = children;

  if (leaf.bold) {
    nestedChildren = <strong>{nestedChildren}</strong>;
  }

  if (leaf.code) {
    nestedChildren = <code>{nestedChildren}</code>;
  }

  if (leaf.italic) {
    nestedChildren = <em>{nestedChildren}</em>;
  }

  return <span {...attributes}>{nestedChildren}</span>;
};

type TextEditorProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  value: Descendant[];
  onChange: (value: Descendant[]) => void;
  errorMessage: string;
};

export const TextEditor = ({
  value,
  onChange,
  errorMessage,
  ...rest
}: TextEditorProps): JSX.Element => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  return (
    <Slate editor={editor} initialValue={value} onChange={onChange}>
      <EditorWrapper hasError={!!errorMessage}>
        <Toolbar />
        <Editable
          className="text-editor"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Digite aqui..."
          onKeyDown={event => {
            if (event.key === 'Enter' && event.shiftKey) {
              event.preventDefault();
              editor.insertText('\n');
            }
            if (event.key === 'Tab') {
              event.preventDefault();
              editor.insertText('    ');
            }
          }}
          {...rest}
        />
      </EditorWrapper>
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </Slate>
  );
};

import styled, { css } from 'styled-components';
import { FiClock, FiLink2} from 'react-icons/fi'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

type ITypeClass = {
  color: 'red' | 'yellow' | 'green'
}

type ICheckboxType = {
  variant: boolean
  isActual: boolean
  color: ITypeClass['color']
}

export const StyledCheckbox = styled(CheckboxPrimitive.Root)`
  all: unset;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  border: 1px solid ${props => props.theme.newTheme.white['60']};
  border-radius: 100%;
  top: 2.7rem;
  left: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 90ms linear;
  &[data-state="checked"] {
    border-color: ${props => props.theme.newTheme.green['20']};
  }
`;

export const StyledIndicator = styled(CheckboxPrimitive.CheckboxIndicator)`
  color: red;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${props => props.theme.newTheme.green['20']};
  border-radius: 100%;
`;

export const Card = styled.div`
  cursor: pointer;
  padding: 0.8rem 1.6rem 0.8rem 5.6em;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardGroup = styled.div<ICheckboxType>`
  background-color: ${props => props.theme.newTheme.black['20']};
  border: 1px solid ${props => props.theme.newTheme.border['20']};
  border-radius: 0.4rem;
  display: flex;
  position: relative;

  ${props => {
    if (props.variant) {
      return css`
        border: none;
        background-color: transparent;
        border-radius: 0;
      `;
    }

    return css``;
  }}

  ${({ theme: { newTheme }, color, isActual}) => isActual && css`
    border-left: 2px solid ${newTheme[color]['20']};
  `}
`;

export const TypeClass = styled.span<ITypeClass>`
  display: block;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ theme: { newTheme }, color }) => newTheme[color]['20']};
`;

export const InfoCard = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  position: relative;
  right: 4.0rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.newTheme.white['50']};
  margin-top: 0.4rem;
`;

export const StyledFiClock = styled(FiClock)`
  stroke: ${props => props.theme.newTheme.white['50']};
  margin-right: 0.5rem;
`;

export const StyledFiLink2 = styled(FiLink2)`
  stroke: ${props => props.theme.newTheme.white['50']};
  margin-right: 0.8rem;
`;

export const Head = styled.div`
  min-height: 6.3rem;
`;

/* eslint-disable no-param-reassign */
export type IMaskOptions = 'cpf' | 'phone' | 'credit' | 'cep' | 'validity';

type IMaskValue = {
  [value in IMaskOptions]: (prev: string) => string;
};

type IMaskEvent = {
  [value in IMaskOptions]: (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => boolean;
};

export const masks: IMaskValue = {
  cep: value => value.replace(/\D/g, '').replace(/(\d{5})(\d)/g, '$1-$2'),
  cpf: value =>
    value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4'),
  credit: value => value.replace(/\D/g, '').replace(/(\d{4})/g, '$1 '),
  phone: value => {
    const text = value.replace(/\D/g, '');

    if (text.length === 10) {
      return text.replace(/(\d{2})(\d{2})(\d{4})(\d)/, '+$1 ($2) $3-$4');
    }
    return text.replace(/(\d{2})(\d{2})(\d{5})(\d)/, '+$1 ($2) $3-$4');
  },
  validity: value =>
    value.replace(/\D/g, '').replace(/(\d{2})(\d{2})/, '$1/$2'),
};

export const maskEvents: IMaskEvent = {
  cep: ev => {
    ev.currentTarget.maxLength = 9;
    let { value } = ev.currentTarget;
    value = value.replace(/\D/g, '');

    value = value.replace(/(\d{5})(\d)/g, '$1-$2');

    ev.currentTarget.value = value;
    return value.length === ev.currentTarget.maxLength;
  },
  cpf: ev => {
    ev.currentTarget.maxLength = 14;
    let { value } = ev.currentTarget;
    value = value.replace(/\D/g, '');

    if (value.length > 9) {
      value = value.replace(/(\d)(\d{2})$/, '$1-$2');
    }
    value = value.replace(/(\d{3})\B/g, '$1.');

    ev.currentTarget.value = value;
    return value.length === ev.currentTarget.maxLength;
  },
  phone: ev => {
    ev.currentTarget.maxLength = 19;
    let { value } = ev.currentTarget;
    value = value.replace(/\D/g, '');

    if (value.length < 3) {
      value = value.replace(/(\d{1})/, '+$1');
    }
    if (value.length < 5) {
      value = value.replace(/(\d{2})(\d)/, '+$1 ($2');
    }
    value = value.replace(/^(\d{2})(\d{2})(\d)/, '+$1 ($2) $3');
    if (value.length > 17) {
      value = value.replace(/(\d{5})(\d)/, '$1-$2');
    } else {
      value = value.replace(/(\d{4})(\d)/, '$1-$2');
    }

    ev.currentTarget.value = value;
    return value.length >= ev.currentTarget.maxLength - 1;
  },
  credit: ev => {
    let { value } = ev.currentTarget;
    ev.currentTarget.maxLength = 19;
    value = value.replace(/\D/g, '');

    value = value.replace(/(\d{4})\B/g, '$1 ');

    ev.currentTarget.value = value;
    return value.length >= 19;
  },
  validity: ev => {
    ev.currentTarget.maxLength = 5;
    let { value } = ev.currentTarget;
    value = value.replace(/\D/g, '');

    value = value.replace(/(\d{2})\B/g, '$1/');

    ev.currentTarget.value = value;
    return value.length === ev.currentTarget.maxLength;
  },
};

import React, { useCallback, useEffect, useState } from 'react';
import { useClass } from '../../../hooks/Class';
import { H2 } from '../../Atoms/Texts';
import { ToggleClassList } from '../ClassPageComponents/ToggleClassList';
import { PaginatedProvider, usePaginated } from '../hooks/Paginated';
import { PaginatedContainer } from '../hooks/Paginated/PaginatedContainer';
import { PaginatedItem } from '../hooks/Paginated/PaginatedItem';
import { Loading } from '../Loading';
import QuestionContainer from './QuestionContainer';
import Resume, { IResume, IQuestion, IStats } from './Resume';

import { Container, QuizHeader } from './styles';

type IMainQuizzProps = {
  classItem: IQuizType;
};

const MainQuizz: React.FC<IMainQuizzProps> = ({ classItem }) => {
  const [classId, setClassId] = useState(classItem.id);
  const [resumeData, setResumeData] = useState<IResume | undefined>();
  const { page, setPage } = usePaginated();

  const {
    findNextActivity,
    updateProgress,
    findActivityById,
    activitiesProgression,
  } = useClass();

  useEffect(() => {
    if (classId !== classItem.id) {
      setClassId(classItem.id);
      setResumeData(undefined);
    } else {
      const quizProgress = activitiesProgression.find(
        activity => activity.class.id === classId,
      );
      if (quizProgress?.state === 'COMPLETED' && classItem.questions) {
        setPage(classItem.questions.length);
      }
    }
  }, [classId, setPage, classItem, activitiesProgression]);

  useEffect(() => {
    const item = findActivityById(classItem.id);
    if (item?.questions) {
      const validatedQuestions = item.questions.map(question => {
        const questionData = classItem.questions?.find(
          questionItem => questionItem.id === question.id,
        );
        if (questionData) {
          const selectedAnswer = questionData.possibleAnswers.find(
            answer => answer.id === question.answer,
          );
          const correctAnswer = questionData.possibleAnswers.find(answer =>
            question.correctAnswers
              ? answer.id === question.correctAnswers[0]
              : false,
          );
          if (selectedAnswer) {
            const data: IQuestion = {
              id: question.id,
              title: questionData.title,
              correct: question.result,
              selected: selectedAnswer.value,
              correctAnswer: correctAnswer?.value
                ? correctAnswer.value
                : undefined,
            };
            return data;
          }
        }
        return {} as IQuestion;
      });
      const stats: IStats = item.questions.reduce(
        (prev, question) => {
          const data = prev;
          data.total += 1;
          data[question.result ? 'correct' : 'wrong'] += 1;
          return data;
        },
        { correct: 0, total: 0, wrong: 0 } as IStats,
      );
      stats.total = classItem.questions?.length ?? 0;
      setResumeData({ questions: validatedQuestions, stats });
    }
  }, [findActivityById, classItem]);

  const handleQuestionResponse = useCallback(
    (questionAnswer: IQuizzAnswer & { selected: string }) => {
      const newQuestion: IQuestion = {
        id: questionAnswer.id,
        title: questionAnswer.title,
        correctAnswer: questionAnswer.correctAnswers[0],
        selected: questionAnswer.selected,
        correct: questionAnswer.selected === questionAnswer.correctAnswers[0],
      };
      if (resumeData) {
        const { questions, stats } = resumeData;
        stats[newQuestion.correct ? 'correct' : 'wrong'] += 1;
        questions.push(newQuestion);
        setResumeData({ questions: [...questions], stats: { ...stats } });
        return;
      }
      const questions: IQuestion[] = [newQuestion];
      const stats: IStats = {
        total: classItem.questions?.length ?? 0,
        correct: newQuestion.correct ? 1 : 0,
        wrong: newQuestion.correct ? 0 : 1,
      };
      setResumeData({ questions, stats });
    },
    [resumeData, classItem],
  );

  const handleFinishQuiz = () => {
    findNextActivity(classItem.id);
  };

  const isComplete =
    resumeData?.questions.length === classItem.questions?.length;

  useEffect(() => {
    if (isComplete) updateProgress();
  }, [isComplete, updateProgress]);

  const rate = classItem.questions
    ? ((page + 1) / classItem.questions.length) * 100
    : 100;
  const isResumePage = page === (classItem.questions?.length || 0);

  if (!classItem.questions) return Loading;

  return (
    <Container>
      <ToggleClassList />
      <div>
        {!isComplete && !isResumePage && (
          <QuizHeader rate={rate > 100 ? 100 : rate}>
            <div className="progressBar">
              <div />
            </div>
            <H2 size={18} weight="500">
              Questão {page + 1} de {classItem.questions?.length}
            </H2>
          </QuizHeader>
        )}
        <PaginatedContainer>
          {classItem.questions?.map((question, index) => (
            <PaginatedItem
              id={question.title + question.id}
              key={question.title + question.id}
            >
              <QuestionContainer
                quizzId={classItem.id}
                isCurrentQuestion={index === page}
                handleQuestionResponse={handleQuestionResponse}
                question={question}
              />
            </PaginatedItem>
          ))}
          {resumeData && (
            <PaginatedItem id="resume">
              <Resume {...resumeData} handleOnClick={handleFinishQuiz} />
            </PaginatedItem>
          )}
        </PaginatedContainer>
      </div>
    </Container>
  );
};

export default ({ classItem }: IMainQuizzProps): JSX.Element => (
  <PaginatedProvider>
    <MainQuizz classItem={classItem} />
  </PaginatedProvider>
);

import { FiAlertCircle } from "react-icons/fi";

import styled from "styled-components";
import { P } from "../../../../Atoms/Texts";

export const ProgressDisclaimerContainer = styled.div`
  width: 100%;
  margin-top: 1.6rem;

  gap: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-radius: .4rem;
`;

export const ProgressDisclaimerIcon = styled(FiAlertCircle)`
  flex-shrink: 0;
  stroke: ${props => props.theme.newTheme.white['50']};
`;

export const ProgressDisclaimerText = styled(P)`
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 150%;

  color: ${props => props.theme.newTheme.white['50']};
`;

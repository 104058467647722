import { createPortal } from 'react-dom';

type Props = {
  id?: string;
  hasPortal?: boolean;
};

export const OptionalPortal: React.FC<Props> = ({
  id,
  children,
  hasPortal,
}) => {
  if (!hasPortal || !id) return <>{children}</>;
  const element = document.getElementById(id);
  if (!element) return null;
  return createPortal(children, element);
};
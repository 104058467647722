import React, { useCallback, useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Button from '../../../Button';
import { validateQuizz } from '../../../../services/api';
import Option from './Option';

import {
  Content,
  FooterRow,
  Container,
  FooterButton,
  NavigateButton,
  PaginatedButtons,
  ConfirmButtonContainer,
} from './styles';
import { useClass } from '../../../../hooks/Class';
import { H3 } from '../../../Atoms/Texts';
import { usePaginated } from '../../hooks/Paginated';
import { MobileNavigationButtons } from '../../ClassPageComponents/MobileNavigationButtons';

type IQuestionContainerProps = {
  question: IQuizzQuestion;
  quizzId: string;
  isCurrentQuestion: boolean;
  handleQuestionResponse: (data: IQuizzAnswer & { selected: string }) => void;
};

type IValidatedAnswers = {
  [id: string]: 'correct' | 'wrong';
};

const QuestionContainer: React.FC<IQuestionContainerProps> = ({
  question,
  handleQuestionResponse,
  isCurrentQuestion,
  quizzId,
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [validatedAnswers, setValidateAnswers] = useState<IValidatedAnswers>(
    {},
  );
  const [finished, setFinished] = useState(false);
  const [classId, setClassId] = useState(quizzId);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { selectedClassId, findActivityById, courseId } = useClass();
  const { nextPage, prevPage, page, pageIdList } = usePaginated();

  useEffect(() => {
    if (selectedAnswers.length > 0) setIsButtonDisabled(false);
    if (selectedAnswers.length === 0 && !finished) setIsButtonDisabled(true);
  }, [selectedAnswers, finished]);

  useEffect(() => {
    if (classId !== quizzId) {
      setClassId(quizzId);
      setFinished(false);
      setValidateAnswers({});
      setSelectedAnswers([]);
    }
    const item = findActivityById(quizzId);
    if (item?.questions) {
      const questionSelected = item.questions.find(
        questionItem => questionItem.id === question.id,
      );
      if (questionSelected) {
        setSelectedAnswers([questionSelected.answer]);
        const selected = [
          questionSelected.answer,
          ...(questionSelected.correctAnswers || []),
        ];
        const initialValidated: IValidatedAnswers = {};

        selected.forEach(answer => {
          initialValidated[answer] = questionSelected?.correctAnswers?.includes(
            answer,
          )
            ? 'correct'
            : 'wrong';
        });
        setValidateAnswers(initialValidated);
        setFinished(true);
      }
    }
  }, [classId, quizzId, findActivityById, question.id]);

  const handleSelect = (id: string) => {
    const result = selectedAnswers.find(item => item === id);
    if (result) {
      const rest = selectedAnswers.filter(item => item !== result);
      setSelectedAnswers([...rest]);
      return;
    }
    setSelectedAnswers([id]);
  };

  const handleValidateAnswers = useCallback(async () => {
    setIsLoading(true);
    setIsButtonDisabled(true);
    if (selectedAnswers.length > 0) {
      try {
        const response = await validateQuizz(courseId, selectedClassId, {
          question: {
            id: question.id,
            answer: selectedAnswers[0],
          },
        });
        const initialValidated: IValidatedAnswers = {};
        initialValidated[response.correctAnswers[0]] = 'correct';
        selectedAnswers.forEach(answer => {
          initialValidated[answer] = response.correctAnswers.includes(answer)
            ? 'correct'
            : 'wrong';
        });
        handleQuestionResponse({ ...response, selected: selectedAnswers[0] });
        setFinished(true);
        setValidateAnswers(initialValidated);
      } finally {
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    }
  }, [
    courseId,
    question,
    selectedAnswers,
    selectedClassId,
    handleQuestionResponse,
  ]);

  return (
    <Container>
      <H3 size={16}>{question.title}</H3>
      <Content>
        <PaginatedButtons
          type="button"
          onClick={prevPage}
          disabled={page === 0}
        >
          <FiChevronLeft size={20} />
        </PaginatedButtons>
        <ul>
          {question.possibleAnswers.map(option => (
            <Option
              quizzId={quizzId}
              answer={validatedAnswers[option.id]}
              cantChange={finished}
              data={option}
              key={option.id}
              handleSelect={handleSelect}
              isSelected={selectedAnswers.includes(option.id)}
            />
          ))}
        </ul>
        <PaginatedButtons
          type="button"
          onClick={nextPage}
          disabled={page === pageIdList.length - 1}
        >
          <FiChevronRight size={20} />
        </PaginatedButtons>
      </Content>
      {!finished && selectedAnswers.length > 0 && (
        <ConfirmButtonContainer>
          <Button
            size="medium"
            colorType="blue"
            isLoading={isLoading}
            onClick={handleValidateAnswers}
            disabled={isButtonDisabled || finished}
          >
            Confirmar
          </Button>
        </ConfirmButtonContainer>
      )}
      {isCurrentQuestion && (
        <MobileNavigationButtons>
          <FooterButton>
            <FooterRow>
              <Button
                isGradient
                size="medium"
                colorType="blue"
                isLoading={isLoading}
                onClick={handleValidateAnswers}
                disabled={isButtonDisabled || finished}
              >
                Confirmar
              </Button>
            </FooterRow>
            <FooterRow>
              <NavigateButton type="button" onClick={prevPage}>
                <FiChevronLeft size={20} />
              </NavigateButton>
              <NavigateButton type="button" onClick={nextPage}>
                <FiChevronRight size={20} />
              </NavigateButton>
            </FooterRow>
          </FooterButton>
        </MobileNavigationButtons>
      )}
    </Container>
  );
};

export default QuestionContainer;

import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;

  > div {
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
  select,
  option {
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.black};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme: { colors } }) => colors.lightGrey};
    color: ${({ theme: { colors } }) => colors.lightBlack};
  }
`;

export const AddNetworkButton = styled.button`
  width: 100%;
  height: 3.3rem;
  border-radius: 4px;
  padding: 4px 8px;
  background: ${({ theme: { newColors } }) => newColors.black['900']};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.2 })};
  color: ${({ theme: { colors } }) => colors.lightBlack};

  &:hover {
    border-color: ${({ theme: { opacifyColor } }) => opacifyColor({ color: 'white', opacity: 0.5 })};
    box-shadow: 0 0 0 30px ${({ theme: { newColors } }) => newColors.black['900']} inset !important;
  }
`;

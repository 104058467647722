import { Root } from '@radix-ui/react-accordion';
import { Container } from './styles';

interface IAccordionWrapperProps {
  defaultValue?: string;
}

const AccordionWrapper: React.FC<IAccordionWrapperProps> = ({
  children,
  defaultValue,
}) => {
  return (
    <Container>
      <Root
        className="customAccordion"
        type="single"
        defaultValue={defaultValue}
        collapsible
      >
        {children}
      </Root>
    </Container>
  );
};

export default AccordionWrapper;

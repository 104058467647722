import React from 'react';
import { FiFlag } from 'react-icons/fi';
import { P } from '../../../Atoms/Texts';
import Icon from '../../../Icon';

import { Container } from './styles';

type IFlagProps = {
  flag: {
    description: string;
    url: string;
  };
};

const FlagLink: React.FC<IFlagProps> = ({ flag }) => {
  const onClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    localStorage.setItem('last-practice', flag.url);
    window.open(flag.url, '_blank');
  };

  return (
    <Container
      href={flag.url}
      onClick={onClick}
      className="blackGlass backgroundBlur"
    >
      <Icon IconComponent={FiFlag} color="red" isntGradient size={24} />
      <P>{flag.description}</P>
    </Container>
  );
};

export default FlagLink;

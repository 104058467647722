import styled from "styled-components";

export const ToggleClassListButton = styled.button`
  align-self: flex-start;
  z-index: 2;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ToggleClassListConent = styled.span`
  gap: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-family: 'Poppins';
  font-size: 1.0rem;
  line-height: 150%;
  font-weight: 500;
  color: rgb(245 245 245 / 15%);
`;

import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  > div {
    transition: .5s;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
`;

export const Footer = styled.footer`
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

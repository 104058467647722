import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserData } from '../../services/api/student.api';
import { slugifyEmail } from '../../util/slugifyEmail';


interface IAuthContextData {
  updateStudentData: () => Promise<void>;
  signOut: () => Promise<void>;
  user: IAWSUserData | undefined;
  student: IUserDataResponse;
  refreshLogin: () => Promise<void>;
  userName: string;
  isLoading: boolean;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthComponent: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>();
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const queryClient = useQueryClient();

  const { data: student = {} as IUserDataResponse } = useQuery({
    queryKey: ['get-user-data'],
    queryFn: getUserData,
    staleTime: Infinity,
    refetchOnMount: 'always',
  });

  const updateStudentData = useCallback(
    () => queryClient.invalidateQueries(['get-user-data']),
    [queryClient],
  );

  useEffect(() => {
    //if (user) 
    updateStudentData();
  }, [user, updateStudentData]);


  const refreshLogin = useCallback(async () => {
    setIsLoading(true);
    try {
      //const value: CognitoUser = await Auth.currentAuthenticatedUser();
      const userData = await getUserData();
     updateStudentData()
      setUser(userData);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    refreshLogin();
  }, [refreshLogin]);


  const signOut = async () => {
    localStorage.removeItem('patoToken');
    localStorage.removeItem('intercom');
    localStorage.removeItem('club');
    localStorage.removeItem('radar');
    setUser(undefined);
    location.href="/";
    
  };



  const updatePassword = async (oldPassword: string, newPassword: string) => {
    alert('- update password -');
    //const currentUser = await Auth.currentAuthenticatedUser();
    //await Auth.changePassword(currentUser, oldPassword, newPassword);
  };

  return (
    <AuthContext.Provider
      value={{
        updateStudentData,
        signOut,
        user,
        student,
        refreshLogin,
        userName,
        isLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthComponent;

const useAuth = (): IAuthContextData => {
  const context = useContext(AuthContext);
  if (!context)
    throw new Error('Você não pode usar esse hook fora de um AuthContext');
  return context;
};

export { useAuth };

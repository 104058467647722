import styled from "styled-components";

export const DescriptionContentContainer = styled.div`
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 150%;

    text-align: justify;

    color: ${props => props.theme.newTheme.white['40']};
  }
`;

import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
  useContext,
} from 'react';
import { IntercomContextValues, useIntercom } from 'react-use-intercom';
import { useAuth } from '../Auth';

type IUseCustomIntercom = Omit<
  IntercomContextValues,
  'hide' | 'show' | 'boot'
> & {
  hideButton: boolean;
  setHideButton: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  open: VoidFunction;
  close: VoidFunction;
  handleClick: VoidFunction;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const IntercomContext = createContext<IUseCustomIntercom>(
  {} as IUseCustomIntercom,
);

export const CustomIntercomProvider: React.FC = ({ children }) => {
  const { boot, show, hide, ...rest } = useIntercom();
  const [hideButton, setHideButton] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {
    student: { phone, email, name },
  } = useAuth();

  useEffect(() => {
    if (phone && email && name) {
      const token = localStorage.getItem('intercom') as string;
      if(token && token.length > 10) {
      boot({
        name,
        email,
        phone,
        verticalPadding: 100,
        userHash: token,
        hideDefaultLauncher: true,
        backgroundColor: '#131316',
      });
    }
  }
  }, [boot, name, phone, email]);

  const open = () => {
    show();
    setIsOpen(true);
  };

  const close = () => {
    hide();
    setIsOpen(false);
  };

  const handleClick = () => {
    if (isOpen) {
      close();
      return;
    }
    open();
  };

  return (
    <IntercomContext.Provider
      value={{
        open,
        close,
        setIsOpen,
        handleClick,
        hideButton,
        setHideButton,
        ...rest,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
};

export const useCustomIntercom = (): IUseCustomIntercom =>
  useContext(IntercomContext);

import { FiChevronLeft } from 'react-icons/fi';
import Button from '../../../Button';
import { MobileNavigationButtons } from '../../ClassPageComponents/MobileNavigationButtons';
import { usePaginated } from '../../hooks/Paginated';
import { FlagButtonsContainer, NavigationButton } from './styles';

type IFlagButtonProps = {
  isLoading: boolean;
  isCompleted: boolean;
  handleSubmit: () => void;
  handleNextClass: () => boolean;
};

export const FlagButtons: React.FC<IFlagButtonProps> = ({
  isLoading,
  isCompleted,
  handleSubmit,
  handleNextClass,
}) => {
  const { page, pageIdList, prevPage, nextPage } = usePaginated();

  const isVideoPage = page === 0 && pageIdList.length > 1;
  const hasVideoPage = pageIdList.length > 1;

  return (
    <MobileNavigationButtons>
      <FlagButtonsContainer>
        {!isVideoPage ? (
          <>
            {hasVideoPage && (
              <NavigationButton type="button" onClick={prevPage}>
                <FiChevronLeft size={20} />
              </NavigationButton>
            )}
            <Button
              isGradient
              size="large"
              hasSmallText
              colorType="blue"
              isLoading={isLoading}
              type="button"
              onClick={isCompleted ? handleNextClass : handleSubmit}
            >
              {isCompleted ? 'Próxima atividade' : 'Concluir'}
            </Button>
          </>
        ) : (
          <Button colorType="blue" type="button" onClick={nextPage}>
            Acesso ao exercício
          </Button>
        )}
      </FlagButtonsContainer>
    </MobileNavigationButtons>
  );
};

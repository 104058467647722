import { H3 } from '@/components/Atoms/Texts';
import CardClass from '@/components/CardClass';
import { useClass } from '@/hooks/Class';
import { useClassCard } from '@/util/hooks/useClassCard';
import AccordionWrapper from './Accordion';
import AccordionTrigger from './Accordion/AccordionTrigger';
import { StyledContent, StyledItem } from './Accordion/styles';
import { CardTitle, CLoseButton, Container, StyledCloseIcon } from './styles';

const ClassLayer: React.FC = () => {
  const {
    showLayerClass,
    toggleShowLayer,
    selectedModuleItem,
    moduleList,
    classesInGroup,
  } = useClass();

  const module = moduleList.find(item => item.id === selectedModuleItem);

  const { handleChecked, handleClick, isntFinished } = useClassCard();

  return (
    <>
      {showLayerClass && (
        <Container>
          <header>
            <H3 weight="600" size={24}>
              Visualização em camadas
            </H3>
            <CLoseButton onClick={toggleShowLayer}>
              <StyledCloseIcon size={24} />
            </CLoseButton>
          </header>
          <CardTitle>{module?.name}</CardTitle>

          {classesInGroup.map(group => (
            <AccordionWrapper key={group.id} defaultValue={group.id}>
              <StyledItem value={group.id}>
                <AccordionTrigger hidden={group?.hidden}>
                  {group.title}
                </AccordionTrigger>
                <StyledContent>
                  {group.classes.map(classItem => (
                    <CardClass
                      key={classItem.id}
                      classItem={classItem}
                      isntFinished={isntFinished(classItem.id)}
                      handleClick={handleClick}
                      handleChecked={() => handleChecked(classItem)}
                    />
                  ))}
                </StyledContent>
              </StyledItem>
            </AccordionWrapper>
          ))}
        </Container>
      )}
    </>
  );
};

export default ClassLayer;

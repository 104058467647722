import * as Yup from 'yup';

type IChangePassword = {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
};

export const updatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Campo obrigatório')
    .min(8, 'Mínimo de 8 caracteres'),
  password: Yup.string()
    .required('Campo obrigatório')
    .min(8, 'Mínimo de 8 caracteres'),
  passwordConfirm: Yup.string()
    .required('Confirmação de senha é obrigatória')
    .equals([Yup.ref('password'), 'As senhas deve ser as mesmas']),
});

type IValidation = (
  data: IChangePassword,
) => Promise<Yup.Asserts<typeof updatePasswordSchema>>;

export const validateUpdatePassword: IValidation = async data =>
  updatePasswordSchema.validate(data, { abortEarly: false });

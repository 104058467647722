export const compareModules = (
  first: IGroupedClasses,
  second: IGroupedClasses,
): number => {
  if (first.module.sortId && second.module.sortId)
    return first.module.sortId - second.module.sortId;

  return first.module.createdAt > second.module.createdAt ? 1 : -1;
};

export const comparePureModules = (first: IModule, second: IModule): number => {
  if (first.sortId && second.sortId) return first.sortId - second.sortId;

  return first.createdAt > second.createdAt ? 1 : -1;
};

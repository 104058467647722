import * as Tabs from '@radix-ui/react-tabs';
import styled from 'styled-components';

export const TabContainer = styled(Tabs.Root)`
  width: 100%;
`;

export const TabHeader = styled(Tabs.TabsList)`
  display: flex;
  align-items: center;
  margin: 0 0 2.4rem;
`;

export const TabTrigger = styled(Tabs.Trigger)`
  flex: 1;
  height: 5rem;
  gap: 1.9rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.newTheme.white['50']};
  }
  svg {
    stroke: ${props => props.theme.newTheme.white['50']};
  }

  &[data-state="active"] {
    span{
      color: ${props => props.theme.newTheme.white['10']};
    }
    svg {
      stroke: ${props => props.theme.newTheme.white['10']};
    }
    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      content: '';
      width: 100%;
      height: 3px;
      transform: translateX(-50%);
      background: ${props => props.theme.newTheme.blue['20']};
    }
  }

  @media screen and (max-width: 768px) {
    span {
      display: none;
    }
  }
`;

export const TabContent = styled(Tabs.TabsContent)`
  width: 100%;
  padding: 0 1.6rem;
  margin: 0 0 3rem;
`;

import styled, { keyframes } from "styled-components";

const appear = keyframes`
  from {
    opacity: 0;
    bottom: -8.1rem;
  }
  to {
    opacity: 1;
    bottom: 0px;
  }
`;

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 8.1rem;
  padding: 0 12%;
  align-items: center;
  justify-content: space-between;
  z-index: 101;

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(24px);
  transition: all .7s;

  animation: ${appear} ease-in;

  &[data-renderstate='false']{
    bottom: -8.1rem;
  }
`;

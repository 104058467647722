import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { P } from '../Atoms/Texts';
import Icon from '../Icon';

import { Container, IButtonStyleProps, LoadingButtonSvg } from './styles';

type IButtonProps = Omit<IButtonStyleProps, 'colorType'> &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    isntColored?: boolean;
    hasSmallText?: boolean;
    colorType?: 'green' | 'reddish' | 'yellow' | 'purple' | 'blue';
  };

type IIconColorValue = 'green' | 'red' | 'yellow' | 'purple' | 'blue';
type IIconColorKey = 'green' | 'reddish' | 'yellow' | 'purple' | 'blue';

const IconColor: {
  [key in IIconColorKey]: IIconColorValue;
} = {
  green: 'green',
  reddish: 'red',
  yellow: 'yellow',
  purple: 'purple',
  blue: 'blue',
};

const Button: React.FC<IButtonProps> = ({
  size,
  chevron,
  children,
  disabled,
  isLoading,
  isntFilled,
  hasSmallText,
  colorType = 'green',
  isntColored,
  ...rest
}) => (
  <Container
    colorType={colorType}
    isLoading={isLoading}
    isntFilled={isntFilled}
    isntColored={isntColored}
    disabled={disabled || isLoading}
    {...{ ...rest, chevron, size }}
  >
    {isLoading ? (
      <LoadingButtonSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <circle
          cy="15"
          cx="15"
          r="7"
          strokeWidth="2"
          strokeDasharray="40"
          strokeDashoffset="10"
          strokeLinecap="round"
          fill="none"
        />
      </LoadingButtonSvg>
    ) : (
      <>
        {chevron === 'prev' && (
          <Icon
            size={18}
            isntGradient
            IconComponent={FiChevronLeft}
            color={isntFilled ? IconColor[colorType] : 'black'}
          />
        )}
        <P weight={hasSmallText ? '600' : '500'} size={14}>
          {children}
        </P>
        {chevron === 'next' && (
          <Icon
            size={18}
            isntGradient
            IconComponent={FiChevronRight}
            color={isntFilled ? IconColor[colorType] : 'black'}
          />
        )}
      </>
    )}
  </Container>
);

export default Button;

/* eslint-disable react/no-danger */
import { pufiryHtmlText } from '@/util/purifyHtmlText';
import React from 'react';
import { ContainerHeader } from './styles';

type IHeaderInfo = {
  namePage: string;
  student: string;
  message: string;
  hideBorder?: boolean;
};

const HeaderInfo: React.FC<IHeaderInfo> = ({
  namePage,
  student,
  message,
  hideBorder,
}) => {
  return (
    <ContainerHeader data-hide-border={hideBorder}>
      <span>{namePage}</span>
      <h4>Olá, {student}!</h4>
      <p dangerouslySetInnerHTML={{ __html: pufiryHtmlText(message) }} />
    </ContainerHeader>
  );
};

export default HeaderInfo;

import { FiChevronDown, FiLayers } from 'react-icons/fi';
import styled, { css } from 'styled-components';


interface IContainerProps {
  isFull?: boolean;
}

export const StyledChevronDown = styled(FiChevronDown)``;

export const StyledFiLayers = styled(FiLayers)``;

export const Container = styled.aside<IContainerProps>`
  position: sticky;
  top:  64px;
  margin: 1rem 1rem 1rem 0.8rem;
  z-index: 3;

  .closeIcon{
    cursor: pointer;
  }

  transition: all 1s;

  display: flex;
  align-items: stretch;
  flex-direction: column;

  min-width: ${props => props.isFull ? 373 : 0}px;
  width: ${props => props.isFull ? 373 : 0}px;
  height: ${props => props.isFull ? `calc(100vh - 84px)` : `0`};
  border-radius: 0 15px 15px 0;

  /* Black glass */
  background: ${props => props.theme.opacifyColor({ color: 'black', opacity: 0.8 })};
  border: 1px solid ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.1 })};
  box-sizing: border-box;

  background: ${({ theme: { newColors } }) => newColors.black['900']};

  box-sizing: border-box;
  border-radius: 8px;
  ${({ isFull }) => !isFull && css`
    border-width: 0px;
  `}

  h3 {
    margin-bottom: 1.2rem;
  }

  @media screen and (max-width: 1024px) {
    position: absolute;
    max-width: 100%;
  }
`;

export const ToggleClassListButton = styled.button<IContainerProps>`
  margin: ${({ isFull }) => isFull ? css`2.4rem 1.6rem` : css`2.4rem 0 0 1.6rem`};
  position: relative;
  transition: ${({ isFull }) => isFull ? 'all .5s' : 'all .75s .25s'};

  left: ${({ isFull }) => isFull ? 0 : '4.6rem'};
  align-self: flex-end;


  background: ${({ theme: { newColors } }) => newColors.black['600']};

  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 4px;

  > div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ClassListHeader = styled.header`
  display: block;
  padding: 0 1.6rem;
  overflow: hidden;
  width: 100%;
`;

export const ModuleSelect = styled.div`
  position: relative;
  height: 4.8rem;
  width: 100%;
  overflow: hidden;

  select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 4.8rem;
    padding: 1.2rem 1.35rem;
    background-color: ${props => props.theme.newTheme.black['30']};
    border: 1px solid ${props => props.theme.opacifyColor({ color: 'white', opacity: 0.1 })};
    color: ${props => props.theme.newTheme.white['20']};
    border-radius: 4px;
  }

  ${StyledChevronDown} {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }
`;

export const ClassListNav = styled.nav`
  margin-bottom: 2rem;
  overflow: hidden;
  flex: 1;

  display: flex;
  flex-direction: column;

  h3 {
    margin: 1.2rem 1.35rem;
  }

  ul {
    overflow: auto;
    width: 100%;
    flex: 1;

    &::-webkit-scrollbar{
      width: 20px;
    }

    &::-webkit-scrollbar-track{
      background-color: none;
    }

    &::-webkit-scrollbar-thumb{
      background-color: ${props => props.theme.colors.lightGrey};
      border-radius: 20px;
      height: 60px;
      background-clip: content-box;
      border: 8px solid transparent;
    }

    &::-webkit-scrollbar-button{
      background: none;
      height: 5px;
    }

    &::-webkit-scrollbar-corner{
      background: none;
    }
  }
`;


import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  > button {
    font-size: 1rem;

    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 15px;

    svg {
      pointer-events: none;
    }
  }

  @media screen and (max-width: 768px) {
    flex: 1;

    > button {
      display: none;
    }
  }
`;

export const Dashboard = styled.div`
  display: flex;

  svg {
    margin-right: 8px;
  }
`

import React from 'react';
import { FiMoreVertical, FiUser } from 'react-icons/fi';
import { useAuth } from '../../hooks/Auth';
import { useCloseMenuRef } from '../../util/hooks/useCloseMenuRef';
import Icon from '../Icon';
import Dropdown from './Dropdown';

import { Container } from './styles';

type IDropdownMenuProps = {
  hasMouseLeave?: true;
  hasImage?: true;
};

const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  hasImage,
  hasMouseLeave,
}) => {
  const { ref, isOpen, toggleMenu, closeMenu } =
    useCloseMenuRef<HTMLDivElement>();

  const {
    student: { imageUrl },
  } = useAuth();

  return (
    <Container className="image" ref={ref}>
      <button type="button" className="image" onClick={toggleMenu}>
        {hasImage ? (
          <>
            {imageUrl ? (
              <img src={imageUrl} alt="Profile" />
            ) : (
              <Icon IconComponent={FiUser} strokeLinecap="butt" isFilled />
            )}
          </>
        ) : (
          <Icon IconComponent={FiMoreVertical} size={30} />
        )}
      </button>
      <Dropdown onMouseLeave={hasMouseLeave && closeMenu} isVisible={isOpen} />
    </Container>
  );
};

export default DropdownMenu;

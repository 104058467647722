import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { useClass } from '../../../hooks/Class';
import { usePostMessageHandler } from '../../../util/hooks/usePostMessageHandler';
import {
  ClassFooter,
  IFooterOptions,
} from '../ClassPageComponents/ClassFooter';
import { ClassPagination } from '../ClassPageComponents/ClassPagination';
import { NextActivity } from '../ClassPageComponents/NextActivity';
import { ToggleClassList } from '../ClassPageComponents/ToggleClassList';

import { Container, IframeWrapper, Iframe } from './styles';

type IMainClassProps = {
  classItem: IClassType;
};

const defaultUrl = process.env.REACT_APP_PLAYER_IFRAME_URL;
const betaUrl = process.env.REACT_APP_PLAYER_IFRAME_URL_BETA;

const MainClass: React.FC<IMainClassProps> = ({ classItem }) => {
  const [footerSelected, setFootterSelected] = useState<IFooterOptions>();
  const { location } = useHistory();
  const { courseId, getNextActivity, selectedClassId } = useClass();
  const [showTransition, setShowTransition] = useState(false);
  const footerRef = useRef<HTMLDivElement>(null);

  const handleNextClass = useCallback(() => setShowTransition(true), []);
  usePostMessageHandler({
    handleNextClass,
  });

  useEffect(() => setFootterSelected('description'), [selectedClassId]);

  const baseUrl = useMemo(() => {
    if (location.search?.includes('beta') && betaUrl)
      return betaUrl || defaultUrl;
    return defaultUrl;
  }, [location.search]);

  const nextActivityTitle = useMemo(
    () => getNextActivity(classItem.id)?.title,
    [classItem.id, getNextActivity],
  );

  const handleClickStartRate = () => {
    setFootterSelected('feedback');

    setTimeout(() => {
      footerRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, 1);
  };

  const handleClickExtras = () => {
    setFootterSelected('extra');

    setTimeout(() => {
      footerRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, 1);
  };

  const shouldShowTransition = nextActivityTitle && showTransition;

  const url = `${baseUrl}?courseId=${courseId}&classId=${classItem.id}`;
  return (
    <Container>
      <ToggleClassList />
      <IframeWrapper id="class-iframe" data-hide={shouldShowTransition}>
        <Iframe
          key={url}
          src={url}
          title={classItem.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          data-cy="activity.classpage.inframe"
        />
      </IframeWrapper>
      {shouldShowTransition && nextActivityTitle && (
        <NextActivity
          key={classItem.id}
          classItem={classItem}
          onClickExtras={handleClickExtras}
          nextActivityTitle={nextActivityTitle || ''}
          onClickRateActivity={handleClickStartRate}
        />
      )}
      <ClassPagination title={classItem.title} classId={classItem.id} />
      <ClassFooter
        ref={footerRef}
        classItem={classItem}
        selected={footerSelected}
        setSelected={setFootterSelected}
      />
    </Container>
  );
};

export default MainClass;

import styled from "styled-components";


export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  & > p {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.newTheme.white['50']};
    margin: auto;
  }

  & a {
    padding: .8rem !important;

    h4 {
      min-height: 4.2rem;
    }

    & .topic-impressions {
      margin-left: auto;
      div {
        width: unset !important;
      }
    }
  }
`;

import styled from 'styled-components';
import { ToggleClassListButton } from '../ClassData/ClassPageComponents/ToggleClassList/styles';

export const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${props =>
    props.theme.opacifyColor({ color: 'black', opacity: 0.5 })};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);
  border-bottom: 1px solid ${props => props.theme.newTheme.border['10']};

  .logo {
    margin-left: 6.5rem;
  }

  ${ToggleClassListButton} {
    display: none;
  }

  @media screen and (max-width: 768px) {
    padding: 0 40px;

    background: ${props => props.theme.newTheme.black['10']};
    border-width: 0 0 0 0;

    .logo {
      margin-left: 5.5rem;
    }

    ${ToggleClassListButton} {
      display: initial;
      align-self: center;
    }
  }

  @media screen and (max-width: 425px) {
    .logo,
    .logo + hr {
      display: none;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > hr {
    align-self: stretch;
    width: 1px;
  }
`;

export const Title = styled(FlexContainer)`
  gap: 25px;
  font-size: .9rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ClassListButton = styled.button`
  display: none;

  gap: 1.4rem;
  align-items: center;
  justify-content: flex-start;
  color: ${props => props.theme.newColors.white['850']};

  small {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;

    color: ${props => props.theme.newTheme.white['20']};
  }

  @media screen and (max-width: 768px) {
    gap: 1.4rem;
    display: flex;
    align-items: center;
    justify-self: flex-start;
    justify-content: flex-start;
  }
`;

export const CourseInfoButton = styled.button`
  display: none;

  gap: 1.4rem;
  align-items: center;
  justify-content: flex-start;
  color: ${props => props.theme.newColors.white['850']};

  small {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;

    color: ${props => props.theme.newTheme.white['20']};
  }

  @media screen and (max-width: 768px) {
    gap: 1.4rem;
    display: flex;
    align-items: center;
    justify-self: flex-end;
    justify-content: flex-start;
  }
`;

export const Menus = styled(FlexContainer)`
  gap: 25px;

  .settings {
    display: flex;
    align-items: center;
    gap: 44px;
    margin-right: 70px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

import React, { createContext, useCallback, useContext, useState } from 'react';

type IPaginatedContextData = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageIdList: string[];
  setPageIdList: React.Dispatch<React.SetStateAction<string[]>>;
  nextPage: () => void;
  prevPage: () => void;
};

const PaginatedContext = createContext({} as IPaginatedContextData);

export const PaginatedProvider: React.FC = ({ children }) => {
  const [pageIdList, setPageIdList] = useState<string[]>([]);
  const [page, setPage] = useState(0);

  const nextPage = useCallback(() => {
    setPage(prev => (prev + 1 >= pageIdList.length ? prev : prev + 1));
  }, [pageIdList.length]);

  const prevPage = () => setPage(prev => (prev > 0 ? prev - 1 : 0));

  return (
    <PaginatedContext.Provider
      value={{
        page,
        setPage,
        prevPage,
        nextPage,
        pageIdList,
        setPageIdList,
      }}
    >
      {children}
    </PaginatedContext.Provider>
  );
};

export const usePaginated = (): IPaginatedContextData =>
  useContext(PaginatedContext);

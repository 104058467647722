import React from 'react';
import { IconType } from 'react-icons';
import { FiExternalLink, FiFilm, FiTerminal } from 'react-icons/fi';
import { ImFilePdf } from 'react-icons/im';
import { RiArticleLine } from 'react-icons/ri';
import { P } from '../../Atoms/Texts';
import Icon from '../../Icon';

import { Container } from './styles';

type IIconDict = {
  [key in IExtraType]: IconType;
};

const ExtraIcon: IIconDict = {
  URL: FiExternalLink,
  PDF: ImFilePdf,
  CODE: FiTerminal,
  CLASS: FiFilm,
  ARTICLE: RiArticleLine,
};

type IExtraProps = {
  extra: IExtra;
};

const Extra: React.FC<IExtraProps> = ({ extra }) => {
  const isLabLink = extra.url.startsWith('https://patoacademy.run/');

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = () => {
    if (!isLabLink) return;
    localStorage.setItem('last-practice', extra.url);
  };

  const color = (() => {
    if (!extra.type) return 'red';
    return ['CLASS', 'CODE'].includes(extra.type) ? 'yellow' : 'green';
  })();

  return (
    <Container
      href={extra.url}
      onClick={onClick}
      className="blackGlass backgroundBlur"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon
        IconComponent={ExtraIcon[extra.type] || FiExternalLink}
        isFilled={['PDF', 'ARTICLE'].includes(extra.type)}
        color={color}
        strokeWidth={['PDF', 'ARTICLE'].includes(extra.type) ? '0' : undefined}
        isntGradient
        size={24}
      />
      <P className={color}>{extra.description}</P>
    </Container>
  );
};

export default Extra;

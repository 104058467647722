import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  font-size: 1rem;
  > button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    img,
    div {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg + svg {
      width: 65%;
      height: 65%;

      stroke: rgba(255, 255, 255, 0.1) !important;
      color: rgba(255, 255, 255, 0.1) !important;
      fill: rgba(255, 255, 255, 0.5) !important;
    }
  }

  @media screen and (max-width:1024px){
    width: 100%;
    > button {
      display: none;
    }
  }
`;

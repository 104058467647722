import styled from "styled-components";
import * as Accordion from "@radix-ui/react-accordion";
import { Link } from "react-router-dom";
import { FiCheck, FiChevronDown, FiChevronUp, FiClock, FiEdit, FiFilm, FiFlag, FiTerminal } from "react-icons/fi";
import { H4, Small } from "../../../../../Atoms/Texts";

export const ClassItemContainer = styled(Accordion.Item)`
  background: ${props => props.theme.newTheme.black['10']};
  border: 1px solid ${props => props.theme.newTheme.border['20']};
  border-top: 0;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

export const ClassItemHeader = styled(Accordion.Header)`
  width: 100%;
`;

export const ClassItemTrigger = styled(Accordion.Trigger)`
  width: 100%;
  padding: 0 2.2rem 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ClassItemTitle = styled(Link)`
  padding: 2.2rem 2.4rem 2.4rem;
  gap: 2.6rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${H4} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    flex: 1 1;
    text-align: start;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5em;

    letter-spacing: 0.01em;

    color: ${props => props.theme.newTheme.white['10']};
  }
`;

export const ClassItemContent = styled(Accordion.Content)`
  padding: 0 2.4rem 4.5rem;
`;

export const ClassItemDescription = styled(Small)`
  color: ${props => props.theme.newTheme.white['40']};
  > * {
    color: ${props => props.theme.newTheme.white['40']};
  }
`;

export const ClassItemMoreButton = styled.div`
  gap: 3rem;
  display: flex;
  align-items: center;
`;

export const ClassItemChevronDown = styled(FiChevronDown)`
  font-size: 2rem;
  flex-shrink: 0;
  ${ClassItemContainer}[data-state="open"] &{
    display: none;
  }
`;

export const ClassItemChevronUp = styled(FiChevronUp)`
  font-size: 2rem;
  flex-shrink: 0;
  ${ClassItemContainer}[data-state="closed"] &{
    display: none;
  }
`;

export const ClassItemDuration = styled.time`
  flex-shrink: 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 150%;

  color: ${props => props.theme.newTheme.white['40']};

  @media screen and (max-width: 425px) {
    flex-shrink: 1;
  }
`;

export const DisabledActivityIcon = styled(FiClock)`
  font-size: 2rem;
  stroke-width: 2px;
  stroke: ${props => props.theme.newTheme.white['50']};
`;

export const ClassTypeIcon = styled(FiFilm)`
  font-size: 2rem;
  stroke: ${props => props.theme.newTheme.green['20']};
`;

export const QuizTypeIcon = styled(FiEdit)`
  font-size: 2rem;
  stroke: ${props => props.theme.newTheme.yellow['20']};
`;

export const FlagTypeIcon = styled(FiFlag)`
  font-size: 2rem;
  stroke: ${props => props.theme.newTheme.red['20']};
`;

export const PracticeTypeIcon = styled(FiTerminal)`
  font-size: 2rem;
  stroke: ${props => props.theme.newTheme.red['20']};
`;

export const ClassItemProgressContainer = styled.div`
  padding: 0 2.4rem 2.4rem;
  gap: 2.8rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${ClassItemContainer}[data-state="open"] &{
    display: none;
  }
`;

export const ClassItemProgressBar = styled.progress`
  flex: 1;
  width: 100%;
  height: .5rem;

  -webkit-appearance: none;
  appearance: none;

  &::-webkit-progress-bar {
    border-radius: .3rem;
    background:  ${props => props.theme.newTheme.black['60']};
  }
  
  &::-webkit-progress-value {
    border-radius: .3rem;
    background:  ${props => props.theme.buttonGradients.green};
  }

  &[data-type="PRACTICE"],
  &[data-type="FLAG"] {
    background:  ${props => props.theme.buttonGradients.reddish};
  }
`;

export const ClassItemCompletedIcon = styled(FiCheck)`
  font-size: 2rem;
  stroke: ${props => props.theme.newTheme.green['20']};
`;

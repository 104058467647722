import React from 'react';
import { FiSettings, FiLogOut, FiShield, FiUsers } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { useAuth } from '../../../hooks/Auth';
import { Small } from '../../Atoms/Texts';

import { Container, Link } from './styles';

type IDropdownProps = {
  isVisible?: boolean;
  onMouseLeave?: () => void;
};

const Dropdown: React.FC<IDropdownProps> = ({ onMouseLeave, isVisible }) => {
  const {
    location: { pathname },
  } = useHistory();
  const { signOut } = useAuth();
  const goToForum = function () { location.href = "https://pato.community"}

  return (
    <Container
      className={!isVisible ? 'disabled' : ''}
      onMouseLeave={onMouseLeave}
    >
      <button type="button" onClick={goToForum}>
        <FiUsers size={24} strokeWidth={1} />
        <Small>Fórum</Small>
      </button>
      <Link to="/discord" colorType="green" isActive={pathname === '/account'}>
        <FiSettings size={24} strokeWidth={1} />
        <Small>Discord</Small>
      </Link>
     {/* <Link
        to="/security"
        colorType="yellow"
        isActive={pathname === '/security'}
      >
        <FiShield size={24} strokeWidth={1} />
        <Small>Segurança</Small>
      </Link>
    */}
      <hr />
      <button type="button" onClick={signOut}>
        <FiLogOut size={24} strokeWidth={1} />
        <Small>Sair</Small>
      </button>
    </Container>
  );
};

export default Dropdown;

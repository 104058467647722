import { Link } from "react-router-dom";
import styled from "styled-components";
import { H4, H5, H6 } from "../../../../Atoms/Texts";

export const LastActivityItemContainer = styled(Link)`
  padding: .8rem 1.6rem;
  width: 100%;
  height: 8.5rem;
  min-width: min(32rem, calc(100vw - 3.2rem));

  gap: .8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: ${props => props.theme.newTheme.black['30']};
  border: 1px solid ${props => props.theme.newTheme.black['30']};
  border-radius: .4rem;

  &:hover {
    background: ${props => props.theme.newTheme.black['50']};

    border: 1px solid ${props => props.theme.newTheme.border['30']};
  }
`;

export const LastActivityItemHeader = styled.header`
  gap: .4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const LastActivityType = styled(H5)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 150%;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  &[data-type="CLASS"] {
    color: ${props => props.theme.newTheme.green['20']};
  }
  
  &[data-type="FLAG"],
  &[data-type="PRACTICE"] {
    color: ${props => props.theme.newTheme.red['20']};
  }

  &[data-type="QUIZ"] {
    color: ${props => props.theme.newTheme.yellow['20']};
  }
`;

export const LastActivityTitle = styled(H4)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;

  color: ${props => props.theme.newTheme.white['20']};
`;

export const LastActivityModule = styled(H6)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 150%;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;

  color: ${props => props.theme.newTheme.white['50']};
`;

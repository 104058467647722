import ClassLayer from '@/components/ClassData/ClassLayer';
import React, { useEffect } from 'react';
import ClassData from '../../components/ClassData';
import { ClassFilter } from '../../components/ClassData/ClassFilter';
import { CourseInfo } from '../../components/ClassData/ClassPageComponents/CourseInfo';
import MobileProvider from '../../components/ClassData/hooks/Mobile';
import ClassHeader from '../../components/ClassHeader';
import ClassProvider from '../../hooks/Class';

import { Container, Content, Footer } from './styles';

const ClassPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <ClassHeader />
      <Content>
        <ClassFilter />
        <ClassLayer />
        <ClassData />
        <CourseInfo />
      </Content>
      <Footer id="class.page.footer" />
    </Container>
  );
};

export default (): JSX.Element => (
  <ClassProvider>
    <MobileProvider>
      <ClassPage />
    </MobileProvider>
  </ClassProvider>
);

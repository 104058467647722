import styled from 'styled-components';

type FontStyle = 'normal' | 'italic';

type FontWeight =
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800';

type TextProps = {
  style?: FontStyle;
  weight?: FontWeight;
  size?: number;
};

export const H1 = styled.h1<TextProps>`
  font-weight: ${props => props.weight ?? 400};
  font-style: ${props => props.style ?? 'normal'};
  font-size: ${props => (props.size ? `${props.size / 10}em` : `5.5em`)};
`;

export const H2 = styled.h2<TextProps>`
  font-weight: ${props => props.weight ?? 400};
  font-style: ${props => props.style ?? 'normal'};
  font-size: ${props => (props.size ? `${props.size / 10}em` : `4.3em`)};
`;

export const H3 = styled.h3<TextProps>`
  font-weight: ${props => props.weight ?? 400};
  font-style: ${props => props.style ?? 'normal'};
  font-size: ${props => (props.size ? `${props.size / 10}em` : `3.5em`)};
`;

export const H4 = styled.h4<TextProps>`
  font-weight: ${props => props.weight ?? 400};
  font-style: ${props => props.style ?? 'normal'};
  font-size: ${props => (props.size ? `${props.size / 10}em` : `2.5em`)};
`;

export const H5 = styled.h5<TextProps>`
  font-weight: ${props => props.weight ?? 400};
  font-style: ${props => props.style ?? 'normal'};
  font-size: ${props => (props.size ? `${props.size / 10}em` : `2.0em`)};
`;

export const H6 = styled.h6<TextProps>`
  font-weight: ${props => props.weight ?? 400};
  font-style: ${props => props.style ?? 'normal'};
  font-size: ${props => (props.size ? `${props.size / 10}em` : `1.8em`)};
`;

export const P = styled.p<TextProps>`
  font-weight: ${props => props.weight ?? 400};
  font-style: ${props => props.style ?? 'normal'};
  font-size: ${props => (props.size ? `${props.size / 10}em` : `1.6em`)};
`;

export const Small = styled.small<TextProps>`
  font-weight: ${props => props.weight ?? 400};
  font-style: ${props => props.style ?? 'normal'};
  font-size: ${props => (props.size ? `${props.size / 10}em` : `1.4em`)};
`;

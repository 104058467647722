import { useCallback, useRef, useState } from 'react';

export type IUseCarroussel = {
  page: number;
  width: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  listRef: React.MutableRefObject<HTMLUListElement | null>;
  prevCards: VoidFunction;
  nextCards: VoidFunction;
  innerWidth: number;
  isntFirstPage: boolean;
  hasMoreCards: boolean;
};

type Props = {
  length: number;
  cardWidth: number;
};

export const useCarroussel = ({
  length = 0,
  cardWidth,
}: Props): IUseCarroussel => {
  const [page, setPage] = useState(0);

  const listRef = useRef<HTMLUListElement | null>(null);

  const { innerWidth } = window;
  const width = innerWidth > 1440 ? 1320 : innerWidth - 48;

  const maxLength = length * cardWidth;
  const isntFirstPage = page - 1 >= 0;
  const hasMoreCards = page + 1 * width <= maxLength;

  const prevCards = useCallback(() => {
    if (listRef.current && isntFirstPage) {
      setPage(prevState => {
        const newState = prevState - Math.floor(width / cardWidth) * cardWidth;
        if (newState <= 0) return 0;

        return newState;
      });
    }
  }, [isntFirstPage, width, cardWidth]);

  const nextCards = useCallback(() => {
    if (listRef.current && hasMoreCards) {
      setPage(
        prevState => prevState + Math.floor(width / cardWidth) * cardWidth,
      );
    }
  }, [listRef, width, hasMoreCards, cardWidth]);

  return {
    page,
    width,
    setPage,
    listRef,
    prevCards,
    nextCards,
    innerWidth,
    hasMoreCards,
    isntFirstPage,
  };
};

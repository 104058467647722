import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.nav`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  z-index: 1;

  width: 20.2rem;
  /*height: 25.6rem;*/
  padding: .5rem 0;

  &.disabled {
    display: none;
  }

  list-style: none;

  position: absolute;
  top: calc(100% + 22px);
  right: calc(50% + -3px);

  background: ${({ theme: { newColors } }) => newColors.black['800']};
  border: 1px solid rgb(255, 255, 255, 0.1);
  border-radius: 5px;

  > hr {
    margin: 0 auto;
    height: 1px;
    width: calc(100% - 2.2rem);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 1px;
    flex-shrink: 0;
  }

  button, .link {
    height: 4.4rem;
    font-size: 1rem;
    cursor: pointer;
    background: none;
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 10px 15px;
    width: 100%;
    text-decoration: none;
    transition: all .2s;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }

    svg {
      stroke: ${({ theme: { newColors } }) => newColors.white['700']};
    }

    :hover {
      background-color: ${({ theme: { newColors } }) => newColors.black['600']};

      small {
        color: ${({ theme: { colors } }) => colors.reddish};
      }

      svg {
        stroke: ${({ theme: { colors } }) => colors.reddish};
      }
    }
  }

  .beamer_icon {
    /* display: none !important; */
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    position: relative;
    top: 0;
    right: 0;
    padding: 0;
    width: 100%;
    background: none;
    border-width: 0px;
    &.disabled {
      display: flex;
    }

    button {
      /* display: none; */
    }

    hr {
      display: none;
    }
  }
`;

type IColorTupe =
  | 'yellow'
  | 'red'
  | 'green'
  | 'pastelGreen'
  | 'grey'
  | 'black'
  | 'purple';

type LinkProps = {
  colorType: IColorTupe;
  isActive?: boolean;
};

export const Link = styled(RouterLink) <LinkProps>`
  font-size: 1rem;
  cursor: pointer;
  background: none;
  height: 4.4rem;
  transition: all .2s;

  display: flex;
  align-items: center;
  gap: 14px;
  padding: 10px 15px;
  min-width: 202px;
  text-decoration: none;

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  svg {
    stroke: ${({ theme: { newColors } }) => newColors.white['700']};
  }

  :hover {
    background-color: ${({ theme: { newColors } }) => newColors.black['600']};
    small {
      color: ${({ theme: { colors }, colorType }) => colors[colorType]};
    }

    svg {
      stroke: ${({ theme: { colors }, colorType }) => colors[colorType]};
    }
  }

  :active {
    border-color: ${({ theme, colorType }) => theme.colors[colorType]}
  }

  ${({ isActive, theme: { colors, opacifyColor }, colorType: color }) => isActive && css`
    border-color: ${colors[color]};
    :hover {
      border-color: ${opacifyColor({ color, opacity: 0.8 })};
    }
  `}

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
